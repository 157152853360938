import { useCallback, useState, useEffect } from "react";
import { ISubdomain } from "../models/ISubdomain";
import agents from "../api/agent"

interface IUseSubdomains {
    subdomains: ISubdomain[] | null,
    fetchSubdomains: () => void
}

export const useSubdomains = (): IUseSubdomains => {
    const [subdomains, setSubdomains] = useState<ISubdomain[]>([]);

    const fetchSubdomains = useCallback(async () => {
        const result = await agents.Company.getSubdomains();

        if (result.success && result.data) {
            setSubdomains(result.data)
        }
    }, [])

    useEffect(() => {
        fetchSubdomains()
    }, [])

    return {
        subdomains,
        fetchSubdomains
    }
}
