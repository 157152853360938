import { useLoggedInUser } from "../hooks/useLoggedInUser";
import { Navigate, useLocation } from "react-router-dom";
import Role from "../enums/Role";
import { useRedirectToNewWebApp } from "./useRedirectToNewWebApp";
import links from "../routes/links";

const AuthorizedRedirect = ({ children }: { children: JSX.Element }) => {
    const { loggedInUser, loading } = useLoggedInUser();
    const shouldStayAtLegacySite = useRedirectToNewWebApp(loggedInUser);
    const location = useLocation();
    
    if (location.pathname == "/Account/CreateCompany") {
        window.location.href = links.CREATECOMPANY.href
    }

    if (loading) return null

    if (!shouldStayAtLegacySite) return null;

    if (loggedInUser) {
        const link = Role.getDashLink(loggedInUser.roleID);
        return (
            <Navigate to={link.link!} replace />
        )
    }

    return children
};

export default AuthorizedRedirect;
