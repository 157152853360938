import { useEffect, useState } from "react";
import gears from "../../assets/images/gears.png";
import { PageTemplate } from "../../core/layout/PageTemplate";
import { strings } from "../../content/strings";
import { useQuery } from "../../hooks/useQuery";
import { useNavigate } from "react-router";
import agents from "../../api/agent";
import { useLoginUserFromToken } from "../../hooks/useUpdateLoggedInUser";
import Role from "../../enums/Role";
import { ILoggedInUser } from "../../models/ILoggedInUser";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { PrimaryButton, PrimaryLink, QuaternaryButton } from "../../components/button";
import { isIOS, isMobile } from "react-device-detect";
import DashboardPage from "../dashboard/DashboardPage";
import { Dialog } from "@mui/material";

const SsoRequestPage = () => {
    const uid: string = useQuery("uid");

    const navigate = useNavigate();
    const { getAuthUserFromUid } = useLoginUserFromToken()
    const [loaded, setLoaded] = useState(false);

    const handleAuth = async () => {
        if (!uid) {
            navigate("/Account/Login")
            return;
        }

        const result = await getAuthUserFromUid(uid);
        const roleID = (result?.payload as ILoggedInUser)?.roleID

        setTimeout(() => {
            setLoaded(true)
            if (!roleID) {
                navigate("/Account/Login")
            } else if (!isMobile) {
                navigate(Role.getDashLink(roleID).link || "/")
            }
        }, 0)
    }

    useEffect(() => {
        handleAuth();
    }, [])

    if (!loaded) {
        return <span />
    }

    return (
        <Container />
    );
};

const Container = () => {
    const navigate = useNavigate();
    const [mobileLink, setMobileLink] = useState("")
    const { loggedInUser } = useLoggedInUser();

    useEffect(() => {
        if (!loggedInUser?.userID) {
            return () => { }
        }
        const getMobileLink = async () => {
            const response = await agents.Auth.createTokenFromUser();
            if (response.data) {
                setMobileLink("https://davincilock.app.link/P2fXKZav3Ab?uid=" + response.data)
            }
        }

        getMobileLink();
        const intervalID = setInterval(() => getMobileLink(), (60000 * 4)) // 4 minutes

        return () => clearInterval(intervalID)
    }, [loggedInUser?.userID])


    const continueToWebApp = () => {
        navigate(Role.getDashLink(loggedInUser?.roleID).link || "/")
    }

    if (!loggedInUser?.userID) {
        return <span />
    }

    return (
        <>
            <DashboardPage />
            <Dialog PaperProps={{ sx: { padding: "24px", position: "fixed", top: 0 } }} fullWidth onClose={continueToWebApp} open={true}>
                <PrimaryLink fullWidth href={mobileLink} external >Open in Mobile</PrimaryLink>
                <div style={{ width: "100%", height: "16px" }} />
                <QuaternaryButton onClick={continueToWebApp} fullWidth>Continue</QuaternaryButton>
            </Dialog >
        </>
    )
}

export const SsoLoginSuccessPage = () => {
    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={strings.login}
            pageDescription={strings.davincilockPageDescription}
            pageCanonical={strings.signinPageCanonical}
            center>
            <SsoLoginSuccessContainer />
        </PageTemplate>
    );
};

const SsoLoginSuccessContainer = () => {
    const navigate = useNavigate();

    const { loggedInUser } = useLoggedInUser();

    useEffect(() => {
        if (isIOS) {
            window.location.href = "https://itunes.apple.com/us/app/davinci-lock-mobile/id1438528927?mt=8"
        } else {
            window.location.href = "https://play.google.com/store/apps/details?id=com.davincilock.mobile"
        }
    }, [])

    return (
        <>
            <DashboardPage />
            <Dialog PaperProps={{ sx: { padding: "24px", position: "fixed", top: 0 } }} fullWidth onClose={() => navigate(Role.getDashLink(loggedInUser?.roleID).link || "/")} open={true}>
                <PrimaryButton fullWidth pending onClick={() => { }}>Open in Mobile</PrimaryButton>
                <div style={{ width: "100%", height: "16px" }} />
                <QuaternaryButton onClick={() => { }} style={{ opacity: 0 }} fullWidth>Close</QuaternaryButton>
            </Dialog >
        </>
    )
}

export default SsoRequestPage;
