import { useEffect, useState } from "react";
import { IPaginationData } from "./Pagination";
import { SelectionFilterOptions } from "../../models/SelectionFilterOptions";
import { FilterDataProps } from "../../models/FilterDataProps";
import { createAlphanumSortObjects } from "../../utils/alphanum-sort";
import { sort } from "../../utils/sort";

export const handleTableDataFilter = (data: any[], filterData: FilterDataProps) => {
    if (!Object.keys(filterData).length) {
        return data;
    }
  //filterData is not always a string, sometimes it's an object in the case of Facility and Status filters
    return Object.entries(filterData).reduce((acc, [key, value]) => {
       return typeof(value) === "string" ? acc.filter(l => l[key]?.toString().toUpperCase().includes(value?.toString().toUpperCase())) :
        acc.filter(l => l[key]?.toString().toUpperCase().includes(value?.value?.toString().toUpperCase()))
    }, data);
}

export const handleTableDataSort = (data: any[], { key, ascending, date, normalSort }: { key: string, ascending: boolean, date: boolean, normalSort?: boolean }) => {
    if (!key) {
        return data
    }

    const sortedData = normalSort
        ? sort(data, (a: any, b: any) => a[key] > b[key] ? 1 : -1)
        : sort(data, createAlphanumSortObjects(key!, date));

    if (!ascending) {
        sortedData.reverse()
    }

    return sortedData
}

export const useTableSortAndFilter = ({
    data, rowsPerPage, filterString, filterKeys, useServer, fetchDataFromServer,
}: any) => {
    const [filterData, setFilterData] = useState<FilterDataProps>({});
    const [sortData, setSortData] = useState({
        key: "",
        ascending: false,
        date: false
    });
    const [paginationData, setPaginationData] = useState<IPaginationData>({
        page: 1,
        itemsPerPage: rowsPerPage?.initial || 10
    });

    useEffect(() => {
        if (useServer) {
            const serverPayload: SelectionFilterOptions = {
                take: paginationData.itemsPerPage,
                skip: (paginationData.page - 1) * paginationData.itemsPerPage,
                sort: sortData.key ? [{
                    field: sortData.key,
                    dir: sortData.ascending ? "asc" : "desc"
                }] : undefined,
                filter: filterString ? {
                    logic: "or",
                    filters: (filterKeys as string[]).map((key: string) => ({
                        field: key as string,
                        operator: "contains",
                        value: filterString as string
                    })) || [],
                } : undefined,
            };

            fetchDataFromServer(serverPayload);
        }
    }, [paginationData, sortData, filterKeys, filterString]);

    useEffect(() => {
        if (useServer) {
            return; /** noop */
        }

        const { page, itemsPerPage } = paginationData;
        //Display all of the data on the first page of the table!
        if ((page - 1) * itemsPerPage > data.length) {
            setPaginationData({ ...paginationData, page: 1 });
        }
    }, [data, useServer]);

    if (useServer) {
        return {
            pageData: handleTableDataSort(data, sortData),
            setFilterData,
            setSortData,
            paginationData,
            setPaginationData,
        };
    }

    const filteredData = useServer ? data : handleTableDataFilter(data, filterData);
    const sortedAndFilteredData = handleTableDataSort(filteredData, sortData);

    const start = (paginationData.page - 1) * paginationData.itemsPerPage;
    const end = start + paginationData.itemsPerPage;
    return {
        sortedData: sortedAndFilteredData,
        pageData: sortedAndFilteredData?.slice(start, end),
        setFilterData,
        setSortData,
        paginationData,
        setPaginationData,
        count: sortedAndFilteredData.length
    };
};
