import React, { useEffect } from "react";
import { debugLog } from "../utils/debugLog";
import Role from "../enums/Role";
import { useAppDispatch } from "../hooks/useAppSelector";
import { useCompanyID } from "../hooks/useCompanyID";
import { useUserRoleID } from "../hooks/useLoggedInUser";
import { loadCompanyDetails, resetSiteRole } from "../redux/auth/loadCompanyDetails";
import { fetchUsersAsync } from "../redux/companyUsers/companyUsersSlice";

const CompanyDetailsMiddleware = ({ children }: { children: any }) => {
    const roleID = useUserRoleID()

    if (Role.isSiteRole(roleID)) {
        return (
            <EnableCompanyDetailsMiddleware>
                {children}
            </EnableCompanyDetailsMiddleware>
        )
    }

    return children
};

const EnableCompanyDetailsMiddleware = ({ children }: { children: any }) => {
    const { companyID } = useCompanyID();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (companyID) {
            loadCompanyDetails(dispatch, companyID);
        } else {
            dispatch(resetSiteRole());
            dispatch(fetchUsersAsync())
        }
    }, [companyID, dispatch]);

    return children
}

export default CompanyDetailsMiddleware
