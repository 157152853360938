import React, { useState, ChangeEvent, useEffect, useContext } from "react";
import { Colors } from "../../core/brand/themes";
import styled from "styled-components";
import Input from "../../core/form-components/Input"
import { PrimaryButton } from "../../components/button";
import { Obj } from "../../models/models";
import alphanum, { createAlphanumSortObjects } from "../../utils/alphanum-sort";
import { sort } from "../../utils/sort";
import useAnalytics from "../../hooks/useAnalytics";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { strings } from "../../content/strings";

export type AutoCompleteProps = {
    values: Values,
    setValue: any,
    errorMessage?: string,
    error?: string,
    label?: string,
    value?: any,
    propName?: string,
    useCategories?: boolean,
    handleClearCallback?: any,
    disabled?: boolean,
    noClear?: boolean,
    noSort?: boolean,
    displayProp?: string,
    analyticsTag?: string,
    endAdornment?: any
}

type Values = Obj[] | string[]

/**
 * @summary creates a drop down input filed with the data passed to it.
 * @param values - can either be a string[] or Obj[]
 * @param value - represents the value of the input textField for data that already exists
 * @param propName - required if values type is Obj[], as it is needed to access
 *  the autocomplete text value
 * @param setValue - callback method for when a value is selected
 * @param useCategories - given values type Obj[] and property "categories" this
 * 
 *  param can set as true to organize auto complete suggestions by category and display the category name
 */

const AutoComplete = (
    { values,
        setValue,
        errorMessage,
        error,
        label,
        value,
        propName,
        useCategories,
        handleClearCallback,
        disabled,
        noClear,
        noSort,
        displayProp,
        analyticsTag,
        endAdornment }: AutoCompleteProps) => {
    const [isComponentVisible, setIsComponentVisible] = useState(false);
    const [floatTop, setFloatTop] = useState(false);
    const [inputVal, setInputVal] = useState(value);
    const [suggestions, setSuggestions] = useState<Values>([])
    const { trackClickEvent } = useAnalytics("AutoComplete", false)
    const [mobile] = useMediaQuery(414);

    useEffect(() => {
        setInputVal(value)
    }, [value])

    const setDropdownPosition = (e: ChangeEvent<HTMLInputElement>) => {
        if (window.innerHeight - 300 > e.target.getBoundingClientRect().y) {
            floatTop && setFloatTop(false);
        } else {
            !floatTop && setFloatTop(true);
        }
    }

    const getTextVal = (v: Obj | string): string => {
        return typeof v == "string" ? v : v[propName || "value"]
    }

    const getDisplayVal = (v: Obj | string): string => {
        return typeof v == "string" ? v : v[displayProp || propName || "value"]
    }

    const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setDropdownPosition(e);

        const value = e.target.value;
        if (!isComponentVisible) {
            // always show all suggestions when first opening the dropdown
            setSuggestions(values)
        }
        else if (value) {
            // the reason I typed it to any here is because filter expects two different types of callbacks for an object [] and a string []
            // for obj it takes Obj, the index, and the array itself and returns a boolean.
            // for type sting it takes he index, and the array itself and returns a boolean
            setSuggestions((values as any).filter((v: Obj | string) => getTextVal(v).toUpperCase().includes(value.toUpperCase())))
        } else {
            setSuggestions(values)
        }
        setInputVal(value)
        setIsComponentVisible(true);
    };

    const onClick = (item: Obj | string, category?: string) => {
        analyticsTag && trackClickEvent(analyticsTag)
        setValue(item, category)
        setIsComponentVisible(false);
        setSuggestions([])
        setInputVal(getTextVal(item) || "")
    }

    const handleClear = () => {
        if (!disabled) {
            setInputVal("")
            handleClearCallback && handleClearCallback();
        }
    }

    function sortSuggestions(items: Obj[]): Obj[];

    function sortSuggestions(items: string[]): string[];

    function sortSuggestions(items: any[]): any {
        if (noSort) {
            return items;
        }
        if (typeof items[0] == "string") {
            return sort(items, alphanum)
        }

        return sort(items, createAlphanumSortObjects(propName!))
    }

    const renderSuggestions = (items: Values, category?: string) =>
        items.map((item: Obj | string, idx) =>
        (
            <AutoCompleteItem key={idx} onClick={() => onClick(item, category)}>
                <AutoCompleteItemButton
                    key={getTextVal(item)}>
                    {getDisplayVal(item)}
                </AutoCompleteItemButton>
            </AutoCompleteItem>
        )
        )

    const renderAutoCompleteBox = () => {
        if (useCategories) {
            const categories = (suggestions as any).reduce((acc: string[], cv: Obj) => (
                [...acc, ...(acc.includes(cv.category) ? [] : [cv.category])]
            ), [])
            return categories.map((category: string) => {
                const items = (suggestions as any).filter((s: any) => s.category === category)
                return (
                    <>
                        <CategoryItem>{category}</CategoryItem>
                        {renderSuggestions(sortSuggestions(items), category)}
                    </>
                )
            })
        }
        return renderSuggestions(sortSuggestions(suggestions as string[]))
    }

    const clearText = mobile ? strings.clear : "X"
    return (
        <Container>
            {isComponentVisible && <div
                onClick={() => setIsComponentVisible(false)}
                style={{
                    display: isComponentVisible ? "block" : "none",
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "transparent",
                    position: "fixed",
                    zIndex: 1,
                    transform: "translate3d(0, 0, 50px)",
                    top: 0,
                    left: 0
                }}
            />}
            <InputContainer>

                <Input
                    disabled={disabled}
                    error={error || errorMessage}
                    value={inputVal || ""}
                    onFocus={onTextChanged}
                    onChange={onTextChanged}
                    type="text"
                    label={label || "Search"}
                    endAdornment={endAdornment}
                >
                </Input>
                {!noClear && <Clear disabled={disabled} onClick={handleClear}>{clearText}</Clear>}
            </InputContainer>
            {suggestions.length > 0 && isComponentVisible && (
                <AutoCompleteContainer floatTop={floatTop}>
                    {renderAutoCompleteBox()}
                </AutoCompleteContainer>
            )}
        </Container>
    )
}

const InputContainer = styled.div({
    position: "relative",
    display: "flex",
})

const Clear = styled(PrimaryButton)({
    fontWeight: 800,
    fontSize: "0.75rem",
    "@media (max-width: 414px)": {
        margin: "20px 0px 0px 4px",
    },
})

export const Container = styled.div({
    position: "relative",
    input: {
        textAlign: "left",
    }
})

const CategoryItem = styled.li({
    padding: "4px 12px",
    fontSize: "1rem",
    fontWeight: 500,
})

export const AutoCompleteContainer = styled.ul<{ floatTop: boolean }>(({ floatTop }) => ({
    li: {
        justifyContent: "start",
    },
    background: "#fff",
    padding: "6px 0 0 0",
    listStyleType: "none",
    position: "absolute",
    left: "0",
    border: `1px solid ${Colors.grey()}`,
    borderRadius: "2px",
    margin: 0,
    boxSizing: "border-box",
    maxHeight: "280px",
    overflowY: "auto",
    zIndex: 1,
    transform: "translate3d(0, 0, 100px)",
    "::-webkit-scrollbar": {
        width: "12px",
    },
    "::-webkit-scrollbar-track": {
        background: Colors.white(),
    },
    "::-webkit-scrollbar-thumb": {
        background: Colors.lightergrey(),
        borderRadius: "10px",
        border: "solid 3px white"
    },
    ...(floatTop ? {
        bottom: "68px",
    } : { top: "40px", })
}));

export const AutoCompleteItem = styled.li({
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px 16px",
    width: "100%",
    boxSizing: "border-box",
    "&: hover": {
        background: Colors.lightergrey()
    },
    button: {
        marginBottom: "0px",
        textAlign: "left",
    }
});

export const AutoCompleteItemButton = styled.button({
    background: "none",
    boxSizing: "border-box",
    border: "none",
    width: "100%",
    marginBottom: "0px!important",
    lineHeight: "32px",
    textAlign: "left",
    "&: active": {
        outline: "none",
        color: Colors.black()
    }
});

export default React.memo(AutoComplete);
