import links from "../../routes/links"
import loginKey from "../../assets/images/key.svg"
import MobileMenu from "../../core/navigation-menu/MobileNavMenu";
import React, { useState, useEffect } from "react";
import styled from "styled-components"
import whiteLogo from "../../assets/images/davinciwhite.svg"
import { useNavigate } from "react-router-dom";
import { Colors } from "../../core/brand/themes"
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoginKey } from "../../components/styled/login-key";
import { PrimaryButton, PrimaryLink, SecondaryLink } from "../../components/button"
import { strings } from "../../content/strings"
import { ILoggedInUser } from "../../models/ILoggedInUser"
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { navigateToRazorPage } from "../../api/agent";
import Role from "../../enums/Role";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { useLogout } from "../../hooks/useLogout";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Header = () => {
    const [showMenu, setShowMenu] = useState(false)
    const navigate = useNavigate();
    const [smallDisplay, showHamburgerMenu, shortNavText] = useMediaQuery(412, 865, 930);
    const { loggedInUser, loading } = useLoggedInUser();
    const { logout } = useLogout()

    const logoutAction = () => {
        logout();
    }

    useEffect(() => {
        if (!showHamburgerMenu && showMenu) {
            setShowMenu(false)
        }
    }, [showHamburgerMenu])

    const navigateHome = () => {
        if (loggedInUser) {
            const dashLink = Role.getDashLink(loggedInUser.roleID)
            if (dashLink.href) {
                navigateToRazorPage(dashLink.href);
            } else {
                navigate(dashLink.link || "");
            }
        } else {
            navigate("/");
        }
    }

    const DisplayNavButtons = () => {
        const buttons = loggedInUser ? <LoggedInHeaderButtons logout={logoutAction} loggedInUser={loggedInUser} /> : <LoggedOutHeaderButtons />

        return (
            <>
                {!loading && buttons}
                <ClippedNavButtons>
                    <SecondaryLinkSmall {...links.STORE} clipped>{strings.store}</SecondaryLinkSmall>
                    <PrimaryLinkSmall {...links.SUPPORT} clipped style={{ margin: "0 0 0 -40px" }}>{strings.support}</PrimaryLinkSmall>
                </ClippedNavButtons>
            </>
        )
    }

    return (
        <>
            <HeaderWrapper>
                {showHamburgerMenu && <SlideMenu setShowMenu={setShowMenu} showMenu={showMenu} loggedInUser={loggedInUser} />}

                <HeaderWrapperBase />
                <ClippedDavinciLockBox>
                    {showHamburgerMenu && <HamburgerIcon
                        onClick={() => setShowMenu(!showMenu)}
                        size="lg"
                        icon={faBars as IconProp}
                    />}
                    <DavinciLogo src={whiteLogo} onClick={navigateHome} />
                </ClippedDavinciLockBox>
                <NavParapgraph>
                    {(shortNavText && !showHamburgerMenu) || smallDisplay ? strings.shortNavText : strings.navText}
                </NavParapgraph>

                {!showHamburgerMenu && DisplayNavButtons()}
            </HeaderWrapper>
        </>
    )
}

const SlideMenu = (
    { showMenu, loggedInUser, setShowMenu }:
        { showMenu: boolean, loggedInUser: ILoggedInUser | null, setShowMenu: (val: boolean) => void }) => {
    if (loggedInUser) {
        return <MobileMenu setShowMenu={setShowMenu} showMenu={showMenu} loggedInUser={loggedInUser} />
    }

    return <LoggedOutMenu showMenu={showMenu} />
}

const LoggedOutMenu = ({ showMenu }: { showMenu: boolean }) => (
    <SlideDown show={showMenu} height={125} duration={450}>
        <PrimaryLinkSmall fullWidth {...links.STORE}> {strings.store} </PrimaryLinkSmall>
        <PrimaryLinkSmall fullWidth {...links.SUPPORT}> {strings.support} </PrimaryLinkSmall>
        <SecondaryLinkSmall fullWidth {...links.LOGIN}> {strings.signinOrCreateAccount} <LoginKey src={loginKey} /> </SecondaryLinkSmall>
    </SlideDown >
)

const LoggedOutHeaderButtons = () => (
    <NavButtons>
        <SecondaryLinkSmall  {...links.LOGIN}>{strings.signinOrCreateAccount} <LoginKey src={loginKey} /> </SecondaryLinkSmall>
    </NavButtons >
)

const LoggedInHeaderButtons = ({ loggedInUser, logout, }: { loggedInUser: ILoggedInUser, logout: () => void }) => {
    const fullName = `${loggedInUser?.firstName} ${loggedInUser?.lastName}`
    return (
        <LoggedInNavButtons>
            <UserLabel link={"/Users/Profile"} >
                {fullName}
            </UserLabel>
            <LogoutLabel onClick={logout}>
                {strings.logout}
            </LogoutLabel>
        </LoggedInNavButtons>
    )
}

const HamburgerIcon = styled(FontAwesomeIcon)({
    color: Colors.white(),
    cursor: "pointer",
    position: "absolute",
    left: "16px",
    top: "calc(50% - 8px)",
})

const SlideDown = styled.div<{ show?: boolean, duration: number, height: number }>(({ show, duration, height }) => ({
    backgroundColor: Colors.navy(),
    height,
    transition: `margin ${duration / 1000}s ease-in`,
    zIndex: -1,
    marginTop: `${(51 - height)}px`,
    ...(show ? {
        marginTop: "55px",
    } : {}),
    display: "flex",
    flexDirection: "column",
    padding: "2px 0",
    width: "100vw",
    position: "absolute",
}))

const HeaderWrapperBase = styled.div({
    position: "absolute",
    backgroundColor: Colors.navy(),
    height: "28px",
    maxHeight: "28px",
    width: "100%",
    zIndex: -1,
    '@media(max-width: 865px)': {
        height: "55px",
        maxHeight: "55px",
    }
})

const HeaderWrapper = styled(HeaderWrapperBase)({
    position: "fixed",
    display: "grid",
    gridTemplateColumns: "298px calc(60% - 298px) 1fr",
    zIndex: 1000,
    transform: "translate3d(0, 0, 400px)",
    '@media(max-width: 865px)': {
        gridTemplateColumns: "298px 1fr",
    }
})

const DavinciLogo = styled.img({
    height: "46px",
    cursor: "pointer",
    position: "absolute",
    right: "25%",
    top: "4px"
})

const ClippedDavinciLockBox = styled.div({
    position: "relative",
    backgroundColor: Colors.red(),
    width: "298px",
    minWidth: "298px",
    clipPath: "polygon(100% 0, 74% 100%, 0 100%, 0 0)",
    height: "55px",
})

const ClippedNavButtons = styled.div({
    top: "32px",
    left: "220px",
    zIndex: "-1",
    margin: "-4px auto",
    position: "fixed",
    "a, button": {
        height: "27px"
    },
    textAlign: "center",
})

const NavParapgraph = styled.p({
    alignSelf: "start",
    lineHeight: "28px",
    margin: "0 8px",
    color: Colors.white(),
    fontSize: "0.80rem",
    fontWeight: 600,
    '@media(max-width: 865px)': {
        alignSelf: "center",
        lineHeight: 1.2,
    }
})

const UserLabel = styled(SecondaryLink)({
    margin: 0,
    height: "28px",
    fontSize: ".75rem",
})

const LogoutLabel = styled(PrimaryButton)({
    margin: "0px 4px",
    height: "28px",
    fontSize: ".75rem",
})

const NavButtons = styled.div({
    display: "flex",
    alignSelf: "start",
    marginTop: "-8px",
})

const LoggedInNavButtons = styled(NavButtons)({
    minWidth: "120px",
    textAlign: "center",
    margin: 0,
    justifySelf: "center"
})

const SecondaryLinkSmall = styled(SecondaryLink)({
    height: "28px",
    fontSize: ".75rem",
})
const PrimaryLinkSmall = styled(PrimaryLink)({
    height: "28px",
    fontSize: ".75rem",
})

export default Header
