export const mockSettingProfile = {
    data: {
        iD: 4,
        companyID: 1,
        delinquentSms: "Hello {1}! your {2}. needs to be paid for. Sms",
        delinquentEmail: "Hello {1} your {2}. needs to be paid for. Email",
        delinquentNotificationPreference: 3,
        moveInSms: "Hello {1}! Thank you for choosing {2}. Sms",
        moveInEmail: "Hello {1}! Thank you for choosing {2}. Email",
        moveInNotificationPreference: 2,
        textReleaseApprovedSms: "Hi there {1}, your code to release {2}! belonging to {3} is 4334.",
        textReleaseDeniedSms: "Hi there {1}, We deny you access to {2}! belonging to {3}. check with your supervisor or something man!",
        textReleaseNotificationPreference: true,
        paymentPortal: true,
        overlockReleaseAction: 0,
        vacantReleaseAction: 0,
        numberOfLocks: 4
    }
}

export const mockStats = {
    "Delinquent Sent": 0,
    "New Move In Sent": 0,
    "Text to Release Approved": 0,
    "Text To Release Denied": 0,
}
