import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import environment from '../utils/environment'
import { CompanyImagesResponse } from '../models/companyImages'
import { IFacilityResponse, ICompanyFMSResponse, IUsersResponse, ILoginResponse, ILockResponse, ILocksTableResponse, ILoggedInUserResponse, ICustomRoleResponse } from './responses/responses';
import { IAccount, IBaseResponse, ITransferForm, LockModel, ICompanyUser, ICreateCompanyForm, IFreeLockForm } from "../models/models";
import { IAcceptFacilityTransfer } from '../models/IAcceptFacilityTransfer';
import { FacilityDto } from '../models/facility';
import { IActivateLock } from '../models/IActivateLock';
import { IAPIKey, IAPIKeyResponse } from '../models/IAPIKey';
import { ISubdomain } from '../models/ISubdomain';
import { ISubdomainResponse } from './responses/ISubdomainResponse';
import { ISubdomainsResponse } from './responses/ISubdomainsResponse';
import { ISettingsProfileResponse } from './responses/responses';
import { IFmsForm } from '../pages/facility-listing/components/FMSForm';
import { ILogsListResponse } from '../models/ILogsListResponse';
import { ICompanyListingResponse } from './responses/ICompanyListingResponse';
import { ICompanyDetailsResponse } from "./responses/ICompanyDetailsResponse";
import { IFacilitiesResponse } from './responses/IFacilitiesResponse';
import { IUnitNumbersResponse } from './responses/IUnitNumbersResponse'
import { ICompanyDetails } from '../models/ICompanyDetails';
import { IFreeLockResponse } from './responses/IFreeLockResponse';
import { SelectionFilterOptions } from '../models/SelectionFilterOptions';
import { keys } from '../keys';
import { ITokenDataResponse } from './responses/ITokenDataResponse';
import { SubscriptionTier } from '../enums/SubscriptionTier';
import { strings } from '../content/strings';

export let cancelTokenSources: any[] = []

axios.defaults.baseURL = environment.baseURL;
axios.defaults.headers.common["Access-Control-Allow-Headers"] = "set-cookie"
axios.defaults.withCredentials = true;

const dotnet7Api = axios.create({
    baseURL: environment.dotnet7Url,
});

const handleRequestMiddleware = (config: AxiosRequestConfig<any>) => {
    const controller = new AbortController();
    config.signal = controller.signal
    cancelTokenSources.push(controller)
    return config;
};

axios.interceptors.request.use(
    handleRequestMiddleware
);
dotnet7Api.interceptors.request.use(
    handleRequestMiddleware
);

const cleanUpAbortController = (signal: any) => {
    const index = cancelTokenSources.findIndex(c => c.signal === signal);
    if (index !== -1) {
        cancelTokenSources.splice(index, 1);
    }
}
const handleResponseSuccess = (response: AxiosResponse<any, any>) => {
    cleanUpAbortController(response.config.signal);
    return response;
};
const handleResponseError = (error: any) => {
    cleanUpAbortController(error.config.signal);
    return Promise.reject(error);
}

axios.interceptors.response.use(
    handleResponseSuccess,
    handleResponseError
);
dotnet7Api.interceptors.response.use(
    handleResponseSuccess,
    handleResponseError,
);

interface ITokenResponse {
    token?: string
}

let accessToken = localStorage.getItem(keys.Authorization) || ""
let accessTokenTimestamp = 0

export const getToken = () => accessToken || localStorage.getItem(keys.Authorization) || ""
export const expireToken = () => {
    localStorage.removeItem(keys.Authorization)
    accessToken = ""
    accessTokenTimestamp = Date.now()
}

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const tokenMiddleware = <T>(response: AxiosResponse<T, ITokenResponse>, timestamp: number) => {
    const { token } = response.data as ITokenResponse
    if (token && timestamp > accessTokenTimestamp) {
        localStorage.setItem(keys.Authorization, token)
        accessToken = token
        accessTokenTimestamp = timestamp
    }
    return responseBody(response)
}

const formatError = (err: any) => {
    if (err?.response?.data?.error) {
        return err?.response?.data?.error
    }

    return err?.response?.data?.error || strings.somethingWentWrong
}

const requests = {
    get: <T>(url: string, api: AxiosInstance = axios) => {
        const timestamp = Date.now()
        return api.get<T>(url, {
            headers: {
                "authorization": getToken(),
            }
        }).then((res: any) => tokenMiddleware(res, timestamp))
            .catch((err: any) => ({ error: formatError(err) })) as T
    },
    post: <T>(url: string, body: {}, api: AxiosInstance = axios) => {
        const timestamp = Date.now()
        return api.post<T>(url, body, {
            headers: {
                "authorization": getToken(),
                "Content-Type":"application/json"
            }
        }).then((res: any) => tokenMiddleware(res, timestamp))
            .catch((err: any) => ({ error: formatError(err) })) as T
    },
    put: <T>(url: string, body = {}) => {
        const timestamp = Date.now()
        return dotnet7Api.put<T>(url, body, {
            headers: {
                "authorization": getToken(),
            }
        }).then((res: any) => tokenMiddleware(res, timestamp))
            .catch((err: any) => ({ error: formatError(err) })) as T
    },
    delete: <T>(url: string) => {
        const timestamp = Date.now()

        return dotnet7Api.delete<T>(url, {
            headers: {
                "authorization": getToken(),
            }
        }).then((res: any) => tokenMiddleware(res, timestamp))
            .catch((err: any) => ({ error: formatError(err) })) as T
    }
}

const get = <T>(url: string, queryObj?: { [queryName: string]: any }, api?: AxiosInstance) => {
    if (!queryObj || Object.values(queryObj).every(val => val === undefined)) {
        return requests.get<T>(url, api);
    }
    const params = Object.entries(queryObj).reduce((params: URLSearchParams, [queryName, queryValue]: [string, string | any]) => {
        if (typeof(queryValue) == 'boolean') {
            params.set(queryName, queryValue.toString())
        }
        queryValue && params.set(queryName, queryValue)
        return params
    }, new URLSearchParams())
    return requests.get<T>(`${url}?${params.toString()}`, api)
}

const queryCompanyID = (companyID?: number | null) => companyID ? `?companyID=${companyID || 0}` : ""

const authController = "/auth"
const Auth = {
    authUser: () => get<any>(`${authController}/user`, dotnet7Api),
    authUpdatedUser: () => get<any>(`${authController}/updated-user`, dotnet7Api),
    login: (account: IAccount['login']) => requests.post<ILoginResponse>(`${authController}/login`, account, dotnet7Api),
    updateUserName: (firstName?: string, lastName?: string) => requests.put<IBaseResponse>(`${authController}/update-name`, { firstName, lastName }),
    updateUserEmail: (email?: string, confirmEmail?: string) => requests.put<IBaseResponse>(`${authController}/update-email`, { email, confirmEmail }),
    updatePassword: (password?: string, confirmPassword?: string) => requests.put<IBaseResponse>(`${authController}/update-password?`, { password, confirmPassword }),
    forgotPassword: (email: string) => requests.put<IBaseResponse>(`${authController}/forgot-password`, { email }),
    updatePasswordWithToken: (form: IAccount["resetPassword"]) => requests.put<IBaseResponse>(`${authController}/update-password-with-token`, form),
    getAuthUserFromUid: (uid: string): Promise<ILoginResponse> => get<any>(`${authController}/user-from-token`, { uid }, dotnet7Api),
    createTokenFromUser: (): Promise<ITokenDataResponse> => requests.get<any>(`${authController}/token-from-user`, dotnet7Api),
    updateJwtProgress: (body: { hasLocks?: boolean }) =>
        requests.put<IBaseResponse>(`${authController}/update-progress`, body),
    generateResetPasswordLink: (email: string) => requests.put<IBaseResponse>(`${authController}/generate-reset-password-link`, { email }),
    getUserByEmail: (email: string) => get<IBaseResponse>(`${authController}/get-user-by-email`, {email}, dotnet7Api)
}

const Account = {
    topCompanies: () => get<CompanyImagesResponse>(`${dotnet7CompanyController}/top`, dotnet7Api),
    getIpCountry: () => requests.get<any>("/utils/country-from-ip", dotnet7Api),
    logout: () => requests.get<IAPIKey>("/Account/SPALogOff"),
    getCompanyImage: (logo: string): string => `${environment.dotnet7Url}/images/${logo}`,
    getCurrentUser: () => requests.get<ILoggedInUserResponse>(`${authController}/user`, dotnet7Api),
    createCompany: (form: ICreateCompanyForm) => requests.post<ILoginResponse>(`${dotnet7CompanyController}/create`, form, dotnet7Api),
    validateCompanyEmail: (email: string) => get<IBaseResponse>(`${dotnet7CompanyController}/validate-email`, {
        email
    }, dotnet7Api),
    validateCompanyUrl: (host: string) =>
        requests.get<IBaseResponse>(`/Account/ValidateCompanyUrl?host=${host}`), // TODO: to dotnet7 server
    validateFms: (fmsData: IFmsForm) =>
        requests.post<IBaseResponse>(`${authController}/validate-fms`, fmsData, dotnet7Api),
}

const dotnet7UsersController = "/users"
const Users = {
    getLogsList: (fromDate: string, toDate: string) => get<ILogsListResponse[]>(`${dotnet7UsersController}/logs`, { fromDate, toDate }, dotnet7Api),
    addUser: (request: ICompanyUser) => requests.post<IBaseResponse>(`${dotnet7UsersController}/add-user`, request, dotnet7Api),
    updateUser: (request: ICompanyUser) => requests.put<IBaseResponse>(`${dotnet7UsersController}/update-user`, request),
    ToggleUserStatus: (userID: number) => requests.get<IBaseResponse>(`/Users/ToggleUserStatus?userID=${userID}`),
    TransferOwnership: (userID: number) => requests.post<IBaseResponse>(`${dotnet7UsersController}/TransferOwnership`, { userID }),
    getUpdatedCurrentUser: () => requests.get<IBaseResponse>(`${authController}/updated-user`, dotnet7Api),
    getCompanyUsers: (companyID?: number) => get<IUsersResponse>(`${dotnet7UsersController}`, { companyID }, dotnet7Api),
    validateUniqueEmail: (email: string) => get<IBaseResponse>(`${dotnet7UsersController}/validate-unique-email`, { email }, dotnet7Api)
}

const dotnet7FacilitiesController = "/facilities"
const Facilities = {
    getFacilityById: (facilityId: string) => get<IFacilityResponse>(`${dotnet7FacilitiesController}/${facilityId}`, {}, dotnet7Api),
    getUnits: (facilityId: number) => get<IUnitNumbersResponse>(`${dotnet7FacilitiesController}/${facilityId}/units`, {}, dotnet7Api),
    getFacilityFromToken: (token: string) => get<IFacilityResponse>(`${dotnet7FacilitiesController}/facility-token`, { token }, dotnet7Api),
    getFacilities: (companyID?: number) => get<IFacilitiesResponse>(`${dotnet7FacilitiesController}`, { companyID }, dotnet7Api),
    initializeFacilityTransfer: (form: ITransferForm, token?: string) =>
        requests.post<IBaseResponse>(`${dotnet7FacilitiesController}/initiate-facility-transfer`, form, dotnet7Api),
    acceptFacilityTransfer: (facility: IAcceptFacilityTransfer, token?: string) =>
        requests.post<IBaseResponse>(`${dotnet7FacilitiesController}/accept-facility-transfer`, facility, dotnet7Api),
    addFacility: (facility: FacilityDto) => requests.post<any>(`${dotnet7FacilitiesController}/add`, facility, dotnet7Api),
    updateFacility: (facility: FacilityDto) => requests.put<any>(`${dotnet7FacilitiesController}/update`, facility),
    importFacilitiesCSV: (managementSoftware: number, file: FormData) => requests.post<IBaseResponse>(`${dotnet7FacilitiesController}/import-csv?managementSoftware=${managementSoftware}`, file, dotnet7Api),
    refreshAllUnitNumbers: (companyID: number) => requests.delete<IBaseResponse>(`${dotnet7FacilitiesController}/all-units-cache${companyID ? `?companyID=${companyID}` : ""}`),
    refreshUnitNumbers: (facilityID: number) => requests.delete<IBaseResponse>(`${dotnet7FacilitiesController}/${facilityID}/units-cache`),
}

const companyController = "/Company";
const dotnet7CompanyController = "/companies"
const Company = {
    topCompanies: (count = 5) => get<CompanyImagesResponse>(`${dotnet7CompanyController}/top`, { count }, dotnet7Api),
    getCompanyByID: (companyID: number) => requests.get<ICompanyDetailsResponse>(`${dotnet7CompanyController}/${companyID}`, dotnet7Api),
    getApiKeys: () => requests.get<IAPIKeyResponse>(`${dotnet7CompanyController}/api-keys`, dotnet7Api),
    addApiKey: (form: IAPIKey) => requests.post<IBaseResponse>(`${dotnet7CompanyController}/add-api-key`, form, dotnet7Api),
    updateApiKey: (form: IAPIKey) => requests.put<IBaseResponse>(`${dotnet7CompanyController}/update-api-key`, form),
    toggleExcludeFacility: (facilityID: number) =>
        requests.post<IBaseResponse>(`${dotnet7CompanyController}/toggle-exclude-facility?FacilityID=${facilityID}`, {}, dotnet7Api),
    getSubdomains: () => requests.get<ISubdomainsResponse>(`${dotnet7CompanyController}/subdomains`, dotnet7Api),
    addSubdomain: (form: ISubdomain) => requests.post<ISubdomainResponse>(`${dotnet7CompanyController}/subdomains`, form, dotnet7Api),
    updateSubdomain: (form: ISubdomain) => requests.put<ISubdomainResponse>(`${dotnet7CompanyController}/subdomains`, form),
    uploadSubdomainLogo: (file: FormData, subdomainID: number) => requests.post<IBaseResponse>(`${dotnet7CompanyController}/subdomains/upload-logo?subdomainId=${subdomainID}`, file, dotnet7Api),
    deleteSubdomainLogo: (subdomainID: number) => requests.delete<IBaseResponse>(`${dotnet7CompanyController}/subdomains/delete-logo?subdomainId=${subdomainID}`),
    companyListing: () => requests.get<ICompanyListingResponse>(`companies`, dotnet7Api),
    updateCompany: (company: ICompanyDetails) => requests.post<IBaseResponse>(`${companyController}/UpdateCompany`, company), // TODO: to dotnet7 server
    completeStep: (stepID: string) => requests.put<IBaseResponse>(`users/complete-step?stepID=${stepID}`, {}),
    importFacilitiesStorEdge: (companyID: string) => requests.post<IBaseResponse>(`${companyController}/ImportFacilitiesStorEDGE`, { companyID }), // TODO: to dotnet7 server
    importFacilitiesSitelink: (corporateCode: string, corporateUsername: string, corporatePassword: string, postalCode: string, inMiles: boolean) => requests.post<IBaseResponse>(`${companyController}/ImportFacilitiesSitelink`, { corporateCode, corporateUsername, corporatePassword, postalCode, inMiles }), // TODO: to dotnet7 server
    getCompanyDefaultFMS: (companyID?: number | null) => get<ICompanyFMSResponse>(`${dotnet7CompanyController}/default-fms`, { companyID }, dotnet7Api),
    //test after company creation is merged
    validateCompanyUrl: (url: string) =>
        requests.get<IBaseResponse>(`${dotnet7CompanyController}/validate-url?url=${encodeURIComponent(url)}`, dotnet7Api),
    validateCompanyEmail: (email: string) =>
        get<IBaseResponse>(`${dotnet7CompanyController}/validate-email`, { email }, dotnet7Api),
    //TODO: This can now update a company's subscription to be suspended, not just update the tier: Add a new bool called suspended!
    updateCompanySubscriptionTierAsync: (companyId: number, subscriptionTier: SubscriptionTier, subscriptionSuspended: bool) => requests.post<IBaseResponse>(`${dotnet7CompanyController}/${companyId}/subscription`, { subscriptionTier, subscriptionSuspended }, dotnet7Api) 

}

const locksController = "/SerialCodes_Locks";
const dotnet7LocksController = "/locks";
const Locks = {
    getSerialLocksTable: ({ facilityID, timestamp, facilityLocks, inventoryLocks }: { facilityID?: number, timestamp?: string | null, facilityLocks?: boolean, inventoryLocks?:boolean }, companyID?: number) =>
        get<ILocksTableResponse>(`${dotnet7LocksController}`, { lastPulled: timestamp, companyID, facilityID, facilityLocks, inventoryLocks }, dotnet7Api),
    getLockById: (id: number) => requests.get<ILockResponse>(`${dotnet7LocksController}/${id}`, dotnet7Api),
    getUnitNumbers: (facilityId: number) => requests.get<IUnitNumbersResponse>(`${locksController}/GetUnitNumbers?facilityId=${facilityId}`), // TODO: to dotnet7 server
    getLockMappingByFacilityIds: (facilityIds: number[]) => requests.post<any>(`${locksController}/GetLockMappingByFacilityIds`, facilityIds), // TODO: to dotnet7 server
    updateLock: (lock: LockModel) => requests.put<ILockResponse>(`${dotnet7LocksController}/update-lock`, lock),
    getLockLogs: (lockID: number, companyID: number | null) => get<any>(`${dotnet7LocksController}/logs`, { lockID, companyID }, dotnet7Api),
    getReports: (facilityId: number) => requests.get<any>(`reports/returned-locks/facilities/${facilityId}`, dotnet7Api),
    getReportDetails: (reportId: number) => requests.get<any>(`reports/returned-locks/reports/${reportId}`, dotnet7Api),
    processLockReturns: (locks: LockModel[], recipientID: number) => requests.post<IBaseResponse>(`${dotnet7LocksController}/process-lock-returns?recipientID=${recipientID}`, locks, dotnet7Api),
    activateLock: (data: IActivateLock) => requests.post<IBaseResponse>(`${dotnet7LocksController}/activate-lock`, data, dotnet7Api),
    transferSerialCode: (facilityID: number, lockID: number) => requests.put<IBaseResponse>(`${dotnet7LocksController}/transfer-lock`, { facilityID, lockID }),
    getFreeSerialcodes: (payload: SelectionFilterOptions) => requests.post<IFreeLockResponse>(`${locksController}/FreeSerialCodeListing`, payload), // TODO: to dotnet7 server
    addFreeLocks: (data: IFreeLockForm) => requests.post<IBaseResponse>(`${locksController}/AddFreeLocks`, data), // TODO: to dotnet7 server
    logRevealLockCombination: (lock: LockModel) => requests.post<IBaseResponse>(`${locksController}/LogRevealLockCombination`, lock), // TODO: to dotnet7 server
    getDavinciInventory: (take?: number, skip?: number, searchVal?: string) => get<IFreeLockResponse>(`${dotnet7LocksController}/davinci-inventory`, { take, skip, searchVal }, dotnet7Api),
    transferLock: (lock: { lockID: number, facilityID: number }) => requests.put<ILockResponse>(`${dotnet7LocksController}/transfer-lock`, lock),
}

const settingsController = "/Setting"
const dotnet7SettingsController = "/settings"
const Settings = {
    getRolesByCompanyId: (companyID?: number) => get<ICustomRoleResponse>("users/roles", { companyID }, dotnet7Api),
    addUpdateCustomRole: (data: any) => requests.post<IBaseResponse>(`${settingsController}/AddUpdateCustomRole`, data), // TODO: to dotnet7 server
    updateLocksPerUnitSetting: (allowMultipleLocks: boolean) => requests.put<IBaseResponse>(`${dotnet7SettingsController}/update-locks-per-unit?canHaveMultipleLocks=${allowMultipleLocks}`),
    updateAssignedOverlockAction: (action: number) => requests.put<IBaseResponse>(`${dotnet7SettingsController}/update-assigned-over-lock?status=${action}`),
    updateAssignedVacantAction: (action: number) => requests.put<IBaseResponse>(`${dotnet7SettingsController}/update-assigned-vacant?status=${action}`),
    updateDelinquent: (type: string, inUse: boolean, message: string) => requests.post<IBaseResponse>(`${settingsController}/UpdateDelinquent`, { type, inUse, message }), // TODO: to dotnet7 server
    updateMoveIn: (type: string, inUse: boolean, message: string) => requests.post<IBaseResponse>(`${settingsController}/UpdateMoveIn`, { type, inUse, message }), // TODO: to dotnet7 server
    updateTextRelease: (type: string, inUse: boolean, message: string) => requests.post<IBaseResponse>(`${settingsController}/UpdateTextRelease`, { type, inUse, message }), // TODO: to dotnet7 server
    getSettingsProfile: () => requests.get<ISettingsProfileResponse>(`${dotnet7SettingsController}/profile`, dotnet7Api)
}

export const navigateToRazorPage = (relativePath: string) => {
    window.location.assign(`${environment.baseURL}/${relativePath}`);
}

const agents = {
    Account,
    Company,
    Locks,
    Users,
    Facilities,
    Settings,
    Auth,
}

export default agents
