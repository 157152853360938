import React from "react";
import AddEditUserForm from "../../../pages/users-listing/components/AddEditUserForm";
import { BladePopout } from "../../../core/layout/BladePopout";
import { ICompanyUser } from "../../../models/ICompanyUser";
import { strings } from "../../../content/strings";

type AddEditUserBladeProps = {
    selectedUser: ICompanyUser | null,
    setSelectedUser: (user: ICompanyUser | null) => void,
    addUser?: boolean,
}

/**
 * @param selectedUser - initial values for the form. If no userID, then this is an add user form. Otherwise edit.
 */
export function AddEditUserBlade({
    selectedUser,
    setSelectedUser,
}: AddEditUserBladeProps) {

    return (
        <BladePopout
            show={!!selectedUser}
            headerText={!selectedUser?.userID ? strings.addUser : strings.editUser}
            closeBlade={() => setSelectedUser(null)}
        >
            <AddEditUserForm
                closeBlade={() => setSelectedUser(null)}
                initialValues={selectedUser as ICompanyUser} />
        </BladePopout>
    );
}
