
import React from 'react';
import { Box, Card } from '@mui/material';
import { H4Bold, PBoldest, PThin } from '../brand/typography';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Colors } from '../brand/themes';
import { strings } from '../../content/strings';
import { RowProps } from './Table';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';

interface IMobileHeader {
    text?: string,
    key?: string,
    content?: (data: any) => void
}
interface IProps {
    data?: any,
    mobileIdentifier?: string,
    mobileHeaders?: IMobileHeader[],
    rowProps?: RowProps | undefined | null,
}
const MobileDisplay = ({ data, mobileIdentifier, mobileHeaders, rowProps }: IProps) => {

    const disabled = (data.displayStatus && data.displayStatus !== strings.active) || data.isActive == false;
    return (
        <Container elevation={12}>
            <Header>
                <H4Bold style={{ marginLeft: "-4px" }}> {mobileIdentifier && data[mobileIdentifier]}</H4Bold>
                {disabled && <PThin style={{ color: Colors.red() }}>{strings.disabled}</PThin>}
            </Header>
            {
                mobileHeaders?.map((item: any) => {
                    return (
                        <Box sx={{ display: "flex", alignItems: "start", gap: "4px", m: "2px 0px", position: "relative" }}>
                            {item.text && <PBoldest>{item.text}:</PBoldest>}
                            {item?.content &&
                                <ValueText >{item.content(data)}</ValueText>
                            }
                            <ValueText >{data[item.key]}</ValueText>
                            {item.endOfRow && rowProps &&
                                <FontAwesomeIcon size="lg" icon={faPencil as IconProp} onClick={() => rowProps.onClick && rowProps.onClick(data)}
                                    style={{ cursor: 'pointer', marginLeft: "auto", position: "absolute", right: 2, bottom: 12 }} />
                            }
                        </Box>
                    )
                })
            }
        </Container>
    );
}

const Container = styled(Paper)({
    width: '100%',
    height: "auto",
    padding: "12px",
    boxSizing: "border-box",
});

const Header = styled(Box)({
    display: "flex",
    padding: "4px",
    alignItems: "center",
    justifyContent: "space-between",
})

const ValueText = styled(PThin)({
    wordBreak: "break-word",
    marginTop: "-3px",
    textAlign: "start"
})
export default MobileDisplay;
