export enum MessagesTemplateValue {
    "{name}" = 1,
    "{facility}" = 2,
    "{company}" = 3,
    "{unlockCodes}" = 4,
}

export const messageLimit = {
    SMS: 500,
    EMAIL: 1000
}
