import React from "react";
import { PrimaryLink } from "../../../components/button";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import sitelink from "../../../assets/images/sitelink.png";
import storedge from "../../../assets/images/storedge.png";
import easy from "../../../assets/images/easy.png";
import doorswap from "../../../assets/images/doorswap.png";
import sixstorage from "../../../assets/images/6Storage.png";
import humminmgbird from "../../../assets/images/hummingbird_black.png";
import { strings } from "../../../content/strings"
import links from "../../../routes/links"
import { Image, ImgWrapper, } from "../../../components/styled/boxes";
import { Section } from "../../../core/layout/Section"
import { H3, PThin } from "../../../core/brand/typography"
import styled from "styled-components"
import { Box } from "@mui/material";

const FacilityManagementSection = () => {
    const [isMobile] = useMediaQuery(411);

    const imgMargin = isMobile ? "0 0 24px 0" : "0 32px 16px 0";
    const fms = [storedge, sitelink, easy, doorswap, humminmgbird, sixstorage];
    return (
        <Section paddingY={[24, 32]}>
            <Section paddingY={[32, 16]}>
                <CustomSpacingHeader>{strings.softwareIntegrationHeader}</CustomSpacingHeader>
                <TextContainer>
                    <PThin>{strings.softwareIntegration}</PThin>
                </TextContainer>
                <Column>
                    <PrimaryLink {...links.HOWITWORKS}>{strings.learnMore}</PrimaryLink>
                </Column>
            </Section>
            <Box sx={{ padding: "24px 8px" }}>
                <ImgWrapper >
                    {fms.map((_data, idx) => (
                        <Image style={{ maxHeight: "64px" }} src={_data} alt="" imgMargin={imgMargin} key={idx} ></Image>
                    ))}
                </ImgWrapper>
            </Box>
        </Section >
    )
}

const CustomSpacingHeader = styled(H3)({
    marginBottom: "24px"
});
export const TextContainer = styled.div({
    width: "60%",
    margin: "0 auto",
    "@media (max-width:768px)": {
        width: "100%"
    },
})

const Column = styled.div({
    display: "flex",
    flexDirection: "column",
    wordWrap: "break-word",
    justifyContent: "center",
    alignItems: "center",
})

export default FacilityManagementSection
