import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import * as signalR from "@microsoft/signalr";
import { useLoggedInUser } from "../hooks/useLoggedInUser";
import { events } from "../events";
import { useFacilities } from "../hooks/useFacilities";
import { useFetchLoggedInUser } from "../hooks/useFetchLoggedInUser";
import { useLocks } from "../hooks/useLocks";
import { useSiteRoleCompanyConnection } from "../hooks/useSiteRoleCompanyConnection";
import { useUsers } from "../hooks/useUsers";
import { useClientRoleCompanyConnection } from "../hooks/useClientRoleCompanyConnection";
import agents, { getToken } from "../api/agent";
import environment from "../utils/environment";
import Role from "../enums/Role";
import { debugLog } from "../utils/debugLog";
import { ToastContext } from "./ToastProvider";
import { ToastType } from "../enums/ToastType";
import { delay } from "../utils/delay";
import { strings } from "../content/strings";

export class SignalRService {
    private connection: signalR.HubConnection | null;

    constructor() {
        this.connection = null;
    }

    startConnection(userId: number, callback: () => void): void {
        if (this.connection) {
            this.connection.stop();
        }

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.dotnet7Url}/daVinciLockHub?userID=` + userId, { headers: { "Authorization": getToken() } })
            .configureLogging(signalR.LogLevel.Information)
            .build();

        this.connection?.start()
            .then(callback)
            .catch((err: any) => {
                debugLog(err)
            })
    }

    onLogin(userId: number, callback: () => void): void {
        this.startConnection(userId, callback);
    }

    onLogout(): void {
        if (this.connection) {
            this.connection.stop();
            this.connection = null;
        }
    }

    getConnection(): signalR.HubConnection | null {
        return this.connection;
    }
}

export const signalRService = new SignalRService();

export const joinCompanyGroupMethodName: string = "joinCompanyGroup"
export const leaveCompanyGroupMethodName: string = "leaveCompanyGroup"

// TODO: Add endpoint that updates user permissions for users who are already logged in.

interface IProgress {
    completed: number, total: number, userID: number | null
}
interface IInvalidFacilities {
    invalidAddresses: number, userID: number | null
}
interface IHubContext {
    progressInfo: IProgress,
    invalidFacilities: { invalidAddresses: number, userID: number | null },
    setInvalidFacilities: Dispatch<SetStateAction<IInvalidFacilities>> | any,
    isReady: boolean
};

export const HubContext = createContext<IHubContext>({
    progressInfo: { completed: 0, total: 0, userID: null },
    invalidFacilities: { invalidAddresses: 0, userID: null },
    setInvalidFacilities: () => { },
    isReady: false
});

const HubProvider: React.FC = ({ children }) => {
    const [isReady, setIsReady] = useState(false)
    const { loggedInUser, userRef } = useLoggedInUser();
    const { fetchLoggedInUser, fetchUpdatedLoggedInUser } = useFetchLoggedInUser();
    const { fetchUserFacilities } = useFacilities();
    const { locks, fetchLocks, fetchUpdatedLocks } = useLocks();
    const { fetchUsers } = useUsers()
    const [progressInfo, setProgressInfo] = useState({ completed: 0, total: 0, userID: null });
    const [invalidFacilities, setInvalidFacilities] = useState({ invalidAddresses: 0, userID: null });
    const { displayToast } = useContext(ToastContext);

    useSiteRoleCompanyConnection(isReady, loggedInUser?.roleID);

    useClientRoleCompanyConnection(isReady, loggedInUser)

    useEffect(() => {
        if (isReady) {
            const connection = signalRService.getConnection();
            if (!connection) {
                throw new Error("connection does not exist...")
            }
            connection.on(events.notifyRoleUpdate, async (roleID: number) => {
                if (userRef.current?.roleID === roleID) {
                    displayToast({
                        type: ToastType.Info,
                        text: strings.permissionRoleUpdate
                    })

                    /** Force update since the user's role has been updated */
                    await delay(500);
                    fetchUpdatedLoggedInUser({
                        ...userRef.current,
                        forceUpdate: true
                    });
                }
            });

            connection.on("notifyWarning", async (message: string) => {
                displayToast({
                    type: ToastType.Warning,
                    text: message
                })
            });

            connection.on("notifySuccess", async (message: string) => {
                displayToast({
                    type: ToastType.Success,
                    text: message
                })
            });

            connection.on(events.notifyUserUpdate, async (userID: number[]) => {
                if (userRef.current) {
                    if (userRef.current?.userID === userID[0]) {
                        displayToast({
                            type: ToastType.Info,
                            text: strings.permissionUserUpdate
                        })

                        /** We do a force update, because we cannot compare
                         * the changes in accessible facilities.
                         * Perhaps we add a an additional flag in SignalR for facilitiesChanged
                         * to prevent any unecessary refetching of data. */
                        await delay(500);
                        fetchUpdatedLoggedInUser({
                            ...userRef.current,
                            forceUpdate: true
                        });
                    } else {
                        fetchUsers()
                    }
                }
            });

            connection.on(events.notifyLockUpdate, () => {
                if (userRef.current) {
                    if (userRef.current.roleID === Role.Vendor) {
                        fetchLocks();
                    } else {
                        fetchUpdatedLocks();
                    }
                }
            });

            connection.on(events.notifyFacilityUpdate, () => {
                if (userRef.current) {
                    fetchUserFacilities();
                }
            });

            connection.on(events.notifyImportFacilityProgress, (userID: number | null | any, completed: number, total: number) => {
                setProgressInfo({ completed, total, userID })
                if (completed === total && completed !== 0) {
                    fetchUserFacilities()
                    setTimeout(() => {
                        setProgressInfo({ userID, total: 0, completed: 0 })
                    }, 2500)
                }
            });

            connection.on(events.notifyInvalidAddresses, (userID: number | null | any, invalidAddresses: number) => {
                setInvalidFacilities({ userID, invalidAddresses })
            });
        }
    }, [isReady])

    /** Set up hub */
    useEffect(() => {
        if (loggedInUser?.userID && !isReady) {
            debugLog("signalR: establishing connection")
            signalRService.startConnection(
                loggedInUser?.userID,
                () => {
                    debugLog("signalR: joining compan group")
                    signalRService.getConnection()?.invoke("JoinCompanyGroup", getToken(), loggedInUser.companyID)
                        .catch(err => debugLog(err));
                    setIsReady(true)
                }
            )
        }
    }, [loggedInUser?.userID]);

    useEffect(() => {
        const updateUserProgress = async () => {
            // Update user progress and enable remaining routes if was previously disabled
            const res = await agents.Auth.updateJwtProgress({ hasLocks: true })

            if (res.success) {
                await fetchLoggedInUser()
            }
        }

        if (loggedInUser?.companyID && locks?.length && !loggedInUser?.hasLocks) {
            updateUserProgress()
        }
    }, [locks?.length])

    return (
        <HubContext.Provider
            value={{
                progressInfo,
                invalidFacilities,
                setInvalidFacilities,
                isReady
            }}>
            {children}
        </HubContext.Provider>
    )
}

export default HubProvider;
