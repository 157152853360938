import React, { useEffect, useState } from 'react';
import agents from '../api/agent';
import { mockStats } from '../api/mockSettingProfile';
import { Obj } from '../models/obj';
import { SettingsProfileModel } from '../models/settingsProfileModel';
import { debugLog } from '../utils/debugLog';
import StringTemplate from '../utils/stringTemplateMethods';

export const useProfileSettings = () => {
    const [profile, setProfile] = useState<SettingsProfileModel | null>(null)
    const [messageStats, setMessageStats] = useState<any>(null);
    const defaultMessage = `Thank you for choosing {2}. You have locks on your unit(s): \n{4} Signed- {3}`;

    const fetchProfileSettings = async () => {
        try {
            const result = await agents.Settings.getSettingsProfile();
            if (result.success) {
                const { data } = result;
                setProfile(data)
            }
        } catch (e: any) {
            debugLog({ e })
        }
    }
    useEffect(() => {
        fetchProfileSettings();
    }, [])

    useEffect(() => {
        // update it from the count variables later
        const stats = {
            "Delinquent Sent": 0,
            "New Move In Sent": 0,
            "Text to Release Approved": 0,
            "Text To Release Denied": 0,
        }
        setMessageStats(stats);

    }, [profile])


    const { delinquentNotificationPreference,
        moveInNotificationPreference,
        textReleaseNotificationPreference,
        delinquentEmail,
        delinquentSms,
        moveInEmail,
        moveInSms,
        textReleaseApprovedSms,
        textReleaseDeniedSms } = profile || {} as Obj

    const getViewValue = (value: string) => {
        return StringTemplate.replaceDefaultValue(value)
    }

    return {
        delinquentEmail: getViewValue(delinquentEmail),
        delinquentSms: getViewValue(delinquentSms),
        moveInEmail: getViewValue(moveInEmail),
        moveInSms: getViewValue(moveInSms),
        textReleaseApprovedSms: getViewValue(textReleaseApprovedSms),
        textReleaseDeniedSms: getViewValue(textReleaseDeniedSms),
        messageStats: messageStats || mockStats,
        delinquentNotificationPreference,
        moveInNotificationPreference,
        textReleaseNotificationPreference: Number(textReleaseNotificationPreference),
        defaultMessage: getViewValue(defaultMessage)

    }
}
