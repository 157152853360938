import { useCallback, useEffect, useState } from "react";
import agents from "../api/agent";

export const useLockLogs = (lockID: number, companyID: number|null): [any[], () => void] => {
    const [logs, setLogs] = useState<any[]>([]);
    const fetchLogs = useCallback(async () => {
        const result = await agents.Locks.getLockLogs(lockID, companyID);
        if (result.data) {
            setLogs(result.data);
        }
    }, [lockID]);

    useEffect(() => {
        if (lockID) {
            fetchLogs();
        } else {
            setLogs([])
        }
    }, [lockID]);

    return [logs, fetchLogs];
};
