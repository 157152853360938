import { Link } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../core/brand/themes";

type ButtonTagProps = {
    secondary?: 0 | 1;
    tertiary?: 0 | 1;
    quaternary?: 0 | 1;
    fullWidth?: boolean;
    width?: string;
    margin?: string;
    loading?: boolean;
    disabled?: boolean;
    inactive?: boolean;
};

const getBackgroundColor = ({ secondary, tertiary, quaternary }: ButtonTagProps) => {
    if (secondary) return Colors.yellow()
    if (tertiary) return Colors.navy()
    if (quaternary) return Colors.grey()
    return Colors.red()
}

const getTextColor = (props: ButtonTagProps) => {
    if (props.loading) return getBackgroundColor(props)
    if (props.secondary) return Colors.black()
    if (props.tertiary) return Colors.white()
    return Colors.white()
}
//TODO: This needs refactoring!
const ButtonTag = styled(Link)<ButtonTagProps>((props) => ({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    color: getTextColor(props),
    lineHeight: ".75rem",
    padding: "1px 16px",
    height: "36px",
    fontSize: ".85rem",
    border: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    margin: props.fullWidth ? "2px 0" : props.margin ? props.margin : "8px",
    boxSizing: "border-box",
    ...(props.disabled ? { backgroundColor: Colors.lightgrey() } : {
        cursor: "pointer",
        backgroundColor: getBackgroundColor(props),
        "&:hover": {
            backgroundColor: props.secondary && Colors.yellowHover() ||
                props.tertiary && Colors.navyHover() || props.quaternary && Colors.greyHover() ||
                Colors.redHover(),
            boxShadow:
                props.tertiary ?
                    "0 4px 6px -12px rgb(255 255 255 / 42%),0 4px 3px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(255 255 255 / 20%)" : "",
        },
    }),
    ...(props.inactive ? { backgroundColor: Colors.lightgrey() } : {}),
    ...(props.width ? {
        width: props.width
    } : {
        width: props.fullWidth ? "100%" : "auto"
    }),
    boxShadow:
        props.tertiary ?
            "0 2px 2px 0 rgb(255 255 255 / 14%),0 3px 1px -2px rgb(255 255 255 / 20%),0 1px 5px 0 rgb(255 255 255 / 12%)" : "",
}));

export const ClippedButtonTag = styled(Link)<ButtonTagProps>((props) => ({
    display: "inline-block!important",
    width: "135px",
    height: "32px",
    border: "none",
    position: "relative",
    fontSize: ".75rem",
    lineHeight: "30px",
    cursor: "pointer",
    clipPath: "polygon(25% 0, 100% 0%,   75% 100%, 0% 100%)",
    textDecoration: "none",
    backgroundColor: getBackgroundColor(props),
    color: getTextColor(props),
    "&:hover": {
        backgroundColor: props.secondary ? Colors.yellowHover() : Colors.redHover(),
    },
    margin: props.margin,
}));

export const SubLinkTag = styled(ButtonTag)({
    color: Colors.lightgrey(),
    backgroundColor: "transparent",
    fontSize: "13px",
    padding: "0px",
    margin: "8px 0",
    ":hover": {
        backgroundColor: "transparent",
    },
});

export default ButtonTag;
