import React, { useContext } from "react"
import agents from "../../../api/agent";
import { PrimaryButton } from "../../../components/button";
import { ToastContext } from "../../../contexts/ToastProvider";
import Input from "../../../core/form-components/Input";
import { ToastType } from "../../../enums/ToastType";
import useAnalytics from "../../../hooks/useAnalytics";
import { useFetchLoggedInUser } from "../../../hooks/useFetchLoggedInUser";
import { useForm } from "../../../hooks/useForm"
import { IUpdateUser } from "../../../models/IUpdateUser"
import { strings } from "../../../content/strings";
import { deepEquals } from "../../../utils/deepEquals";
import { editUserProfileValidator } from "../../../validators/validators";
import FormDivider from "./FormDivider";
import { FormCard } from "./FormStyles";
import { events as analyticsEvents } from "../../../mixPanelEvents";

const ProfileForm = ({ initialValues }: { initialValues: IUpdateUser }) => {
    const { displayToast } = useContext(ToastContext);
    const { fetchLoggedInUser } = useFetchLoggedInUser()
    const { trackClickEvent } = useAnalytics("Update Profile Form", false)

    const submitAction = async (formValues: IUpdateUser, setFormValues: (arg0: IUpdateUser) => any) => {
        if (deepEquals(initialValues, formValues)) {
            displayToast({ type: ToastType.Error, text: strings.nothingHasChanged })
            return;
        }

        trackClickEvent(analyticsEvents.submit)
        //@ts-ignore
         const result = await agents.Auth.updateUserName(formValues?.firstName, formValues?.lastName);
        //@ts-ignore: Solve this later Please
        if (result?.success) {
            fetchLoggedInUser()
            displayToast({ type: ToastType.Success })
        } else {
            displayToast({ type: ToastType.Error, text: strings.somethingWentWrong })
        }
    }

    const form = useForm<IUpdateUser>(initialValues, submitAction, editUserProfileValidator)

    return (
        <FormCard onSubmit={form.handleSubmit}>
            <Input {...form.inputProps.firstName} label={strings.firstName} />
            <Input {...form.inputProps.lastName} label={strings.lastName} />
            <PrimaryButton fullWidth onClick={form.handleSubmit} >{strings.saveProfile}</PrimaryButton>
            <FormDivider />
        </FormCard>
    )
}

export default ProfileForm
