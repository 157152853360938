import styled from "styled-components"

type PageLayoutProps = {
    backgroundImage?: any,
    footer?: boolean,
    flip?: boolean,
}

export const FlipBack = styled.div({})

export const PageLayoutTag = styled.div<PageLayoutProps>(props => ({
    overflowX: "hidden",
    width: '100%',
    position: "relative",
    minHeight: '100vh',
    backgroundPositionX: ' 50%',
    backgroundPositionY: '35px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '120%',
    ...(props.flip ? {
        backgroundSize: '150%',
        transform: "scaleY(-1)",
        [FlipBack]: {
            minHeight: '100vh',
            transform: "scaleY(-1)",
            ...(props.footer ? { paddingBottom: "40px", minHeight: "calc(100vh - 40px)" } : {})
        },
    } : {}),
    backgroundImage: props.backgroundImage && `url(${props.backgroundImage})`,
}));
