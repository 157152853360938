import { useCallback, useContext, useMemo, useRef, useState } from "react";
import agents from "../../../api/agent";
import Input from "../../../core/form-components/Input";
import { PrimaryButton, QuaternaryButton } from "../../../components/button";
import { useForm } from "../../../hooks/useForm";
import styled from "styled-components";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { userModelValidator } from "../../../validators/validators";
import Role from "../../../enums/Role";
import { siteLevelRoles } from "../../../enums/Role";
import { ICompanyUser } from "../../../models/ICompanyUser";
import { strings } from "../../../content/strings";
import useAnalytics from "../../../hooks/useAnalytics";
import AutoComplete from "../../../core/form-components/AutoComplete";
import { events as analyticsEvents } from "../../../mixPanelEvents";
import { store } from "../../../redux/store";
import { FacilitiesMultiSelect } from "./FacilitiesMultiSelect";
import { IBaseResponse } from "../../../models/models";
import { ToggleUserStatus } from "./ToggleUserStatus";
import { useCompanyID } from "../../../hooks/useCompanyID";
import { useIsSiteLevelPage } from "../../../hooks/useIsSiteLevelPage";
import { useCustomRoles } from "../../../hooks/useCustomRoles";
import { useUserRoleID } from "../../../hooks/useLoggedInUser";
import { Box, IconButton, TextField, InputAdornment } from "@mui/material";
import CopyIcon from '@mui/icons-material/FileCopy';

const AddEditUserForm = ({
    closeBlade,
    initialValues
}: {
    initialValues: ICompanyUser,
    closeBlade: () => void,
}) => {
    const { trackClickEvent } = useAnalytics(initialValues.userID ? "Edit User" : "Add User")
    const { displayToast } = useContext(ToastContext);
    const [allFacilities, setAllFacilities] = useState(false);
    const { companyIDRef } = useCompanyID();
    const { customRoles, customRolesMap } = useCustomRoles()
    const loggedInRoleID = useUserRoleID()
    const isSiteLevel = Role.isSiteRole(loggedInRoleID)
    const [resetPasswordLink, setResetPasswordLink] = useState("Generate Password Link");

    const filteredClientRoles = isSiteLevel ? customRoles : customRoles.filter((role) => role.name !== "Owner")
    const clientRolesList = filteredClientRoles
        .filter(cr => !!cr.name)
        .map(cr => cr.name)

    const isSiteLevelPage = useIsSiteLevelPage()
    const loggedInUser = store.getState().auth.loggedInUser
    const allFacilityIds: number[] = []
    
    const submitAction = useCallback(async (formValues: ICompanyUser) => {
        await trackClickEvent(analyticsEvents.submit);
        try {
            const method = formValues.userID == undefined ? agents.Users.addUser : agents.Users.updateUser;

            const request: ICompanyUser = {
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                email: formValues.email,
                userInRole: formValues.roleID,
                roleID: formValues.roleID,
                userID: formValues?.userID ?? 0,
                facilityIDs: allFacilities ? allFacilityIds : formValues.facilityIDs,
                companyId: isSiteLevel ? companyIDRef?.current : loggedInUser?.companyID,
                roleName: "",
                isActive: formValues.isActive
            }

            const result: IBaseResponse = await method(request);

            if (result.success) {
                displayToast({ type: ToastType.Success })
                closeBlade()
            } else {
                displayToast({ type: ToastType.Error, text: result.error || "" })
            }

        } catch (e: any) {
            displayToast({ type: ToastType.Error, text: e.message })
        }
        return { unmounting: true }
    }, [allFacilities, isSiteLevelPage, trackClickEvent]);

    const validator = useMemo(() => userModelValidator(initialValues), [initialValues])

    const form = useForm<ICompanyUser>(
        initialValues, submitAction, validator
    );

    const roleIDProps = form.inputProps.roleID;

    const inputRef = useRef("Generate Password Link");

    const handleCopy = () => {
        const current = inputRef.current;
        if (current) {
            //@ts-ignore
            current.select();
            //@ts-ignore
            navigator.clipboard.writeText(current?.value);
        }
    };
    const email = form.inputProps.email.value;

    const generatePassword = async () => {
        const result = await agents.Auth.generateResetPasswordLink(email as string);
        if (result.success) {
            //@ts-ignore
            setResetPasswordLink(result.data);
            displayToast({ type: ToastType.Success })
        } else {
            displayToast({ type: ToastType.Error, text: result.error?.error || "" })
        }
    }

    return (
        <FormCard>

            {initialValues?.userID &&
                <ToggleUserStatus
                    initialValue={!!initialValues.status}
                    closeBlade={closeBlade}
                    user={initialValues}
                />
            }

            <Input {...form.inputProps.firstName} label={strings.firstName} />
            <Input {...form.inputProps.lastName} label={strings.lastName} />
            <Input {...form.inputProps.email} label={strings.email} />

            <AutoComplete
                options={(isSiteLevelPage ? siteLevelRoles : clientRolesList) as string[]}
                setValue={(value: Role) => {
                    roleIDProps.setValue(
                        Role[value] || customRolesMap[value] || 0
                    );
                }}
                label={strings.role}
                error={roleIDProps.error}
                value={Role[roleIDProps.value as number] || customRolesMap[roleIDProps.value as number] || ""}
                noClear
            />

            {!isSiteLevelPage && <FacilitiesMultiSelect
                onSwitchChange={setAllFacilities}
                facilityIdProps={form.inputProps.facilityIDs}
            />}

            <ButtonsSpan>
                <QuaternaryButton fullWidth onClick={closeBlade}>{strings.cancel}</QuaternaryButton>
                <PrimaryButton fullWidth onClick={form.handleSubmit}>{strings.submit}</PrimaryButton>
            </ButtonsSpan>
            {initialValues.userID &&
                <Box sx={{ mt: "48px", display: "flex", flexDirection: "column", gap: "16px" }}>
                    <TextField
                        variant="filled"
                        disabled
                        value={resetPasswordLink}
                        inputRef={inputRef}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleCopy} sx={{ mt: "14px", ml: "-2px" }} >
                                        <CopyIcon fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <PrimaryButton fullWidth onClick={generatePassword}> Generate Password Link</PrimaryButton>
                </Box>
            }
        </FormCard >
    )
}

const FormCard = styled.div({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    padding: "4px"
});

export const ButtonsSpan = styled.span({
    display: "flex",
    justifyContent: "space-evenly",
    gap: "16px",
    marginTop: "32px"
});

export const SwitchInput = styled.span<{ justify?: string }>(({ justify }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    ...(justify ? { justifyContent: justify } : { justifyContent: "space-between" })
}));

export const CenteredSpan = styled.span({
    display: "flex",
    alignItems: "center"
});

export const DialogContainer = styled.div({
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    width: "250px",
    height: "100px",
})

export default AddEditUserForm;
