import React, { useContext, useMemo, useState } from "react";
import agents from "../../../api/agent";
import LockStatus from "../../../enums/LockStatus";
import { H4Bold, PBold } from "../../../core/brand/typography";
import { PrimaryButton } from "../../../components/button";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { SelectFacility } from "../../../core/form-components/SelectFacility";
import { FacilityDto } from "../../../models/facility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../../../core/brand/themes";
import { strings } from "../../../content/strings";
import useAnalytics from "../../../hooks/useAnalytics";
import { events as analyticsEvents } from "../../../mixPanelEvents";
import { useCompanyID } from "../../../hooks/useCompanyID";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ITransferLockForm {
    lockID: number,
    serialCode?: string,
    facilityID?: number,
    lockStatus: number,
    closeBlade: () => void,
}

export const TransferLockForm = ({ lockID, serialCode, facilityID, lockStatus, closeBlade }: ITransferLockForm) => {
    const { displayToast } = useContext(ToastContext);
    const [newFacilityID, setNewFacilityID] = useState(facilityID);
    const [confirmSubmit, setConfirmSubmit] = useState(false);
    const { companyID } = useCompanyID()

    const { trackClickEvent } = useAnalytics("Transfer Lock")

    const handleAutoCompleteClick = (item: FacilityDto) => {
        setNewFacilityID(item.facilityID!);
    };

    const handleSubmit = async () => {
        trackClickEvent(analyticsEvents.submit)
        if (!confirmSubmit && LockStatus.isAssigned(lockStatus)) {
            setConfirmSubmit(true);
        } else if (newFacilityID && facilityID !== newFacilityID) {
            const result = await agents.Locks.transferSerialCode(newFacilityID!, lockID);
            if (result) {
                companyID && closeBlade()
                displayToast({ type: ToastType.Success });
                setConfirmSubmit(false);
            }
        }
    }

    const warningMessage = useMemo(() => (
        <PBold left>
            <FontAwesomeIcon style={{ marginRight: "8px" }} color={Colors.warningYellow()} icon={faExclamationTriangle as IconProp} />
            {strings.lockNotAvailable}
        </PBold>
    ), [])

    const submitButton = useMemo(() => (
        <span style={{ display: "flex", justifyContent: "space-evenly", gap: "16px", height: "100%", paddingBottom: "48px" }}>
            <div style={{ width: "100%", margin: "8px" }} />
            <PrimaryButton fullWidth disabled={!newFacilityID || newFacilityID === facilityID} onClick={handleSubmit}>{strings.ok}</PrimaryButton>
        </span>
    ), [newFacilityID, facilityID, handleSubmit])

    const renderForm = () => {
        if (LockStatus.isAssigned(lockStatus) && companyID) {
            return warningMessage
        }

        if (confirmSubmit) {
            return (
                <>
                    {warningMessage}
                    <PBold left>{strings.continueChangingLock}</PBold>
                    {submitButton}
                </>
            )
        }

        return (
            <>
                <PBold left>{strings.lockTransferCode(serialCode!)}</PBold>
                <SelectFacility
                    setValue={handleAutoCompleteClick}
                    facilityID={newFacilityID!}
                    noClear />
                {submitButton}
            </>
        )
    }

    return (
        <>
            <H4Bold>{strings.transferLockHeader}</H4Bold>
            {renderForm()}
        </>
    );
};
