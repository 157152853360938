import { useCallback, useEffect, useRef, useState } from "react"
import agents from "../api/agent"
import { LockModel } from "../models/lockModel"
import { SelectionFilterOptions } from "../models/SelectionFilterOptions"

export const useFreeSerialCodes = () => {
    const [freeLocks, setFreeLocks] = useState<{
        locks: LockModel[],
        total: number,
    }>({ locks: [], total: 0 })

    const lastPayload = useRef<SelectionFilterOptions>({ take: 10, skip: 0 })

    const fetchFreeLocks = useCallback(async (payload?: SelectionFilterOptions) => {
        const response = await agents.Locks.getDavinciInventory(payload?.take, payload?.skip)
        lastPayload.current = payload || lastPayload.current

        if (response.data) {
            setFreeLocks(response.data)
        }
    }, [])

    useEffect(() => {
        fetchFreeLocks()
    }, [])

    return {
        freeLocks,
        fetchFreeLocks
    }
}
