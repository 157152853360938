import React from "react";
import gears from "../../../assets/images/gears.png";
import CMSDocumentRenderer from "../components/CMSDocumentRenderer";
import { Card } from "../../../core/surfaces/Card";
import { PageTemplate } from "../../../core/layout/PageTemplate";
import { subTermsOfUse } from "../../../content/subTermsOfUse";

const SubTermsOfUsePage = () => {
    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={subTermsOfUse.title}
            pageDescription=""
            pageCanonical="www.davincilock.com/Account/PrivacyPolicy">
            <Card style={{ margin: "0 24px", boxSizing: "border-box", backgroundColor: "white" }}>
                <CMSDocumentRenderer cmsDocument={subTermsOfUse} />
            </Card>
        </PageTemplate>
    );
};

export default SubTermsOfUsePage
