import React from "react";
import gears from "../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import FreeSerialCodesContainer from "./FreeSerialCodesContainer";

const FreeSerialCodesPage = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle="Free Serial Codes page"
            pageDescription=""
            pageCanonical="www.davincilock.com/SerialCodes_Locks/FreeCodes">
            <FreeSerialCodesContainer />
        </LoggedInPageTemplate >
    );
};

export default FreeSerialCodesPage;
