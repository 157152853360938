import React, { } from "react"
import { Colors } from "../../core/brand/themes"
import Tooltip from "@mui/material/Tooltip/Tooltip"
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styledMui from "@mui/material/styles/styled"
import { formatDate } from "../../utils/formatDate";
import { HeaderProps } from "./Table";

type RowProps = {
    row: any,
    tertiary?: boolean,
    columns: { width?: string, key: string, content?: any, date?: boolean }[] | HeaderProps[],
    onRowClick: () => void,
    tooltip?: string,
}

const TableRowComponent = ({ row, tertiary, onRowClick, columns, tooltip }: RowProps) => {
    const positionRef = React.useRef<{ x: number; y: number }>({
        x: 0,
        y: 0,
    });

    const popperRef = React.useRef<any>(null);
    const areaRef = React.useRef<HTMLTableRowElement>(null);

    const handleMouseMove = (event: React.MouseEvent) => {
        positionRef.current = { x: event.clientX, y: event.clientY };

        if (popperRef.current != null) {
            popperRef.current.update();
        }
    };

    if (!tooltip) {
        return (
            <StyledTableRow
                style={{ cursor: tooltip ? "pointer" : "default" }}
                ref={areaRef}
                tertiary={tertiary ? 1 : 0}
                onClick={onRowClick}>
                {columns.map((column) => {
                    if (column.content) {
                        return <TableCell key={`column-${column.key}`}>{column.content(row)}</TableCell>
                    }
                    const value = row[column.key!];
                    return (
                        <TableCell key={`column-${column.key}`}>
                            {(column.date && value) ? formatDate(value) : (value || "")}
                        </TableCell>
                    );
                })}
            </StyledTableRow>
        )
    }

    return (
        <Tooltip
            componentsProps={{
                tooltip: {
                    height: "40px",
                    sx: { fontSize: "0.8rem" }
                }
            }}
            placement="top" arrow title={tooltip}
            PopperProps={{
                onMouseEnter: (e: any) => {
                    if (e?.target?.style) {
                        e.target.style.visibility = "hidden";
                    }
                },
                popperRef,
                anchorEl: {
                    getBoundingClientRect: () => {
                        return new DOMRect(
                            positionRef.current.x,
                            areaRef.current!.getBoundingClientRect().y,
                            0,
                            0,
                        );
                    },
                },
            }}
        >
            <StyledTableRow
                style={{ cursor: "pointer" }}
                onMouseMove={handleMouseMove}
                ref={areaRef}
                tertiary={tertiary as any}
                onClick={onRowClick}
                key={row.id}>
                {columns.map((column) => {
                    if (column.content) {
                        return <TableCell key={`column-${column.key}`}>{column.content(row)}</TableCell>
                    }
                    const value = row[column.key!];
                    return (
                        <TableCell key={`column-${column.key}`}>
                            {(column.date && value) ? formatDate(value) : (value || "")}
                        </TableCell>
                    );
                })}
            </StyledTableRow>
        </Tooltip >
    )
}

const StyledTableRow = styledMui(TableRow)(({ tertiary }: { tertiary: 1 | 0 }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: tertiary ? Colors.pink() : Colors.tableGrey(),
    },
    ...(tertiary ? {
        backgroundColor: Colors.pink(),
        border: `solid ${Colors.red()} 2px`,
        td: {
            padding: "6px",
        }
    }
        : {
            td: {
                padding: "8px",
            }
        }),
}));

export default TableRowComponent
