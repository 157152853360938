import { useSearchParams } from "react-router-dom";
import { useCallback, useMemo } from "react";

export const useSearchParam = (key: string): [string, (newValue?: string | number) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();

    const value: string = useMemo(() => searchParams.get("roleID"), [key]) || "";

    const setValue = useCallback((newValue?: string | number) => {
        if (!newValue) {
            setSearchParams()
        } else {
            setSearchParams(`${key}=${newValue}`)
        }
    }, [key])

    return [value, setValue];
};
