import { createAction, createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { LockModel } from "../../models/lockModel";
import agents from '../../api/agent';
import { createCacheWorker } from '../../hooks/createCacheWorkers';
import { getUserID } from '../auth/authSlice';
import { ActionTypes } from '../auth/ActionTypes';
import { strings } from '../../content/strings';

const locksCacheKey = (userID: number) => {
    return userID ? `locks-${userID}` : ""
}

const cacheWorker = createCacheWorker(locksCacheKey)

interface ILockState {
    locks: LockModel[],
    inventoryLocks:LockModel[],
    timestamp?: string | null,
    status: "awaiting" | "fetched",
    pending?: boolean,
    error?: any,
}

const initialState: ILockState = {
    locks: [],
    inventoryLocks:[],
    timestamp: null,
    status: 'awaiting',
}

export const getLocksFromStore = (store: any) => {
    return (store.getState() as RootState)?.locks.locks
}

const fetchLocksData = async (store: any, params?: any) => {
    const response = await agents.Locks.getSerialLocksTable({inventoryLocks:params?.inventoryLocks, facilityLocks: params?.facilityLocks}, params?.companyID);
    if (response.success && response.data) {
        if (!params?.companyID) { // don't cache for site level users viewing another company
            cacheWorker.postCache(response.data, getUserID(store))
        }
        return response.data
    }

    return store.rejectWithValue(response.error || strings.somethingWentWrong)
}

const fetchUpdatedLocksData = async (store: any, params?: any) => {
    const response = await agents.Locks.getSerialLocksTable({ timestamp: (store.getState() as RootState).locks.timestamp, inventoryLocks:params?.inventoryLocks, facilityLocks:params?.facilityLocks } || {}, params?.companyID);

    if (response.success && response.data) {
        const updatedLocks = response.data.locks.reduce((acc: LockModel[], lockModel: LockModel) => {
            const index = acc.findIndex((l: LockModel) => l.lockID == lockModel.lockID);
            if (index == -1) {
                acc.push(lockModel)
            } else {
                acc[index] = lockModel
            }
            return acc;
        }, (getLocksFromStore(store) || []).slice());

        const result = {
            locks: updatedLocks,
            timestamp: response.data.timestamp
        };

        if (!params?.companyID) { // don't cache for site level users viewing another company
            cacheWorker.postCache(result, getUserID(store))
        }
        return result
    }

    return store.rejectWithValue(response.error || strings.somethingWentWrong)
}

export const fetchLocksAsync = createAsyncThunk(
    "Locks/fetchLocksAsync",
    (params:any, store) => fetchLocksData(store, params)
)

export const fetchCompanyLocksAsync = createAsyncThunk(
    "Locks/fetchCompanyLocksAsync",
    (params: any, store) => fetchLocksData(store, params)
)

export const fetchUpdatedLocksAsync = createAsyncThunk(
    "Locks/fetchUpdatedLocksAsync",
    async (params:any, store) => fetchUpdatedLocksData(store, params)
)

export const fetchUpdatedCompanyLocksAsync = createAsyncThunk(
    "Locks/fetchUpdatedCompanyLocksAsync",
    async (params: any, store) => fetchUpdatedLocksData(store, params)
)

export const loadLocksAsync = createAction(
    "Locks/loadLocks",
    (userID: number, dispatch) => {
        const cacheData = cacheWorker.getCachedData(userID)
        return {
            payload: cacheData
        }
    }
)

export const locksSlice = createSlice({
    name: "Locks",
    initialState,
    reducers: {
        updateLock: (state: any, action: PayloadAction<LockModel[]>) => {
            return { ...state, status: "fetched", locks: action.payload }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadLocksAsync, (state, action: PayloadAction<any>) => {
                return { ...state, ...action.payload }
            })
            .addMatcher(
                isAnyOf(
                    fetchLocksAsync.pending, fetchUpdatedLocksAsync.pending,
                    fetchCompanyLocksAsync.pending, fetchUpdatedCompanyLocksAsync.pending),
                (state, action: PayloadAction<any>) => {
                    return { ...state, ...action.payload, pending: true }
                })
            .addMatcher(
                isAnyOf(
                    fetchLocksAsync.fulfilled, fetchUpdatedLocksAsync.fulfilled,
                    fetchCompanyLocksAsync.fulfilled, fetchUpdatedCompanyLocksAsync.fulfilled),
                (state, action: PayloadAction<any>) => {
                    const facilityLocks = action.payload.locks.filter((lock:LockModel) => lock.facilityID !== 0)
                    const inventoryLocks = action.payload.locks.filter((lock: LockModel) => lock.facilityID == 0)
                    return {
                        status: 'fetched',
                        locks: facilityLocks.length? facilityLocks : state.locks,
                        inventoryLocks: inventoryLocks.length? inventoryLocks : state.inventoryLocks || [],
                        timestamp: action.payload.timestamp,
                        pending: false
                    }
                })
            .addMatcher(
                isAnyOf(
                    fetchLocksAsync.rejected, fetchUpdatedLocksAsync.rejected,
                    fetchCompanyLocksAsync.rejected, fetchUpdatedCompanyLocksAsync.rejected),
                (state, action: PayloadAction<any>) => {
                    return { ...state, error: action.payload }
                })
            .addMatcher(({ type }: { type: string }) => type === ActionTypes.LOGOUT || type === ActionTypes.RESET_SITE_ROLE,
                (state: any) => ({ locks: [], inventoryLocks:[], status: "awaiting" })
            )
            .addDefaultCase((state) => {
                return state;
            })
    }
})

export const { updateLock } = locksSlice.actions;

export const getLocks = (state: RootState) => state.locks
export const getInventoryLocks = (state: RootState) => state.locks.inventoryLocks
export const getLocksPending = (state: RootState) => state.locks.pending
export const getTimeStamp = (state: RootState) => state.locks.timestamp
