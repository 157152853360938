//@ts-nocheck
import React, { useCallback, useContext, useEffect, useRef } from "react"
import styled from "styled-components";
import agents from "../../../api/agent";
import { PrimaryButton, QuaternaryButton } from "../../../components/button";
import { ToastContext } from "../../../contexts/ToastProvider";
import { PBold } from "../../../core/brand/typography";
import Input, { PhoneInput } from "../../../core/form-components/Input";
import AddressAutoComplete from "../../../core/form-components/AddressAutoComplete"
import { ToastType } from "../../../enums/ToastType";
import { FormObject, IFieldProps, IForm } from "../../../hooks/useForm";
import { RenderProps, Step } from "../../../hooks/useFormFlow";
import { ICreateCompanyForm } from "../../../models/ICreateCompanyForm";
import { FMSForm, IFmsForm } from "../../../pages/facility-listing/components/FMSForm";
import { strings } from "../../../content/strings";
import { TermsOfUseAndPrivacyPolicy } from "../../legal/components/TermsOfUseAndPrivacyPolicy";
import { termsOfUse } from "../../../content/termsOfUse";

type CreateCompanyStep<T extends FormObject> = Step<T> & {
    title: string,
    subtext: string,
}

const ChooseFmsStep = ({ form, next, back, validationFailed }: RenderProps<ICreateCompanyForm>) => {
    if (!form.values.usesFms) {
        back()
        return null
    }

    return (
        <form onSubmit={() => next()}>
            <FMSForm form={form} validationFailed={validationFailed} />
            <ContinueButton onClick={() => next()} fullWidth>{strings.continue}</ContinueButton>
        </form>
    )
}

const AddressForm = ({ setFormAddress, next, form, street, city, state, zip }: RenderProps<ICreateCompanyForm> & {
    street: IFieldProps, city: IFieldProps, state: IFieldProps, zip: IFieldProps, setFormAddress: (values: any) => void
}) => (
    <form onSubmit={() => next()}>
        <AddressAutoComplete clearErrors={form.clearErrors} setFormAddress={setFormAddress} {...{ street, city, state, zip }} />
        <ContinueButton onClick={() => next()} fullWidth>{strings.continue}</ContinueButton>
    </form>
)

const CompanyAddressForm = (props: RenderProps<ICreateCompanyForm>) => {
    const { street, city, state, zip } = props.form.inputProps

    /** Pull Latitude and Longitude out when setting company address */
    const setFormAddress = ({ lat, lng, dirtyAddress, ...address }: any) => {
        props.form.setFormValues((previous: any) => ({
            ...previous,
            ...address
        }))
    }

    return <AddressForm setFormAddress={setFormAddress} {...{ street, city, state, zip, ...props }} />
}

const FacilityAddressForm = (props: RenderProps<ICreateCompanyForm>) => {
    const { facilityStreet: street, facilityCity: city, facilityState: state, facilityZip: zip } = props.form.inputProps

    const setFormAddress = ({ dirtyAddress, ...address }: any) => {
        const mappedAddress = {
            ...(address.street ? { facilityStreet: address.street } : {}),
            ...(address.city ? { facilityCity: address.city } : {}),
            ...(address.state ? { facilityState: address.state } : {}),
            ...(address.zip ? { facilityZip: address.zip } : {}),
            lat: address.lat,
            lng: address.lng,
            ...(dirtyAddress ? { dirtyAddress } : {})
        }
        props.form.setFormValues((previous: any) => ({
            ...previous,
            ...mappedAddress
        }))
    }

    return <AddressForm setFormAddress={setFormAddress} {...{ street, city, state, zip, ...props }} />
}

const DoYouHaveFmsStep = ({ form, next }: RenderProps<ICreateCompanyForm>) => {
    const chosen = useRef(false)

    const handleSelection = useCallback((usesFms: boolean) => {
        chosen.current = true;
        form.setFormValues((prevState) => ({
            ...prevState,
            usesFms,
            managementSoftware: 0
        }))
    }, [])

    const handleNo = useCallback(() => handleSelection(false), [])

    const handleYes = useCallback(() => handleSelection(true), [])

    useEffect(() => {
        if (chosen.current) {
            next(null, form.values.usesFms ? 1 : 2)
        }
    }, [form.values])

    return (
        <>
            <PBold left>
                {strings.usingManagementSoftwareIsRequiredFor}
            </PBold>
            <div style={{ display: "flex", gap: "16px", paddingTop: "16px" }}>
                <QuaternaryButton fullWidth onClick={handleNo}>{strings.no}</QuaternaryButton>
                <PrimaryButton fullWidth onClick={handleYes}>{strings.yes}</PrimaryButton>
            </div>
        </>
    )
}

const useSteps = () => {
    const { displayToast } = useContext(ToastContext)

    const companyNameAndUrl: CreateCompanyStep<ICreateCompanyForm> = {
        fields: [
            { key: "companyName" },
            { key: "companyUrl" },
        ],
        title: strings.createCompanyTitle,
        subtext: strings.createCompanyIntro,
    }

    const email: CreateCompanyStep<ICreateCompanyForm> = {
        fields: [
            { key: "email" },
        ],
        title: strings.createCompanyTitle,
        subtext: strings.whatIsYourEmail,
    }

    const firstAndLastName: CreateCompanyStep<ICreateCompanyForm> = {
        fields: [
            { key: "firstName" },
            { key: "lastName" }
        ],
        title: strings.createCompanyTitle,
        subtext: strings.whatIsYourName,
    }

    const phoneNumber: CreateCompanyStep<ICreateCompanyForm> = {
        fields: [{ key: "phoneNumber", component: (props: IFieldProps<any>) => <PhoneInput {...props} label={strings.phoneNumber} /> }],
        title: strings.createCompanyTitle,
        subtext: strings.whatIsYourCompanyPhone,
    }

    const companyAddress: CreateCompanyStep<ICreateCompanyForm> = {
        fields: [
            { key: "street" },
            { key: "city" },
            { key: "state" },
            { key: "zip" }
        ],
        render: CompanyAddressForm,
        title: strings.createCompanyTitle,
        subtext: strings.whatIsCompanyAddress,
    }

    const facilityBrandName: CreateCompanyStep<ICreateCompanyForm> = {
        fields: [{ key: "facilityBrandName" }, { key: "facilityUrl" }],
        title: strings.addAFacility,
        subtext: strings.whatIsYourFacilityBrand,
    }

    const facilityName: CreateCompanyStep<ICreateCompanyForm> = {
        fields: [{ key: "facilityName" }],
        title: strings.addAFacility,
        subtext: strings.whatIsYourFacilityIdentifier,
    }

    const facilityAddress: CreateCompanyStep<ICreateCompanyForm> = {
        fields: [
            { key: "facilityStreet", label: strings.street },
            { key: "facilityCity", label: strings.city },
            { key: "facilityState", label: strings.state },
            { key: "facilityZip", label: strings.zip }
        ],
        render: FacilityAddressForm,
        title: strings.addAFacility,
        subtext: strings.whatIsYourFacilityAddress,
    }

    const chooseFms: CreateCompanyStep<ICreateCompanyForm> = {
        fields: [{ key: "managementSoftware" },],
        render: ChooseFmsStep,
        validate: async (form: IForm<ICreateCompanyForm>) => {
            if (!form.values.managementSoftware) {
                return true;
            }
            const result = await agents.Account.validateFms(form.values as IFmsForm)
            if (!result.data) {
                displayToast({
                    type: ToastType.Warning,
                    title: strings.incompleteInformation,
                    text: strings.managmentSoftwareInfo,
                })
                return false;
            }
            return true;
        },
        title: strings.addAFacility,
        subtext: strings.whatIsYourFMS,
    }

    const doYouHaveFms: CreateCompanyStep<ICreateCompanyForm> = {
        fields: [],
        render: DoYouHaveFmsStep,
        title: strings.addAFacility,
        subtext: strings.doYouUseAnFMS,
    }

    const termsOfUseStep: CreateCompanyStep<ICreateCompanyForm> = {
        fields: [{ key: "acceptedTerms" },],
        subtext: "",
        title: termsOfUse.title,
        render: ({ form: { inputProps: { acceptedTerms } }, next }: RenderProps<ICreateCompanyForm>) => (
            <>
                <TermsOfUseAndPrivacyPolicy setAccepted={acceptedTerms?.setValue || (() => { })} accepted={acceptedTerms?.value} />
                <ContinueButton disabled={!acceptedTerms?.value} onClick={() => next()} fullWidth>{strings.continue}</ContinueButton>
            </>
        )
    }

    const password: CreateCompanyStep<ICreateCompanyForm> = {
        fields: [
            {
                key: "password", component: (props: IFieldProps) => (
                    <>
                        <input id="password" style={{ display: "none" }} type="password" name="fakepasswordremembered" />
                        <Input {...props} type="password" autocomplete="new-password" label={strings.password} />
                    </>
                )
            },
            { key: "confirmPassword", component: (props: IFieldProps) => <Input {...props} type="password" autocomplete="new-password" label={strings.confirmPassword} /> },
        ],
        title: strings.createAccountCompletion,
        subtext: strings.whatIsYourPassword
    }

    return [
        companyNameAndUrl,
        email,
        firstAndLastName,
        phoneNumber,
        companyAddress,
        facilityBrandName,
        facilityName,
        facilityAddress,
        doYouHaveFms,
        chooseFms,
        termsOfUseStep,
        password
    ]
}

export default useSteps

const ContinueButton = styled(PrimaryButton)({
    height: "32px",
    fontSize: "1rem",
    marginTop: "8px"
})
