import styled from "styled-components";

export const Form = styled.form<{ center?: boolean }>(({ center = true }) => ({
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 40px)",
    ...(center ? {
        alignItems: "center",
        justifyContent: "center",
    } : {
        alignItems: "start",
    }),
    padding: "20px",
    borderRadius: "12px",
    position: "relative",
    marginTop: "0px",
}));
