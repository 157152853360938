import styled from "styled-components"
export const Collapsable = styled.div<{ collapsed?: boolean, duration?: number, height: string }>(props => ({
    overflow: 'hidden',
    maxHeight: props.collapsed ? '0px' : props.height && props.height,
    transition: props.duration ? `max-height ${props.duration / 1000}s ease-in` : 'max-height:0.5s ease-in',
}))
export const HorizontalCollapse = styled.div<{ collapsed?: boolean, duration?: number, height: string, width: string }>(props => ({
    overflow: 'hidden',
    height: props.collapsed ? '0px' : props.height && props.height,
    maxWidth: props.collapsed ? '0px' : props.width && props.width,
    transition: props.duration ? `max-width ${props.duration / 1000}s ease-in-out` : 'max-width:0.5s ease-in-out',
}))
