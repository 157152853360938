import React, { useState } from "react"
import styled from "styled-components";
import { Colors } from "../../../core/brand/themes";
import { useNavigate } from "react-router-dom";
import { H3Bold, PBold } from "../../../core/brand/typography";
import { Card } from "../../../core/surfaces/Card";
import AuthenticationForm from "./AuthenticationForm";
import EmailForm from "./EmailForm";
import PasswordForm from "./PasswordForm";
import ProfileForm from "./ProfileForm";
import { strings } from "../../../content/strings";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";

const UserProfileContainer = () => {
    const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>("")
    const { userRef } = useLoggedInUser()
    const navigate = useNavigate();

    const editProfileInitialValues = {
        firstName: userRef?.current?.firstName,
        lastName: userRef?.current?.lastName,
    };
    const editEmailInitialValues = {
        email: userRef?.current?.email,
        newEmail: "",
        confirmEmail: "",
    };

    const editPasswordnitialValues = {
        password: "",
        confirmPassword: "",
    };

    const authenticateInitialValues = {
        email: userRef?.current?.email || "",
        password: ""
    }

    return (
        <>
            <Container>
                <ClippedPathMain >
                    <StyledH3 secondary>{strings.profile}</StyledH3>
                </ClippedPathMain>
                <ClippedPathSecondary onClick={() => navigate("/")} >
                    <StyledP>{strings.home}</StyledP>
                </ClippedPathSecondary>
                <ProfileForm initialValues={editProfileInitialValues} />
                {
                    userAuthenticated ?
                        <>
                            <EmailForm initialValues={editEmailInitialValues} />
                            <PasswordForm initialValues={editPasswordnitialValues} />
                        </> :
                        <AuthenticationForm initialValues={authenticateInitialValues}
                            setErrorMessage={setErrorMessage}
                            setUserAuthenticated={setUserAuthenticated} />

                }
                {errorMessage && <PBold>{errorMessage}</PBold>}
            </Container >
        </>
    )
}

const Container = styled(Card)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
    width: "400px",
    margin: "auto",
    padding: "24px",
    marginTop: "32px",
    position: "relative",
    border: `solid ${Colors.red()} 2px`,
    "@media (max-width: 570px)": {
        width: "80%",
    },
});

const ClippedPathMain = styled.div({
    width: "100%",
    height: "35px",
    background: Colors.red(),
    position: "absolute",
    top: "0", left: "0",
    clipPath: "polygon(100% 0,100% 100%,0 100%,0 0) ",
})

const ClippedPathSecondary = styled.div({
    width: "30%",
    height: "35px",
    background: Colors.yellow(),
    position: "absolute",
    top: "0",
    left: "70%",
    clipPath: "polygon(100% 0,100% 100%,0 100%,60px 0) ",
    cursor: "pointer"
})

const ImgContainer = styled.div({
    display: "flex",
    justifyContent: "center"
})

const StyledP = styled(PBold)({
    margin: "10px 0 0 45%"
})

const StyledH3 = styled(H3Bold)({
    margin: "2px"
})

export default UserProfileContainer;
