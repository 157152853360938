import agents from '../../api/agent';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICompany } from '../../models/ICompany';
import { IState } from '../IState';
import { fetchData } from '../fetchData';

interface ICompaniesState extends IState {
    companies: ICompany[],
}

const initialState: ICompaniesState = {
    companies: [],
}

export const fetchCompaniesAsync = createAsyncThunk(
    'Companies/fetchCompaniesAsync',
    async (_, { rejectWithValue }) =>
        fetchData(rejectWithValue, agents.Company.companyListing)
)

export const companiesSlice = createSlice({
    name: "Companies",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompaniesAsync.pending, (state) => {
                return { ...state, pending: true }
            })
            .addCase(fetchCompaniesAsync.fulfilled, (_, action: PayloadAction<any>) => {
                return { companies: action.payload }
            })
            .addCase(fetchCompaniesAsync.rejected, (state, action: PayloadAction<any>) => {
                return { ...state, error: action.payload }
            })
            .addMatcher(({ type }: { type: string }) => type === "LOGOUT",
                () => ({ companies: [] })
            )
            .addDefaultCase((state) => {
                return state;
            })
    }
})
