import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useContext, useState } from 'react';
import { PBold, } from '../../../core/brand/typography';
import { PrimaryButton } from '../../../components/button';
import { Colors } from '../../../core/brand/themes';
import { strings } from '../../../content/strings';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import styled from '@emotion/styled';
import { SelectUser } from '../../../core/form-components/SelectUser'
import { ICompanyUser } from '../../../models/ICompanyUser';
import { useLoggedInUser } from '../../../hooks/useLoggedInUser';
import agents from '../../../api/agent';
import { ToastContext } from '../../../contexts/ToastProvider';
import { ToastType } from '../../../enums/ToastType';
import { debugLog } from '../../../utils/debugLog';

const TransferOwnership = () => {
    const [user, setUser] = useState<ICompanyUser | null>(null)
    const { loggedInUser } = useLoggedInUser()
    const { displayToast } = useContext(ToastContext);

    const handleSubmit = () => {
        const transferOwnership = async () => {
            try {
                if (user?.userID) {
                    const result = await agents.Users.TransferOwnership(user?.userID)

                    if (result.success) {
                        displayToast({ type: ToastType.Success, text: result.data || "Success" })
                    } else {
                        displayToast({ type: ToastType.Error, text: result.error || "something went wrong" })
                    }
                }
            } catch (e: any) {
                debugLog({ e })
            }
        }

        transferOwnership()
    }

    return (
        <Grid container>
            <Grid item xs={12} >
                <HeaderBox>
                    <Title>{strings.transferOwnership('sm')}</Title>
                </HeaderBox>
            </Grid>
            <Grid item container xs={12} sx={{ padding: "24px 8px", gap: { xs: "8px", sm: "32px" } }} alignItems="center" justifyContent='center'>
                <Grid item xs={12} sm={4} md={5} lg={3.5} sx={{ maxWidth: "100px" }}>
                    <SelectUser
                        setValue={setUser}
                        value={user?.userID}
                        handleClear={() => { }}
                    />
                </Grid>
                <Grid container item xs={12} sm={6} md={5} alignItems="start">
                    <PrimaryButton onClick={handleSubmit} disabled={!user?.userID || loggedInUser?.userID === user.userID}>
                        <FontAwesomeIcon size="lg" icon={faKey as IconProp} style={{ margin: "2px 8px" }} />
                        {strings.transferOwnership('lg')}</PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

const HeaderBox = styled(Box)({
    width: "100%", minHeight: "36px",
    background: Colors.red(),
    padding: "4px 0",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center"
})

const Title = styled(PBold)({
    textAlign: "start",
    color: Colors.white(),
    margin: "0px 20px"
})
export default TransferOwnership;
