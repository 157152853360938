import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Table from "../../core/table/Table";
import { strings } from "../../content/strings";
import { ICompany } from "../../models/ICompany";
import { useCompanies } from "../../hooks/useCompanies";
import { useNavigate } from "react-router-dom"
import SearchField from "../../core/form-components/SearchField";
import { ActionsContainer } from "../users-listing/components/UsersListingContainer";
import TableWrapper from "../../core/table/TableWrapper";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import TableTitle from "../../core/table/TableTitle";
import { getSubscriptionTierDisplay, getSubscriptionTierEnum, subscriptions } from "../../enums/SubscriptionTier";
import { PrimaryButton, QuaternaryButton } from "../../components/button";
import AutoCompleteDynamic from "../../core/form-components/AutoCompleteDynamic";
import { Box, Checkbox, Dialog, FormControlLabel, FormGroup, Typography } from "@mui/material";
import agent from "../../api/agent";
import { ToastContext } from "../../contexts/ToastProvider";
import { ToastType } from "../../enums/ToastType";
import { debugLog } from "../../utils/debugLog";
import { Form } from "../../core/form-components/Form";
import { H4Meduim } from "../../core/brand/typography";

const rowsPerPage = { initial: 20, options: [5, 10, 20] };

const mobileKeys: { text: string; key: string; endOfRow?: any; }[] = [
    { text: strings.clientAdmin, key: "clientAdmin" },
    { text: strings.phoneNumber, key: "phoneNumber" },
    { text: strings.noOfActiveLocks, key: "noOfActiveLocks", endOfRow: true },
];

const EXCLUDE_ROW_PROP = "exclude-row-prop";

const CompanyListingContainer = () => {

    const navigate = useNavigate();
    const { companies, fetchCompanies } = useCompanies();
    const [changeSubTierTrigger, setChangeSubTierTrigger] = useState(-1)
    const [submit, setSubmit] = useState(false)
    const [updateSubInformation, setUpdateSubInformation] = useState<any>({ companyID: null, subscriptionTier: null, value: null });
    const [checked, setChecked] = useState<number | null>(null);
    const [filteredCompanies, setFilteredCompanies] = useState(companies);
    const [mobile] = useMediaQuery(414);
    const { displayToast } = useContext(ToastContext)

    const company = useMemo(() => {
        return companies.filter((c:any) => c.companyID == checked)[0] || null
        
    }, [checked])
    
    const selectRow = useCallback((company: ICompany, event: any) => {
        if (event.target.id == EXCLUDE_ROW_PROP || changeSubTierTrigger == company.companyID) { 
            return
        }
            navigate(`/Company/CompanyDetails?companyID=${company.companyID}`)
    }, [changeSubTierTrigger])

    const caculateDaysPastDue = (dateString: any) => {
        if (!dateString) return "";
        const pastDate = new Date(dateString);
        const currentDate = new Date();
        return Math.floor((currentDate - pastDate) /(24 * 60 * 60 * 1000))
    }
    const subscriptionStatusOptions = [{ label: "Suspended", value: true }, { label: "Not Suspended", value: false }]

    const headers = useMemo(() => {
        return [
            { text: strings.companyName, key: "companyName" },
            { text: strings.clientAdmin, key: "clientAdmin" },
            { text: strings.email, key: "email" },
            { text: strings.noOfActiveLocks, key: "noOfActiveLocks" },
            {
                text: "Subscription",
                key: "subscriptionTier",
                content: (row: any) => renderSubscription(row)
            },
            { text: "Update Subscription", key: "", content: (row: any) => renderSubTierCell(row) },
            { text: "Days Since Last Payment", key: "lastPaymentOn", date: false, content: (row: any) => caculateDaysPastDue(row.lastPaymentOn)},
            { text: "Subscription Status", key: "subscriptionSuspended", content: (row: any) => renderSuspendSubscription(row), filterOptions: subscriptionStatusOptions },

        ];
    }, [changeSubTierTrigger, checked]);

    const handleChange = async (e: any, row:any) => {
        setUpdateSubInformation({ companyID: row.companyID, value: e, subscriptionTier: getSubscriptionTierEnum(e) })
    }

    const handleCheckboxChange = (row: any) => {
        if (row.companyID == checked) {
            setChecked(null)
        }
        setChecked(row.companyID)
    };

    const renderSubTierCell = (row: any) => {
        if (changeSubTierTrigger == row.companyID) {
            return (
                <Box sx={{display:"flex", alignItems:"center"}}>
                    <AutoCompleteDynamic
                        label="Update Subscription"
                        options={subscriptions}
                        value={row.value}
                        setValue={(e: any) => handleChange(e, row)}
                        solo
                    />
                    <PrimaryButton onClick={() => setSubmit(true)}>Submit</PrimaryButton>
                </Box>
                )
        }
    
        return (
        <Box sx={{minWidth:"200px"}} >
            <PrimaryButton id={EXCLUDE_ROW_PROP} onClick={() => setChangeSubTierTrigger(row.companyID)}>Update Subscription</PrimaryButton>
        </Box>
        )
    }

    const renderSubscription = (row: any) => {
        const suspended = row.subscriptionSuspended
        const subTier = suspended ? getSubscriptionTierDisplay(row.preSuspendedSubscription) : getSubscriptionTierDisplay(row.subscriptionTier)
        return (
            <Box>
                <Typography sx={{ textDecoration: suspended ? "line-through" :"auto"}} variant="subtitle2">{ subTier}</Typography>
            </Box>
        )
    }

    const renderSuspendSubscription = useCallback((row: any) => {
        const label = row.subscriptionSuspended ? "Suspended" : "Not Suspended";
        return (
                <FormGroup id={EXCLUDE_ROW_PROP}>
                <FormControlLabel control={<Checkbox checked={checked === row.companyID || row.subscriptionSuspended}  onChange={() => handleCheckboxChange(row)} id={EXCLUDE_ROW_PROP} />} label={label} />
                </FormGroup>);
        }, [checked])
    

    const rowsProps = useMemo(() => ({ onClick: selectRow }), [selectRow]);

        useEffect(() => {
            const updateSubscription = async () => {
                try {
                    const { companyID, subscriptionTier } = updateSubInformation;
                    const result = await agent.Company.updateCompanySubscriptionTierAsync(companyID, subscriptionTier, false);
                    if (result.success) {
                        setSubmit(false);
                        displayToast({ type: ToastType.Success, text: "Subscription tier changed successfully" })
                        fetchCompanies();
                        setChangeSubTierTrigger(-1)
                    }
                } catch (e) {
                    debugLog(e);
                }
            };

            if (submit) {
                updateSubscription();
            }
        }, [submit, updateSubInformation]);

    
    const suspendSubscriptionasync = async () => {
        try {
            const { companyID, subscriptionTier, preSuspendedSubscription, subscriptionSuspended } = company!
            const tier = subscriptionSuspended ? preSuspendedSubscription : subscriptionTier;
            const result = await agent.Company.updateCompanySubscriptionTierAsync(companyID, tier, !subscriptionSuspended);
            if (result.success) {
                setChecked(null);
                displayToast({ type: ToastType.Success, text: "Company Subscription suspended" })
                fetchCompanies();

            }
        } catch (e) {
            console.log({ e })
        }
    }
    
    return (
        <Container>
            <ActionsContainer>
                <TableTitle title={strings.companies} />
                <SearchField
                    data={companies}
                    searchKeys={["companyName", "clientAdmin", "email"]}
                    searchLabel={strings.companiesSearchLabel}
                    fullWidth={mobile}
                    setFilterData={setFilteredCompanies} />
            </ActionsContainer>
            <TableWrapper>
                <Table
                    tooltip={strings.tableTooltip}
                    data={filteredCompanies}
                    headers={headers}
                    rowsPerPage={rowsPerPage}
                    rowProps={rowsProps}
                    mobileIdentifier="companyName"
                    mobileHeaders={mobileKeys}
                />

            </TableWrapper>
            <Dialog onClose={() => setChecked(null)} open={!!checked}>
                <Form>
                    <H4Meduim>{`Are you sure you want to ${company?.subscriptionSuspended ? "unsuspend" : "suspend"} company: ${company?.companyName}?`}</H4Meduim>
                    <div style={{ display: "flex", paddingBottom: "12px" }}>
                        <PrimaryButton onClick={suspendSubscriptionasync} >{strings.yes}</PrimaryButton>
                        <QuaternaryButton onClick={() => setChecked(null)}>{strings.no}</QuaternaryButton>
                    </div>
                </Form>

            </Dialog>
        </Container >
    )
}

const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    padding: "8px",
    boxSizing: "border-box",
})

export default CompanyListingContainer
