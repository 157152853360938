import { Divider, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useContext } from 'react';
import { PBold, PThin } from '../../../core/brand/typography';
import { PrimaryLink } from '../../../components/button';
import { Colors } from '../../../core/brand/themes';
import { strings } from '../../../content/strings';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faUser, faUserGear } from "@fortawesome/free-solid-svg-icons";
import styled from '@emotion/styled';
import { useUsers } from '../../../hooks/useUsers';
import { SubscriptionContext } from '../../../contexts/SubscriptionProvider';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { Box } from '@mui/system';
import { useLoggedInUser } from '../../../hooks/useLoggedInUser';

const RolesCount = () => {
    const { users } = useUsers();
    const { isSubscribed } = useLoggedInUser()
    const { handleOpen, renderDialog } = useContext(SubscriptionContext);
    const navigate = useNavigate();

    const rolesMap = {
        "Administrators": 0,
        "Call Center Agents": 0,
        "Personnel": 0,
        "Restricted Personnel": 0,
        "Vendors": 0,
    }

    users.map((user: any) => {
        switch (user.roleID) {
            case 4:
                rolesMap["Administrators"] += 1;
                break;
            case 5:
                rolesMap["Personnel"] += 1;
                break;
            case 7:
                rolesMap["Call Center Agents"] += 1;
                break;
            case 9:
                rolesMap["Restricted Personnel"] += 1;
                break;
            case 11:
                rolesMap["Vendors"] += 1;
                break;
            default:
                break;
        }
    });

    const handleClick = () => {
        if (isSubscribed()) {
            navigate("/Company/RolesSettings")
        } else {
            handleOpen && handleOpen();
            renderDialog && renderDialog();
        }
    }

    const [isMobile] = useMediaQuery(415)

    if (isMobile) {
        return (
            <Box>
                <Grid container lg={12} xs={12} justifyContent="space-between">
                    {Object.entries(rolesMap).map(([type, count]) => {
                        return (
                            <>
                                <Grid item xs={8} sx={{ px: 1 }}>
                                    <PBold left style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block" }}>{type}</PBold>
                                </Grid>
                                <Grid item xs={4} sx={{ px: 1 }}>
                                    <PThin left>{count}</PThin>
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
                <Divider orientation="horizontal" sx={{ background: Colors.red() }} />
                <Box sx={{ pt: 1, px: 1, boxSizing: "border-box" }}>
                    <PremiumLockButton fullWidth onClick={handleClick} subscribed={isSubscribed()}>
                        <FontAwesomeIcon size="lg" icon={faUserGear as IconProp} />
                        {strings.createCustomRole}</PremiumLockButton>
                </Box>
            </Box>
        )
    }

    return (
        <Grid container alignItems="center" justifyContent="space-around" sx={{ width: "100%" }} >
            <Grid item container lg={8.5} xs={5} alignItems={{ xs: "center", md: "flex-start" }} justifyContent="space-between" direction={{ xs: 'column', md: 'row' }}>
                {Object.entries(rolesMap).map(([type, count]) => {
                    return (
                        <Grid item container xs={6} md={5} lg={2} sx={{ height: "100%", gap: "20px" }}
                            direction="column"
                            justifyContent="center"
                            alignItems={{ lg: "center", xs: "start" }}>
                            <Grid item xs={12}>
                                <PBold style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block" }}>{type}</PBold>
                            </Grid>
                            <Grid item xs={12} >
                                <PThin >{count}</PThin>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <Divider orientation="vertical" sx={{ background: Colors.red(), width: "1px", height: "100%", overflow: "visible" }} />
            <Grid item container lg={2.5} xs={5} sx={{ width: "100%" }} gap="20px" alignSelf="center">
                <Grid item sx={{ width: "100%", textAlign: "center" }} xs={12}>
                    <PrimaryLink link="/Users/ClientUserListing" fullWidth >
                        <FontAwesomeIcon size="lg" icon={faUser as IconProp} style={{ margin: "2px 8px" }} />
                        {strings.viewUsers}</PrimaryLink>
                </Grid>
                <Grid item sx={{ width: "100%", textAlign: "center" }} xs={12}>
                    <Tooltip title="Coming Soon!" placement='bottom-end'>
                        <PremiumLockButton fullWidth onClick={handleClick} subscribed={isSubscribed()} >
                            <FontAwesomeIcon size="lg" icon={faUserGear as IconProp} style={{ margin: "2px 4px" }} />
                            {strings.createCustomRole}</PremiumLockButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid >
    );
}

const PremiumLockButton = styled(PrimaryLink)<{ subscribed: boolean }>(({ subscribed }) => ({
    ...(!subscribed ? {
        background: Colors.navy(),
        color: Colors.yellow(),
    } : {}),
    padding: "4px 8px",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    whiteSpace: "nowrap",
    "&:hover": {
        background: Colors.yellow(),
        color: Colors.red(),
        transition: "all ease-in-out 1s"
    }
}))

export default RolesCount;
