import {
    useEffect,
    useRef
} from "react";
import { getToken } from "../api/agent";
import Role from "../enums/Role";
import { signalRService, joinCompanyGroupMethodName, leaveCompanyGroupMethodName } from "../contexts/HubContext";
import { useCompanyID } from "./useCompanyID";

/** Custom Hook for Site Level users to listen to selected company changes */
export function useSiteRoleCompanyConnection(
    isReady: boolean,
    roleID?: number) {

    const { companyID } = useCompanyID();
    const companyIDRef = useRef(companyID)
    companyIDRef.current = companyID
    const currentCompanyConnectionID = useRef<number | null>(null);

    useEffect(() => {
        if (!isReady) {
            return
        }
        if (!currentCompanyConnectionID.current &&
            companyID &&
            Role.isSiteRole(roleID)) {
            signalRService.getConnection()?.invoke(
                joinCompanyGroupMethodName,
                getToken(),
                companyID,
            );
            currentCompanyConnectionID.current = companyID;
        } else if (currentCompanyConnectionID.current &&
            !companyID) {
            signalRService.getConnection()?.invoke(
                leaveCompanyGroupMethodName,
                getToken(),
                currentCompanyConnectionID.current,
            );
            currentCompanyConnectionID.current = null;
        }
    }, [companyID, isReady, roleID]);
}
