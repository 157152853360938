import React from "react"
import { Box } from "@mui/material";

interface TabPanelProps {
    children?: JSX.Element | null
    index: number;
    value: number;
}

const TabPanel = ({ children, value, index, }: TabPanelProps) => {

    return (
        <Box
            sx={{ minHeight: "667px" }}
            role="tabpanel"
            hidden={value !== index}
            id={`company-tabpanel-${index}`}
            aria-labelledby={`company-tab-${index}`}
        >
            {value === index && (
                children
            )}
        </Box>
    );
}

export default TabPanel
