import { useEffect, useMemo, useState } from "react";
import { FacilityDto } from "../models/facility";
import { calcLatLongDistance } from "../utils/calcLatLongDistance";
import { LatLongResult } from './useLatLong'

export const useClosestFacility = (currentPosition: LatLongResult, facilities: FacilityDto[]) => {
    return useMemo(() => {
        if (!currentPosition.loading && facilities.length) {
            if (!currentPosition.error) {
                const result = facilities.reduce(
                    (acc: { facility?: FacilityDto, distance?: number }, facility: FacilityDto) => {
                        if (!facility.lat || !facility.lng) {
                            return acc;
                        }
                        const distance = calcLatLongDistance(
                            currentPosition.latitude, currentPosition.longitude,
                            facility.lat, facility.lng
                        )

                        if (!acc.distance || distance < acc.distance) {
                            return { facility, distance }
                        }
                        return acc
                    }, {})

                return result.facility || null
            }
        } else {
            return null;
        }
    }, [currentPosition, facilities])
}
