import { strings } from '../content/strings';

export const fetchData = async (
    rejectWithValue: (error: string) => any,
    fetchMethod: (id?: number) => any,
    id?: number
) => {
    const response = await fetchMethod(id);

    if (response.data) {
        return response.data;
    }
    return rejectWithValue(response.error || strings.somethingWentWrong);
};
