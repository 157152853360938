import { Box } from "@mui/material";
import { useMemo, useState } from "react"
import styled from "styled-components";
import { PrimaryButton } from "../../components/button";
import { SearchInput } from "../../core/form-components/SearchField";
import Table from "../../core/table/Table";
import { strings } from "../../content/strings";
import { useDebounce, useFreeSerialCodes, useMediaQuery, useUserRoleID } from "../../hooks/hooks";
import { BladePopout } from "../../core/layout/BladePopout";
import AddLockForm from "./components/AddFreeLockForm";
import Role from "../../enums/Role";
import TableWrapper from "../../core/table/TableWrapper";
import TableTitle from "../../core/table/TableTitle";

const filterKeys = ["SerialCode", "Pin"]

const FreeSerialCodesContainer = () => {
    const { freeLocks, fetchFreeLocks } = useFreeSerialCodes();
    const [searchValue, setSearchValue] = useState<string>("");
    const [addFreeLocks, setAddFreeLocks] = useState(false)
    const debouncedSearchValue = useDebounce<string>(searchValue, 300);
    const roleID = useUserRoleID();
    const [mobile] = useMediaQuery(414);

    const handleAddLock = () => {
        setAddFreeLocks(true)
    }

    const handleCloseBlade = () => {
        setAddFreeLocks(false)
    }

    const headers: {
        text: string,
        key: string,
        date?: boolean,
        content?: any,
        normalSort?: boolean,
        filterOptions?: string[] | { label: string, value: any }[]
    }[] = useMemo(() =>
        [
            { text: strings.serialCode, key: "serialCode", normalSort: true },
            { text: strings.unlockCode, key: "unlockCode" },
            { text: strings.pin, key: "pin" },
        ], []
    )
    const mobileButtonStyles = mobile ? { width: "95%", marginTop: "-4px", marginBottom: "8px" } : {}

    return (
        <Container>
            <ActionsContainer>
                <TableTitle title={strings.freeSerialCodes} />
                <SearchInput
                    searchLabel={strings.serialCode}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    handleSearchChange={(e: any) => setSearchValue(e.target.value)}
                    fullWidth={mobile}
                />
                <Box sx={{ marginTop: "8px" }} >
                    <PrimaryButton style={mobileButtonStyles} disabled={Role[roleID!] === "Manufacturing QA"} onClick={handleAddLock}>{strings.addLocks} </PrimaryButton>
                </Box>
            </ActionsContainer>
            <TableWrapper>
                <Table
                    data={freeLocks.locks}
                    headers={headers}
                    rowsPerPage={{ initial: 10, options: [5, 10, 20, 50, 100] }}
                    useServer
                    total={freeLocks.total}
                    filterKeys={filterKeys}
                    filterString={debouncedSearchValue}
                    fetchDataFromServer={fetchFreeLocks}
                    mobileIdentifier="serialCode"
                    mobileHeaders={headers.filter((h: any) => h.key !== "serialCode")}
                />
            </TableWrapper>

            <BladePopout
                headerText={strings.addLocks}
                show={!!addFreeLocks}
                closeBlade={handleCloseBlade}
            >
                {
                    addFreeLocks &&
                    <AddLockForm
                        fetchFreeLocks={fetchFreeLocks}
                        closeBlade={handleCloseBlade} />
                }

            </BladePopout>
        </Container>
    );
}

export default FreeSerialCodesContainer;

const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    padding: "8px",
    boxSizing: "border-box",
})

const ActionsContainer = styled.div({
    display: "grid",
    width: "100%",
    gridTemplateColumns: "minmax(135px, 1fr) repeat(4, auto)",
    alignItems: "baseline",
    "@media (max-width: 414px)": {
        gridTemplateColumns: "1fr",
        gap: "4px",
    },
})
