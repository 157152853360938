import React, { useContext, useEffect, useState } from 'react';
import { IAPIKey } from "../../../models/IAPIKey";
import AddEditAPIKey from "./AddEditAPIKey";
import SearchField from "../../../core/form-components/SearchField";
import { Card } from "../../../core/surfaces/Card"
import { PrimaryButton } from "../../../components/button"
import { strings } from "../../../content/strings";
import Table from "../../../core/table/Table"
import { BladePopout } from "../../../core/layout/BladePopout";
import useAnalytics from "../../../hooks/useAnalytics";
import { events as analyticsEvents } from "../../../mixPanelEvents";
import agents from "../../../api/agent";
import styled from 'styled-components';
import TableWrapper from '../../../core/table/TableWrapper';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { ToastContext } from '../../../contexts/ToastProvider';
import { ToastType } from '../../../enums/ToastType';

const headers: { text: string, key?: string, date?: boolean, content?: (tr: IAPIKey) => any }[] =
    [
        { text: strings.nameUpperCase, key: "name" },
        { text: strings.apiKey, key: "key" },
        { text: strings.apiSecret, content: (tr: IAPIKey) => strings.editToShow },
        { text: strings.noOfFacilities, content: (tr: IAPIKey) => <>{tr.allFacilities ? strings.all : tr.facilityIds.length}</> },
    ];

const initialApiKeyValues = {
    allFacilities: true,
    facilityIds: [],
    id: 0,
    isActive: true,
    key: "",
    name: "",
    secret: "",
}

const ApiListing = ({ writePermissions }: { writePermissions: boolean }) => {
    const { trackClickEvent } = useAnalytics("API Settings", false)
    const { displayToast } = useContext(ToastContext);
    const [apiKeys, setApiKeys] = useState<IAPIKey[]>([])
    const [filteredApiKeys, setFilteredApiKeys] = useState<IAPIKey[]>([]);
    const [selectedKey, setSelectedKey] = useState<IAPIKey | null>(null);
    const [addAPIKey, setAddAPIKey] = React.useState(false);
    const [mobile] = useMediaQuery(414);

    useEffect(() => {
        fetchApiKeys()
    }, [])

    const mobileApiKeys = [,
        {
            key: "", text: strings.noOfFacilities, content: (tr: IAPIKey): any => tr.allFacilities ? strings.all : tr.facilityIds?.length,
            endOfRow: true
        }
    ];

    const fetchApiKeys = async () => {
        const result = await agents.Company.getApiKeys()
        if (result.success && result.data) {
            setApiKeys(result.data)
        }
    }

    const handleAddApiKey = async () => {
        await trackClickEvent(analyticsEvents.showAddApiKeyForm)
        setAddAPIKey(true);
    }

    const handleCloseBlade = () => {
        trackClickEvent(analyticsEvents.closeForm)
        if (addAPIKey) setAddAPIKey(false)
        if (selectedKey) setSelectedKey(null)
        fetchApiKeys()
    }

    const selectRow = async (apiKey: IAPIKey) => {
        if (!writePermissions) {
            displayToast({ type: ToastType.Info, text: strings.insufficientPermissions })
            return;
        }
        await trackClickEvent(analyticsEvents.showEditApiKeyForm, { apiKeyID: apiKey.id })
        setSelectedKey(apiKey)
    }

    const rowsPerPage = { initial: 10, options: [5, 10, 20] }
    const rowsProps = { onClick: selectRow };
    const mobileButtonStyles = mobile ? { width: "95%", marginTop: "-4px", marginBottom: "8px" } : {}

    return (

        <>
            <ActionsContainer>
                <SearchField
                    data={apiKeys}
                    searchKeys={["name", "key"]}
                    searchLabel={strings.nameOrKey}
                    setFilterData={setFilteredApiKeys}
                    fullWidth={mobile}
                />
                {writePermissions &&
                    <span style={{ marginTop: "4px" }}>
                        <PrimaryButton style={mobileButtonStyles} onClick={handleAddApiKey}>{strings.createApiKey}</PrimaryButton>
                    </span>
                }
            </ActionsContainer>

            <TableWrapper>
                <Table
                    tooltip={strings.clickToEdit}
                    data={filteredApiKeys}
                    headers={headers}
                    rowsPerPage={rowsPerPage}
                    rowProps={rowsProps}
                    selectedRow={selectedKey}
                    mobileIdentifier="name"
                    mobileHeaders={mobileApiKeys as any}
                />
            </TableWrapper>

            <BladePopout
                headerText={addAPIKey ? strings.addApiKey : strings.editApiKey}
                show={!!selectedKey || addAPIKey}
                closeBlade={handleCloseBlade}>
                {(selectedKey || addAPIKey) &&
                    <AddEditAPIKey
                        editting={!addAPIKey}
                        closeBlade={handleCloseBlade}
                        initialValue={addAPIKey ? initialApiKeyValues : selectedKey!} />
                }
            </BladePopout>
        </>
    );
}

const ActionsContainer = styled.div({
    display: "flex",
    justifyContent: "end",
    width: "100%",
    "@media (max-width: 414px)": {
        flexDirection: "column",
        gap: "4px",
    },
})

export default ApiListing;
