import React, { useContext, useEffect } from "react";
import agents from "../../../api/agent";
import LockStatus, { lockStatuses } from "../../../enums/LockStatus";
import { H4Bold } from "../../../core/brand/typography";
import { PrimaryButton } from "../../../components/button";
import { useForm } from "../../../hooks/useForm";
import { ILockModelForm } from "../../../models/ILockModelForm";
import { inventoryLockModelValidator } from "../../../validators/validators";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { strings } from "../../../content/strings";
import { EditUnitNumberField } from "../../serial-codes/components/EditUnitNumberField";
import { ProtectedInput } from "../../serial-codes/components/ProtectedInput";
import AutoComplete from "../../../core/form-components/AutoComplete";
import { SelectFacility } from "../../../core/form-components/SelectFacility";
import { FacilityDto } from "../../../models/facility";
import { useLocks } from "../../../hooks/useLocks";

export const EditInventoryForm = ({ closeBlade, initialValue, trackClickEvent }:
    {
        initialValue: ILockModelForm,
        closeBlade: () => void,
        trackClickEvent: (arg0: string, arg1?: any) => void;
    }) => {
    const { displayToast } = useContext(ToastContext)
    const { updateLockFromClient } = useLocks();

    const submitAction = async (formValues: ILockModelForm) => {
        trackClickEvent("Update Lock")
        try {
            updateLockFromClient(formValues);

            const transferResult = await agents.Locks.transferLock(formValues)
            if (!transferResult.success) {
                displayToast({ type: ToastType.Error, text: transferResult.error || strings.somethingWentWrong })
                updateLockFromClient(initialValue);
                return;
            }
            const result = await agents.Locks.updateLock(formValues);
            if (result.success) {
                /* TODO: Remove redundency in fetching locks and signalR. (notify all except sender? Or just rely on SignalR?)
                    we guess update is successful to show change immediately on the table,
                    we then fetch the update
                    we also get notified by SignalR that an update occurred and fetch it again.
                */
                displayToast({ type: ToastType.Success })
                closeBlade();
            } else {
                updateLockFromClient(initialValue);
                displayToast({ type: ToastType.Error, text: result.error || strings.somethingWentWrong })
            }
        } catch (err: any) {
            updateLockFromClient(initialValue);
            displayToast({ type: ToastType.Error, text: err.message })
        }
    }

    const form = useForm<ILockModelForm>(
        initialValue, submitAction, inventoryLockModelValidator
    );

    const secondaryLockStatusProps = form.inputProps.secondaryLockStatus!
    const unitNumberProps = form.inputProps.unitNumber;
    const facilityIDProps = form.inputProps.facilityID

    useEffect(() => {
        unitNumberProps?.setValue("")
    }, [facilityIDProps?.value])

    return (
        <>
            <H4Bold>{strings.serialCode} {form.inputProps.serialCode!.value}</H4Bold>
            <AutoComplete
                value={LockStatus[secondaryLockStatusProps.value as keyof typeof LockStatus]}
                setValue={(val: keyof typeof LockStatus) => {
                    secondaryLockStatusProps.setValue(LockStatus[val] as number)
                }}
                noClear
                options={lockStatuses as string[]}
                label={strings.status}
            />

            <div style={{ margin: "25px 0 0 0" }}>
                <ProtectedInput
                    {...form.inputProps.unlockCode}
                    required
                    label={strings.unlockCode}
                    available={initialValue.secondaryLockStatus === LockStatus["Available"]}
                />
            </div>

            <SelectFacility
                setValue={(facilityDto: FacilityDto) => facilityIDProps?.setValue(facilityDto.facilityID)}
                facilityID={(facilityIDProps?.value || 0) as number}
                noClear
            />

            <EditUnitNumberField
                fieldProps={form.inputProps.unitNumber!}
                secondaryLockStatus={secondaryLockStatusProps.value as number}
                facilityID={(facilityIDProps?.value || 0) as number}
                trackClickEvent={trackClickEvent}
            />

            <span style={{ display: "flex", justifyContent: "space-evenly", gap: "16px" }}>
                <div style={{ width: "100%", margin: "8px" }} />
                <PrimaryButton fullWidth onClick={form.handleSubmit}>{strings.submit}</PrimaryButton>
            </span>
        </>
    )
}
