import React, { useContext, useState } from "react";
import { Paper, Box } from '@mui/material';
import { H3Bold, PBold } from "../../../core/brand/typography";
import styled from "@emotion/styled";
import { strings } from "../../../content/strings";
import { privacyPolicy } from "../../../content/privacyPolicy";
import agents from "../../../api/agent";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../../hooks/useLogout";
import { useUpdateLoggedInUser } from "../../../hooks/useUpdateLoggedInUser";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { PrimaryButton } from "../../../components/button";
import { useLoggedInUser, } from "../../../hooks/useLoggedInUser";
import Role from "../../../enums/Role";
import { TermsOfUseAndPrivacyPolicy } from "./TermsOfUseAndPrivacyPolicy";
import { ProgressionSteps } from "../../../enums/ProgressionSteps"

export enum TermsView {
    Default,
    TermsOfUse,
    PrivacyPolicy,
}

const TermsOfUseContainer = () => {
    const [accepted, setAccepted] = useState(false);
    const [mobile] = useMediaQuery(560)

    const { displayToast } = useContext(ToastContext);
    const navigate = useNavigate();
    const { logout } = useLogout();
    const { loggedInUser } = useLoggedInUser();
    const { updateLoggedInUser } = useUpdateLoggedInUser();

    const handleError = () => {
        displayToast({ type: ToastType.Error, text: strings.somethingWentWrong })
        setTimeout(() => {
            logout();
        }, 500)
    }

    const handleSuccess = async (user: any) => {
        displayToast({ type: ToastType.Success, text: strings.thankYou })
        await updateLoggedInUser(user)

        navigate(Role.getDashLink(loggedInUser?.roleID).link || "/");
    }

    const handleSubmit = async () => {
        try {
            const result = await agents.Company.completeStep(ProgressionSteps.TermsOfUse20230101);

            if (!result.success) {
                handleError();
            } else {
                handleSuccess(result.data)
            }
        } catch (e) {
            handleError();
        }
    }

    return (
        <Container>
            <H3Bold>{strings.termsOfUseUpdated}</H3Bold>
            <Card elevation={2} sx={{ width: mobile ? "100%" : "auto" }}>
                <TermsOfUseAndPrivacyPolicy setAccepted={setAccepted} accepted={accepted} />
            </Card>
            <PrimaryButton disabled={!accepted} onClick={handleSubmit}>{strings.accept}</PrimaryButton>
        </Container>
    )
}

export const TermsText = styled(PBold)({
    textAlign: "left",
    padding: "8px",
    marginTop: "8px"
});

const Container = styled(Box)({
    margin: "16px 0px",
    height: "450px",
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    gap: "32px"
})

const Card = styled(Paper)({
    padding: "8px",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: 'start',
    gap: "16px",
})

export const TermsLink = styled.span({
    cursor: "pointer",
    textDecoration: "underline"

});

export default TermsOfUseContainer;
