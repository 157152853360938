import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { PBold, PThin } from '../../../core/brand/typography';
import { PrimaryLink } from '../../../components/button';
import { Colors } from '../../../core/brand/themes';
import { strings } from '../../../content/strings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Obj } from '../../../models/obj';
import Role from '../../../enums/Role';
import styled from '@emotion/styled';
import { Card } from '../../../core/surfaces/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router';
import { CustomRole } from '../../../models/CustomRole';
import { useCustomRoles } from '../../../hooks/useCustomRoles';
import Permission from '../../../enums/Permission';
import { IPermission } from '../../../models/IPermission';
import { useLoggedInUser } from '../../../hooks/useLoggedInUser';

const permissions = [
    {
        role: "Owner",
        permissions: [
            { id: Permission.Locks, accessLevel: 2, name: "" },
            { id: Permission['Activate Locks'], accessLevel: 2, name: "" },
            { id: Permission.Inventory, accessLevel: 2, name: "" },
            { id: Permission['Tenant Locks'], accessLevel: 2, name: "" },
            { id: Permission.Facilities, accessLevel: 2, name: "" },
            { id: Permission['Transfer Facility'], accessLevel: 2, name: "" },
            { id: Permission.Users, accessLevel: 2, name: "" },
            { id: Permission['Edit Admins'], accessLevel: 2, name: "" },
            { id: Permission.Subdomains, accessLevel: 2, name: "" },
            { id: Permission.ApiSettings, accessLevel: 2, name: "" },
            { id: Permission['Lock Returns'], accessLevel: 2, name: "" },
        ],
        roleID: Role["Owner"]
    },
    {
        role: "Administrator",
        permissions: [
            { id: Permission.Locks, accessLevel: 1, name: "" },
            { id: Permission['Activate Locks'], accessLevel: 2, name: "" },
            { id: Permission.Inventory, accessLevel: 1, name: "" },
            { id: Permission['Tenant Locks'], accessLevel: 2, name: "" },
            { id: Permission.Facilities, accessLevel: 2, name: "" },
            { id: Permission['Transfer Facility'], accessLevel: 2, name: "" },
            { id: Permission.Users, accessLevel: 2, name: "" },
            { id: Permission['Edit Admins'], accessLevel: 0, name: "" },
            { id: Permission.Subdomains, accessLevel: 2, name: "" },
            { id: Permission.ApiSettings, accessLevel: 2, name: "" },
            { id: Permission['Lock Returns'], accessLevel: 2, name: "" },
        ],
        roleID: Role["Admin"]
    },
    {
        role: "Personnel",
        permissions: [
            { id: Permission.Locks, accessLevel: 2, name: "" },
            { id: Permission['Activate Locks'], accessLevel: 2, name: "" },
            { id: Permission.Inventory, accessLevel: 0, name: "" },
            { id: Permission['Tenant Locks'], accessLevel: 2, name: "" },
            { id: Permission.Facilities, accessLevel: 1, name: "" },
            { id: Permission['Transfer Facility'], accessLevel: 0, name: "" },
            { id: Permission.Users, accessLevel: 0, name: "" },
            { id: Permission['Edit Admins'], accessLevel: 0, name: "" },
            { id: Permission.Subdomains, accessLevel: 0, name: "" },
            { id: Permission.ApiSettings, accessLevel: 0, name: "" },
            { id: Permission['Lock Returns'], accessLevel: 2, name: "" },
            { id: Permission.Inventory, accessLevel: 2, name: "" },
        ],
        roleID: Role["Personnel"]
    },
    {
        role: "Restricted Personnel",
        permissions: [
            { id: Permission.Locks, accessLevel: 2, name: "" },
            { id: Permission['Activate Locks'], accessLevel: 2, name: "" },
            { id: Permission.Inventory, accessLevel: 0, name: "" },
            { id: Permission['Tenant Locks'], accessLevel: 1, name: "" },
            { id: Permission.Facilities, accessLevel: 1, name: "" },
            { id: Permission['Transfer Facility'], accessLevel: 0, name: "" },
            { id: Permission.Users, accessLevel: 0, name: "" },
            { id: Permission['Edit Admins'], accessLevel: 0, name: "" },
            { id: Permission.Subdomains, accessLevel: 0, name: "" },
            { id: Permission.ApiSettings, accessLevel: 0, name: "" },
            { id: Permission['Lock Returns'], accessLevel: 2, name: "" },
            { id: Permission.Inventory, accessLevel: 2, name: "" },
        ],
        roleID: Role["Restricted Personnel"]
    },
    {
        role: "Call Center Agent",
        permissions: [
            { id: Permission.Locks, accessLevel: 1, name: "" },
        ],
        roleID: Role["Call Center Agent"]
    },
    {
        role: "Vendor",
        permissions: [
            { id: Permission.Locks, accessLevel: 1, name: "" },
            { id: Permission['Activate Locks'], accessLevel: 0, name: "" },
            { id: Permission.Inventory, accessLevel: 0, name: "" },
            { id: Permission['Tenant Locks'], accessLevel: 0, name: "" },
            { id: Permission.Facilities, accessLevel: 1, name: "" },
            { id: Permission['Transfer Facility'], accessLevel: 0, name: "" },
            { id: Permission.Users, accessLevel: 0, name: "" },
            { id: Permission['Edit Admins'], accessLevel: 0, name: "" },
            { id: Permission.Subdomains, accessLevel: 0, name: "" },
            { id: Permission.ApiSettings, accessLevel: 0, name: "" },
            { id: Permission['Lock Returns'], accessLevel: 0, name: "" },
        ],
        roleID: Role["Vendor"]
    },
]

const DefaultRoles = () => {
    const { isSubscribed } = useLoggedInUser()
    const [expanded, setExpanded] = useState<string | boolean>(isSubscribed() ? "premium" : "free")
    // TODO: also add a signalR listener to update that redux slice
    const { customRoles, pending } = useCustomRoles();
    const navigate = useNavigate()

    const handleChange = (panel: string) => (e: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false)
    }

    const navigateToSettings = (id: number) => {
        navigate(`/Company/RolesSettings/?roleID=${id}`)
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
            <Card>
                <Accordion expanded={!isSubscribed() ? true : expanded === 'free'} onChange={handleChange('free')} sx={{ height: "100%" }}>
                    <Summary
                        expandIcon={<ExpandMoreIcon sx={{ color: Colors.white() }} />}
                    >
                        <PBold style={{ color: Colors.white(), verticalAlign: "center", paddingTop: "8px" }} >{strings.defaultRoles}</PBold>
                    </Summary>
                    <AccordionDetails>
                        <Grid container sx={{ padding: "4px", boxSizing: "border-box" }} xs={12} gap={{ lg: "0px", xs: "0px" }} justifyContent={{ xs: "center", sm: "start" }} >
                            {permissions.map((p: Obj) => {
                                return (
                                    <Grid item lg={2} md={4} sm={6} xs={10} justifyContent="center" alignItems="center" sx={{ p: "24px 8px", boxSizing: "border-box" }} >
                                        <RoleCard>
                                            <CardHeader>
                                                <RoleText >
                                                    {p.role}
                                                </RoleText>
                                            </CardHeader>
                                            <PermissionsBox >
                                                <Box sx={{ width: "100%", mt: "12px", mb: "16px", p: "0 14px" }}>
                                                    <PBold style={{ textAlign: "start" }}>{strings.permissions}:</PBold>
                                                </Box>
                                                <ListBox>
                                                    {p.permissions.map((per: any) =>
                                                        <DisplayPermission permission={per} />
                                                    )}
                                                </ListBox>
                                            </PermissionsBox>
                                            <Box sx={{ margin: "0 auto", width: "80%", marginBottom: "8px" }}>
                                                <PrimaryLink link={`/Users/ClientUserListing?roleID=${p.roleID}`} >{strings.viewUsers}</PrimaryLink>
                                            </Box>
                                        </RoleCard>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Card>
            {isSubscribed() &&
                <Card>
                    <Accordion expanded={expanded === "premium"} onChange={handleChange('premium')} sx={{ height: "100%", }}>
                        <Summary
                            expandIcon={<ExpandMoreIcon sx={{ color: Colors.white() }} />}
                        >
                            <PBold style={{ color: Colors.white(), verticalAlign: "center", paddingTop: "8px" }} >{strings.customRoles}</PBold>
                        </Summary>
                        <AccordionDetails>
                            <Grid container sx={{ padding: "4px", boxSizing: "border-box" }} xs={12} gap={{ lg: "0px", xs: "0px" }} justifyContent={{ md: "center", lg: "start" }} >
                                {!customRoles.length && pending ? strings.loading : null}
                                {customRoles.map((r: CustomRole) => {
                                    return (
                                        <Grid item lg={2} md={4} sm={6} xs={10} justifyContent="center" alignItems="center" sx={{ p: "24px 8px", boxSizing: "border-box" }} >
                                            <RoleCard>
                                                <CardHeaderWithIcon >
                                                    <RoleText>{r.name}</RoleText>
                                                    <span onClick={() => navigateToSettings(r.id)}>
                                                        <FontAwesomeIcon size="lg" icon={faPencil as IconProp} style={{ margin: "2px 8px", color: Colors.white(), cursor: "pointer" }} />
                                                    </span>
                                                </CardHeaderWithIcon>
                                                <PermissionsBox >
                                                    <Box sx={{ width: "100%", mt: "12px", p: "0 14px" }}>
                                                        <PBold style={{ textAlign: "start" }}>{strings.permissions}:</PBold>
                                                    </Box>
                                                    <ListBox>
                                                        {r.permissions.map((per: any) =>
                                                            <DisplayPermission permission={per} />
                                                        )}
                                                    </ListBox>
                                                </PermissionsBox>
                                                <Box sx={{ margin: "0 auto", width: "80%", marginBottom: "8px" }}>
                                                    <PrimaryLink link={`/Users/ClientUserListing?roleID=${r.id}`} >{strings.viewUsers}</PrimaryLink>
                                                </Box>
                                            </RoleCard>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Card>
            }
        </Box>
    );
}

const DisplayPermission = ({ permission }: { permission: IPermission }) => {
    if (!permission.accessLevel) return null;

    if (Permission.isToggle(permission.id)) return (
        <PBold left>- {Permission[permission.id]}</PBold>
    )

    return (
        <PBold left>
            - {permission.accessLevel > 1 ? strings.edit : strings.view} {Permission[permission.id]}
        </PBold>
    )
}

const Summary = styled(AccordionSummary)({
    background: Colors.red(),
    maxHeight: "36px",
    minHeight: "36px !important",
    justifyContent: "center",
    boxSizing: "border-box"
})

const RoleCard = styled(Card)({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px",
    minHeight: "100%",
})
const CardHeader = styled(Box)({
    width: "100%",
    height: "44px",
    background: Colors.red(),
    boxSizing: "border-box",
    paddingLeft: "12px",
    paddingTop: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start"
})

const CardHeaderWithIcon = styled(Box)({
    width: "100%",
    height: "44px",
    background: Colors.red(),
    boxSizing: "border-box",
    paddingLeft: "12px",
    paddingTop: "8px",
    display: "flex",
    justifyContent: "space-between"
})
const RoleText: any = styled(PThin)({
    textAlign: "start",
    color: Colors.white(), padding: "4px 0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    verticalAlign: "center"
})

const PermissionsBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    p: "0 8px",
    alignItems: "flex-start",
    height: "100%",
    flexGrow: 2,
    overflow: "hidden",
})

const ListBox = styled(Box)({
    padding: "0px 24px",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column"
})
export default DefaultRoles;
