import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { FacilityDto } from "../../models/facility";
import agents from '../../api/agent';
import { ActionTypes } from "../auth/ActionTypes";
import { fetchData } from '../fetchData';

interface IUserFacilityState {
    facilities: FacilityDto[],
    status: "awaiting" | "fetched",
    pending?: boolean,
    error?: string,
}

const initialState: IUserFacilityState = {
    facilities: [],
    status: 'awaiting',
}

export const fetchUserFacilitiesAsync = createAsyncThunk(
    "UserFacilities/fetchUserFacilitiesAsync",
    async (_, { rejectWithValue }) =>
        fetchData(rejectWithValue, agents.Facilities.getFacilities)
)

export const fetchCompanyFacilitiesAsync = createAsyncThunk(
    "UserFacilities/fetchCompanyFacilitiesAsync",
    async (companyID: number, { rejectWithValue }) =>
        fetchData(rejectWithValue, agents.Facilities.getFacilities, companyID)
)

export const userFacilitiesSlice = createSlice({
    name: "UserFacilities",
    initialState,
    reducers: {
        setUserFacilities: (state: any, action: PayloadAction<any>) => {
            return { ...state, facilities: [...action.payload.facilities], pending: false }
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                isAnyOf(fetchUserFacilitiesAsync.pending, fetchCompanyFacilitiesAsync.pending),
                (state) => {
                    return { ...state, status: "awaiting", pending: true }
                })
            .addMatcher(
                isAnyOf(fetchUserFacilitiesAsync.fulfilled, fetchCompanyFacilitiesAsync.fulfilled),
                (state, action: PayloadAction<any>) => {
                    return { ...state, status: 'fetched', facilities: [...action.payload], pending: false }
                })
            .addMatcher(
                isAnyOf(fetchUserFacilitiesAsync.rejected, fetchCompanyFacilitiesAsync.rejected),
                (state, action: PayloadAction<any>) => {
                    return { ...state, error: action.payload, pending: false }
                })
            .addMatcher(({ type }: { type: string }) =>
                type === ActionTypes.LOGOUT || type === ActionTypes.RESET_SITE_ROLE,
                (state: any) => ({ ...state, facilities: [], pending: false })
            )
            .addDefaultCase((state) => {
                return state;
            })
    }
})

export const { setUserFacilities } = userFacilitiesSlice.actions;

export const getUserFacilities = (state: RootState) => state.userFacilities;
