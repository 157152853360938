import React from "react";
import { PageTemplate } from "../../core/layout/PageTemplate";
import { strings } from "../../content/strings";
import gears from "../../assets/images/gears.png";
import ForgotPasswordForm from "./components/ForgotPasswordForm";

const ForgotPasswordPage = () => {
    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={strings.signinPageTitle}
            pageDescription={strings.davincilockPageDescription}
            center>
            <ForgotPasswordForm />
        </PageTemplate>
    );
};

export default ForgotPasswordPage;
