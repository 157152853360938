import React from "react";
import gears from "../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import { strings } from "../../content/strings";
import IntegrationsContainer from "./components/IntegrationsContainer"

const IntegrationsPage = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.integrations}
            pageDescription={strings.integrationDescription}
            pageCanonical={"https://www.davincilock.com/Company/Integrations"}
        >
            <IntegrationsContainer />
        </LoggedInPageTemplate >
    )
}

export default IntegrationsPage
