import gears from "../../assets/images/gears.png";
import React from "react";
import { PageTemplate } from "../../core/layout/layouts";
import { strings } from "../../content/strings";
import styled from "styled-components";
import { CreateCompanyFlow } from "./components/CreateCompanyFlow";

export default function CreateCompanyFlowPage() {
    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={strings.createCompanyTitle}
            pageDescription={strings.davincilockPageDescription}
            pageCanonical={strings.signinPageCanonical}
            footer>
            <Section>
                <CreateCompanyFlow />
            </Section>
        </PageTemplate>
    );
};

const Section = styled.div({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    width: `100%`,
})
