import React, { useContext, useEffect, useState, useMemo } from "react";
import agents from "../../../api/agent";
import AutoComplete from "../../../core/form-components/AutoComplete";
import Input from "../../../core/form-components/Input";
import LockStatus, { lockStatuses } from "../../../enums/LockStatus";
import useAnalytics from "../../../hooks/useAnalytics";
import { H4Bold } from "../../../core/brand/typography";
import { PrimaryButton, ToggleButton } from "../../../components/button";
import { IForm, useForm } from "../../../hooks/useForm";
import { ILockModelForm } from "../../../models/ILockModelForm";
import { lockModelValidator } from "../../../validators/validators";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { strings } from "../../../content/strings";
import { EditUnitNumberField } from "./EditUnitNumberField";
import { ProtectedInput } from "./ProtectedInput";
import { LogsTable } from "./LogsTable";
import { TransferLockForm } from "./TransferLockForm";
import { events as analyticsEvents } from "../../../mixPanelEvents";
import { useFacilities } from "../../../hooks/useFacilities";
import { useLocks } from "../../../hooks/useLocks";
import { useCompanyID } from "../../../hooks/useCompanyID";
import { useLockLogs } from "../../../hooks/useLockLogs";

enum Tab {
    Logs,
    TransferLock
}

export const EditLockForm = ({ closeBlade, initialValue }: { initialValue: ILockModelForm, closeBlade: () => void }) => {
    const { trackClickEvent } = useAnalytics("Edit Lock")
    const { displayToast } = useContext(ToastContext)
    const { updateLockFromClient } = useLocks();
    const { companyID } = useCompanyID()
    const [tab, setTab] = useState<Tab>(Tab.Logs)

    const submitAction = async (formValues: ILockModelForm) => {
        trackClickEvent(analyticsEvents.submit)
        try {
            if (LockStatus.isNotAssigned(formValues.secondaryLockStatus!)) {
                formValues.unitNumber = ""
            }
            updateLockFromClient(formValues);

            const result = await agents.Locks.updateLock(formValues);
            if (result.success) {
                /* TODO: Remove redundency in fetching locks and signalR. (notify all except sender? Or just rely on SignalR?)
                    we guess update is successful to show change immediately on the table,
                    we then fetch the update
                    we also get notified by SignalR that an update occurred and fetch it again.
                */
                displayToast({ type: ToastType.Success })
                closeBlade();
            } else {
                updateLockFromClient(initialValue);
                displayToast({ type: ToastType.Error, text: result.error || strings.somethingWentWrong })
            }
        } catch (err: any) {
            updateLockFromClient(initialValue);
            displayToast({ type: ToastType.Error, text: err.message })
        }
    }

    const handleSetTab = (selection: Tab) => {
        trackClickEvent(`Show ${Tab[selection]}`)
        setTab(selection)
    }

    const form = useForm<ILockModelForm>(
        initialValue, submitAction, lockModelValidator
    );

    /** Log data and fetching of logs */
    const [logs, fetchLogs] = useLockLogs(initialValue.lockID, companyID)

    useEffect(() => {
        const logUser = async () => {
            if (initialValue.secondaryLockStatus == LockStatus["Tenant Using Lock"]) {
                await agents.Locks.logRevealLockCombination(initialValue);

                fetchLogs()
            }
        }
        logUser();
    }, [])

    return (
        <>
            <H4Bold>{strings.serialCode} {form.inputProps.serialCode!.value}</H4Bold>

            <EditLockFieldsAndSubmit
                form={form}
                initialValue={initialValue}
            />

            <div style={{ border: "1px solid black", margin: "16px 0" }} />
            <span style={{ display: "flex", justifyContent: "space-evenly", gap: "16px" }}>
                <ToggleButton selected={tab == Tab.Logs} fullWidth onClick={() => handleSetTab(Tab.Logs)}>{strings.viewLogs}</ToggleButton>
                <ToggleButton selected={tab == Tab.TransferLock} fullWidth onClick={() => handleSetTab(Tab.TransferLock)}>{strings.transferLock}</ToggleButton>
            </span>
            <div style={{ borderTop: "1px solid black", margin: "16px 0" }} />
            {tab === Tab.Logs ?
                <LogsTable
                    logs={logs}
                    hideMobileCard
                /> :
                <TransferLockForm
                    closeBlade={closeBlade}
                    {...form.values}
                    lockStatus={initialValue.secondaryLockStatus!}
                />
            }
        </>
    )
}

const EditLockFieldsAndSubmit = ({ form, initialValue }: { form: IForm<ILockModelForm>, initialValue: ILockModelForm }) => {
    const { trackClickEvent } = useAnalytics("Edit Lock")
    const { facilities } = useFacilities()
    const { companyID } = useCompanyID()
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [allowMultipleLocks, setMultipleLocksToggle] = useState(true);

    const secondaryLockStatusProps = form.inputProps.secondaryLockStatus!

    const { getFacilityLocks } = useLocks();

    if (companyID) {
        return null
    }

    const facilityIDProps = form.inputProps.facilityID

    const facilityLocks = useMemo(() => getFacilityLocks(facilityIDProps?.value as number), [facilityIDProps?.value])

    const facility = facilities.find(f => f.facilityID === facilityIDProps?.value)

    useEffect(() => {
        if (!allowMultipleLocks && LockStatus.isAssigned(form.values?.secondaryLockStatus!)) {
            const otherLocks = facilityLocks.filter(l => l.unitNumber === form.values.unitNumber && l.serialCode !== form.values.serialCode);
            setDisableSubmit(otherLocks.length > 0);
            // iterate through all locks for this facility and check to see if any of them already
            // belong to this unit...
        } else {
            setDisableSubmit(false)
        }
    }, [form.values.unitNumber, allowMultipleLocks, form.values.secondaryLockStatus])

    useEffect(() => {
        const getAllowMultipleLocks = async () => {
            const result = await agents.Settings.getSettingsProfile();
            setMultipleLocksToggle((result.data?.numberOfLocks || 4) > 1);
        }
        getAllowMultipleLocks()
    }, [])

    return (
        <>
            <AutoComplete
                value={LockStatus[secondaryLockStatusProps.value as keyof typeof LockStatus]}
                setValue={(val: keyof typeof LockStatus) => {
                    secondaryLockStatusProps.setValue(LockStatus[val] as number)
                }}
                noClear
                options={lockStatuses as string[]}
                label={strings.status}
            />

            <div style={{ margin: "25px 0 0 0" }}>
                <ProtectedInput
                    trackClickEvent={trackClickEvent}
                    {...form.inputProps.unlockCode}
                    required
                    label={strings.unlockCode}
                    available={initialValue.secondaryLockStatus === LockStatus["Available"]}
                />
            </div>

            <EditUnitNumberField
                fieldProps={form.inputProps.unitNumber!}
                secondaryLockStatus={secondaryLockStatusProps.value as number}
                facilityID={initialValue.facilityID!}
                trackClickEvent={trackClickEvent}
                disableSubmit={disableSubmit}
            />

            <Input disabled value={facility?.facilityName} label={strings.facilityName} />
            <span style={{ display: "flex", justifyContent: "space-evenly", gap: "16px" }}>
                <div style={{ width: "100%", margin: "8px" }} />
                <PrimaryButton fullWidth disabled={disableSubmit} onClick={form.handleSubmit}>{strings.submit}</PrimaryButton>
            </span>
            <div style={{ border: "1px solid black", margin: "16px 0" }} />
        </>
    )
}
