import { Box, Grid, } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../../core/brand/themes';
import { P, PBold } from '../../../core/brand/typography';
import Input from '../../../core/form-components/Input';
import { useForm } from '../../../hooks/useForm';
import { PrimaryButton, QuaternaryButton } from '../../../components/button';
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import agents from "../../../api/agent";
import { roleSettingsValidator } from "../../../validators/validators"
import { useSearchParam } from '../../../hooks/useSearchParam';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";
import PermissionStepper from "./PermissionStepper"
import { Card } from '../../../core/surfaces/Card';
import useAnalytics from '../../../hooks/useAnalytics';
import { events as analyticsEvents } from "../../../mixPanelEvents";
import Permission from '../../../enums/Permission';
import { strings } from '../../../content/strings';
import { useNavigate } from 'react-router';
import { useCustomRoles } from '../../../hooks/useCustomRoles';
import { debugLog } from '../../../utils/debugLog';

/**
 * This method builds an object with shape:
 * {
 *    [permissionID]: {id: permissionID, name: permissionName, accessLevel: 0 | 1 | 2}
 * }
 * Dictionaries/Hashmaps are easier to work with.
 */
const getInitialValues = () => {
    return Object.values(Permission)
        .filter(p => typeof p === "number")
        .reduce((acc: any, p: any) => ({
            ...acc,
            [p]: {
                accessLevel: 0,
                id: p,
                name: Permission[p]
            }
        }), {})
}

const AddEditSettings = () => {
    const [roleID] = useSearchParam("roleID");
    const { customRoles, fetchCustomRoles } = useCustomRoles()
    const selectedRole = customRoles.find((r: any) => r.id == roleID)
    const { displayToast } = useContext(ToastContext);
    const { trackClickEvent } = useAnalytics(selectedRole?.id ? "Edit Role" : "Add Role")
    const navigate = useNavigate()

    const [permissionsValues, setPermissionsValues] = useState(getInitialValues())
    const initialValues = {
        name: "",
    }

    const submitAction = async (formValues: any) => {
        await trackClickEvent(analyticsEvents.submit);

        try {
            const result = await agents.Settings.addUpdateCustomRole({
                ...formValues,
                id: roleID,
                permissions: Object.values(permissionsValues)
            })

            if (result.success) {
                fetchCustomRoles()
                displayToast({ type: ToastType.Success })
                navigate("/Company/RolesAndPermissions")
            } else {
                displayToast({ type: ToastType.Error, text: result.error || "" })
            }

        } catch (e: any) {
            debugLog({ e })
        }
    }

    const form = useForm(
        initialValues, submitAction, roleSettingsValidator
    )

    useEffect(() => {
        if (selectedRole) {
            setPermissionsValues({
                ...getInitialValues(),
                ...selectedRole.permissions.reduce((acc, { id, accessLevel }) => ({
                    ...acc,
                    [id]: {
                        id,
                        accessLevel,
                        name: Permission[id]
                    }
                }), {})
            })
            form.inputProps.name.setValue(selectedRole?.name)
        }
    }, [selectedRole])

    /**
     * @param id - permissions ID
     * @param value - accessLevel of 0 | 1 | 2
     */
    const updatePermission = (id: number, value: number) => {
        setPermissionsValues((prev: any) => ({
            ...prev,
            [id]: {
                ...prev[id],
                accessLevel: value,
            }
        }))
    }

    const title = selectedRole ? strings.createRole(0) : strings.createRole(1);

    return (
        <Grid container gap="32px">
            <Grid item xs={12}>
                <HeaderBox>
                    <Title>{title}</Title>
                </HeaderBox>
            </Grid>
            <Grid item container xs={12} justifyContent="center" >
                <Grid item sm={4} md={3} xs={9}>
                    <Input label={strings.roleDisplayName} {...form.inputProps.name} />
                </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent={{ xs: "center", sm: "start" }} gap={{ xs: "4px", sm: "8px" }} >
                {Object.values(permissionsValues).map((per: any, idx: number) => {
                    return (
                        <Grid item container lg={3.5} md={5.5} xs={12} justifyContent="space-between" sx={{ ml: "24px" }}  >
                            <Grid item container xs={12} alignItems=" start" >
                                <Grid item xs={6} sx={{ padding: "18px" }}>
                                    <PermissionName>{per.name}</PermissionName>
                                </Grid>
                                <Grid item xs={6} sx={{ padding: "12px" }}>
                                    <PermissionStepper setValue={updatePermission} value={per.accessLevel} id={per.id} />
                                </Grid>

                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid item container xs={12} justifyContent="space-around" sx={{ mb: "40px" }} gap={{ lg: "22px", xs: "0px" }} >
                <Grid item xs={8} lg={3} md={4}>
                    <PrimaryButton fullWidth>
                        <FontAwesomeIcon size="lg" icon={faTrashCan as IconProp} style={{ margin: "2px 8px" }} />
                        {strings.deleteCustomRole}
                    </PrimaryButton>
                </Grid>

                <Grid item xs={8} lg={3} md={4}>
                    <QuaternaryButton onClick={() => navigate("/Company/RolesAndPermissions")} fullWidth>
                        <FontAwesomeIcon size="lg" icon={faXmark as IconProp} style={{ margin: "2px 8px" }} />
                        {strings.cancel.toUpperCase()}
                    </QuaternaryButton>
                </Grid>

                <Grid item lg={3} xs={8} md={4} onClick={form.handleSubmit}>
                    <PrimaryButton fullWidth >
                        <FontAwesomeIcon size="lg" icon={faCheck as IconProp} style={{ margin: "2px 8px" }} />
                        {strings.saveChanges}
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

const HeaderBox = styled(Box)({
    width: "100%", minHeight: "36px",
    background: Colors.red(),
    padding: "4px 0",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center"
})

const Title = styled(PBold)({
    textAlign: "start",
    color: Colors.white(),
    margin: "0px 20px"
})

const PermissionName = styled(PBold)({
    textAlign: "start",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    // marginBottom: "40px"
})

export default AddEditSettings;
