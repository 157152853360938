import { FacilityDto } from "../models/facility"
import { debugLog } from "./debugLog";

const extractAdrField = (adrAddress: string, adrComponent: string) => {
    const componentRegex = new RegExp('<[^>]+class="' + adrComponent + '"[^>]*>[^<>]*<\/span>');

    if (adrAddress === undefined || adrAddress === null) {
        return null;
    }

    const match = adrAddress.match(componentRegex);

    if (!match) {
        return null;
    }

    const matchingSpan = match[0].toString();

    const addressField = matchingSpan.replace(/<[^>]+>/g, '');

    return addressField;
}

export const getGoogleAddress = async (facility: FacilityDto) => {
    if (facility.street && facility.city && facility.state && facility.zip) {
        try {
            const address = facility.street + " " + facility.city + ", " + facility.state + " " + facility.zip || "";

            var geocoder = new google.maps.Geocoder();
            const predicter = new google.maps.places.AutocompleteService();

            var predicterResult = await predicter.getPlacePredictions({
                'input': address
            })

            const tasks = predicterResult.predictions.map((prediction) => {
                return geocoder
                    .geocode(
                        { 'address': prediction.description });
            })

            const addresses = (await Promise.all(tasks)).reduce(
                (acc: any[], { results }: any) => {
                    const place = results[0];

                    const googleAddress = extractGoogleAddress(place)

                    if (!googleAddress || googleAddress?.zip !== facility.zip) {
                        return acc;
                    }

                    if (acc.some(cv => cv.lat === googleAddress.lat && cv.lng === googleAddress.lng)) {
                        return acc;
                    }

                    return [...acc, googleAddress];
                }, []
            )

            return addresses
        } catch (err) {
            debugLog({ err })
            return [];
        }
    }
    return []
};

const capitalize = (str: string) => {
    return str[0].toUpperCase() + str.slice(1)
}

export const extractGoogleAddress = (place: google.maps.GeocoderResult | google.maps.places.PlaceResult) => {
    if (!place.address_components) {
        return null;
    }

    const location = place.geometry?.location;
    const lat = location?.lat()
    const lng = location?.lng()

    //@ts-ignore
    var adrAddress: string = place.adr_address;

    if (adrAddress) {
        const street = extractAdrField(adrAddress, 'street-address');
        const city = extractAdrField(adrAddress, 'locality');
        const state = extractAdrField(adrAddress, 'region');
        const zip = extractAdrField(adrAddress, 'postal-code');

        if (street && city && state && zip) {
            return {
                street,
                city,
                state,
                zip,
                lat,
                lng,
            }
        }
    }

    const subAddress = place.address_components.find((component: any) => component.types.includes('subpremise'))?.long_name;
    const streetNumber = place.address_components.find((component: any) => component.types.includes('street_number'))?.long_name;
    const streetName = place.address_components.find((component: any) => component.types.includes('route'))?.short_name;
    const city = place.address_components.find((component: any) => component.types.some((t: string) => ['locality', 'sublocality'].includes(t)))?.long_name;
    const state = place.address_components.find((component: any) => component.types.includes('administrative_area_level_1'))?.short_name;
    const zip = place.address_components.find((component: any) => component.types.includes('postal_code'))?.long_name;

    const street = subAddress ?
        `${streetNumber} ${streetName} ${capitalize(subAddress)}` :
        `${streetNumber} ${streetName}`;

    if (!streetNumber || !streetName) {
        return null;
    }

    return {
        street,
        city,
        state,
        zip,
        lat,
        lng,
    }
}
