import React, { useCallback, useEffect } from "react";
import { strings } from "../../content/strings";
import Role, { siteLevelRoles } from "../../enums/Role";
import { useIsSiteLevelPage } from "../../hooks/useIsSiteLevelPage";
import AutoComplete from "./AutoComplete";
import { SelectAutoCompleteProps } from "./SelectFacility";
import { useSearchParam } from "../../hooks/useSearchParam";
import { useCustomRoles } from "../../hooks/useCustomRoles";

export const SelectUserRole = ({ value, setValue, ...props }: SelectAutoCompleteProps) => {
    const [roleID, setRoleIDParam] = useSearchParam("roleID")
    const { customRoles, customRolesMap } = useCustomRoles();

    const isSiteLevelPage = useIsSiteLevelPage();

    const handleSelectRole = useCallback((name: keyof typeof Role) => {
        const roleID = (customRolesMap[name] || Role[name]) as number
        setRoleIDParam(roleID)
        setValue(roleID);
    }, []);

    useEffect(() => {
        if (roleID) {
            setValue(Number(roleID))
        }
    }, [])

    return (
        <AutoComplete
            {...props}
            value={Role[value] || ""}
            setValue={handleSelectRole}
            options={isSiteLevelPage ? siteLevelRoles : customRoles.map(role => role.name)}
            label={strings.selectUserRoleLabel}
            analyticsTag="User Role Select" />
    );
};
