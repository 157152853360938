import React, { useLayoutEffect } from "react";
import { FormFlowRenderProps } from "../../../hooks/useFormFlow2";
import { IFacilityModelForm } from "../../../models/IFacilityForm";
import { PrimaryButton } from "../../../components/button";
import { IForm } from "../../../hooks/hooks";
import { strings } from "../../../content/strings";
import Input, { PhoneInput } from "../../../core/form-components/Input";
import { events as analyticsEvents } from "../../../mixPanelEvents"

export const FacilityDetailsStep = {
    Render: ({ form, next, analytics }: FormFlowRenderProps<IFacilityModelForm>) => {
        const handleNext = () => {
            next(form);
            analytics(analyticsEvents.nextStep)
        }

        useLayoutEffect(() => {
            document.getElementById("FacilityDetailsStepFirstInput")?.focus()
        }, [])
        return (
            <>
                <Input id="FacilityDetailsStepFirstInput" {...form.inputProps.facilityName} label={strings.facilityName} />
                <Input {...form.inputProps.facilityDisplayName} label={strings.publicName} />
                <Input {...form.inputProps.companyDisplayName} label={strings.companyPublicName} />
                <Input {...form.inputProps.facilityURL} label={strings.facilityUrl} />
                <Input {...form.inputProps.email} label={strings.email} />
                <PhoneInput {...form.inputProps.phoneNumber} label={strings.phoneNumber} />
                <PhoneInput {...form.inputProps.phoneNumberCustomerService} label={strings.customerServicePhone} />
                <Input {...form.inputProps.no_Of_Units} label={strings.noOfUnits} />
                <PrimaryButton onClick={handleNext} fullWidth>{strings.continue}</PrimaryButton>
            </>

        )
    },
    validate: (form: IForm<IFacilityModelForm>) => {
        return form.validateFields("facilityName", "facilityDisplayName", "companyDisplayName", "facilityURL", "email",
            "phoneNumber", "phoneNumberCustomerService", "no_Of_Units")
    },
}
