import React, { useContext, useEffect, useState } from "react";
import agents from "../../../api/agent";
import Input from "../../../core/form-components/Input";
import styled from "styled-components";
import { activateLockValidator } from "../../../validators/validators"
import { Colors, Styles } from "../../../core/brand/themes";
import { FacilityDto } from "../../../models/facility";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "../../../core/form-components/Form";
import { IActivateLock } from "../../../models/IActivateLock";
import { PBold } from "../../../core/brand/typography";
import { PrimaryButton } from "../../../components/button";
import { strings } from "../../../content/strings";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { useForm } from "../../../hooks/useForm";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import {
    ListItemText,
    ListItem,
    List,
    Divider,
} from "@mui/material";
import { useFacilities } from "../../../hooks/useFacilities";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { SelectFacility } from "../../../core/form-components/SelectFacility";

export default function ActivateSerialCodeContainer() {
    const { facilities } = useFacilities();
    const { displayToast } = useContext(ToastContext)
    const [selectedFacility, setSelectedFacility] = useState<FacilityDto | null>(null)
    const [activated, setActivated] = useState<string[]>([]);
    const [mobile] = useMediaQuery(768);

    useEffect(() => {
        if (!selectedFacility && facilities.length) {
            setSelectedFacility(facilities[0])
        }
    }, [facilities])

    const submitAction = async (formValues: IActivateLock) => {
        const result = await agents.Locks.activateLock(formValues);
        if (result.success) {
            displayToast({ type: ToastType.Success })
            setActivated(prevState => [...prevState, formValues.serialCode]);
            serialCodeProps.setValue("");
        } else {
            displayToast({ type: ToastType.Error, text: result.error || "" })
        }
    }

    const form = useForm<IActivateLock>(
        { serialCode: "", facilityID: 0 }, submitAction, activateLockValidator
    );

    const serialCodeProps = form.inputProps.serialCode;

    useEffect(() => {
        form.inputProps.facilityID.setValue(selectedFacility?.facilityID || 0);
    }, [selectedFacility])

    return (
        <Container>
            <HeaderText>
                <HeaderParagraph >{strings.activateLocks}</HeaderParagraph>
                <OrderedList>
                    <OrderedListItem>{strings.selectFacility}</OrderedListItem>
                    <OrderedListItem>{strings.enterCode}</OrderedListItem>
                    <OrderedListItem> {strings.tapButton}</OrderedListItem>
                </OrderedList>

                <FormCard>
                    <span style={{ width: "100%" }}>
                        <SelectFacility
                            setValue={setSelectedFacility}
                            facilityID={selectedFacility?.facilityID}
                            geolocate
                            noClear
                        />
                    </span>

                    <Input {...form.inputProps.serialCode} label={strings.serialCode} />

                    <PrimaryButton clickContext="Activate Lock" fullWidth onClick={form.handleSubmit}>{strings.activate}</PrimaryButton>
                </FormCard>
            </HeaderText>
            {
                mobile
                    ? <Divider orientation="horizontal" sx={{ borderBottom: `solid 2px ${Colors.red()}`, height: "1px" }} />
                    : <Divider orientation="vertical" sx={{ borderRight: `solid 2px ${Colors.red()}`, marginRight: "12px" }} />
            }
            {activated.length > 0 &&
                <MuiList sx={{ m: "0 auto" }}>
                    {
                        activated.map((value) => {
                            return (
                                <MuiListItem
                                    key={value}
                                    secondaryAction={<FontAwesomeIcon color={Colors.red()} icon={faLock as IconProp}></FontAwesomeIcon>}
                                >
                                    <ListItemText primary={value} />
                                </MuiListItem>
                            )
                        })
                    }
                </MuiList>
            }
        </Container>
    );
};

const HeaderText = styled.div({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "8px",
})

const Container = styled.div({
    ...Styles.shadow(),
    width: "100%",
    padding: "8px",
    boxSizing: "border-box",
    height: "70vh",
    marginTop: "40px",
    backgroundColor: Colors.white(),
    display: "grid",
    gridTemplateColumns: "1fr 0.2fr 1fr",
    "@media (max-width:768px)": {
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 0.1fr 1fr",
    },
})

const FormCard = styled(Form)({
    display: "flex",
    input: {
        fontSize: "1.2rem",
    },
    flexDirection: "column",
    padding: "16px",
    boxSizing: "border-box",
    width: "50%",
    alignItems: "flex-start",
    "@media (max-width:768px)": {
        width: "100%",
    },
});

const OrderedList = styled.ol({
    width: "100%",
    padding: "0 16px 0 32px",
    margin: "0"
});

const OrderedListItem = styled.li({
    padding: "8px",
    textAlign: "left",
})

const HeaderParagraph = styled(PBold)({
    width: "100%"
})

const MuiListItem = styled(ListItem)({
    background: Colors.lightergrey(),
    marginBottom: "4px",
    borderRadius: "3px",
})

const MuiList = styled(List)({
    width: "100%",
    maxWidth: 360,
    position: "relative",
    overflow: "auto",
    maxHeight: 400,
    "& ul": { padding: 0 },
    "::-webkit-scrollbar": {
        width: "12px",
    },
    "::-webkit-scrollbar-track": {
        background: Colors.white(),
    },
    "::-webkit-scrollbar-thumb": {
        background: Colors.lightergrey(),
        borderRadius: "10px",
        border: "solid 3px white"
    },
    "@media (max-width:768px)": {
        maxWidth: "100%",
    },
});
