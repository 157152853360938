import React, { useContext } from "react";
import { strings } from "../../../content/strings";
import { Display, H1, PBold } from "../../../core/brand/typography";
import { Card } from "../../../core/surfaces/Card";
import { Divider } from "@mui/material";
import { Colors } from "../../../core/brand/themes";
import useFormFlow, { Step } from "../../../hooks/useFormFlow";
import { ICreateCompanyForm } from "../../../models/models";
import agents from "../../../api/agent";
import { createCompanyValidator } from "../../../validators/validators";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import styled from "styled-components";
import { PrimaryButton, QuaternaryButton } from "../../../components/button";
import useCreateCompanySteps from "./useCreateCompanySteps";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { useFetchLoggedInUser } from "../../../hooks/useFetchLoggedInUser";
import { RenderInputFields } from "./RenderInputFields";
import { IForm } from "../../../hooks/useForm";

export type StepExtension = { title: string; subtext: string; };

export const CreateCompanyFlow = () => {
    const { fetchLoggedInUser } = useFetchLoggedInUser()
    const { displayToast } = useContext(ToastContext)
    const steps = useCreateCompanySteps();

    const submitAction = async (formValues: ICreateCompanyForm) => {
        const result = await agents.Account.createCompany({
            ...formValues,
            externalFacilityId: formValues.externalFacilityId || null,
            corporateUsername: formValues.corporateUsername || null,
            corporatePassword: formValues.corporatePassword || null,
            baseUrl: formValues.baseUrl || null,
            corporateCode: formValues.corporateCode || null,
            locationCode: formValues.locationCode || null
        });

        if (result.success) {
            displayToast({ type: ToastType.Success })

            // Since this is a route that you can only get to if you're not logged in,
            // then fetching a user will automatically reroute to the dashboard.
            fetchLoggedInUser()
            cacheData.clearCache()
        } else {
            displayToast({ type: ToastType.Error, text: result.error })
        }
    };

    const { currentStep, form, next, back, activeStep, cacheData, validationFailed } = useFormFlow<ICreateCompanyForm, StepExtension>(
        initialValues(),
        submitAction,
        createCompanyValidator,
        steps,
        { cacheKey: "create_company" }
    );

    if (!cacheData.loaded) {
        return null;
    }

    const renderStep = () => {
        if (cacheData.startFromCache) {
            return promptStartFromCache(cacheData);
        }

        if (currentStep.render) {
            return renderCustomStep(currentStep, form, next, back, validationFailed)
        }

        return renderCurrentStep(next, currentStep, steps, form, activeStep);
    }

    return (
        <>
            <Display>{strings.accountCreation}</Display>
            <Divider sx={{ width: "calc(400px - 48px)", border: `1px solid ${Colors.red()}`, mb: 6 }} />

            <Card style={{
                width: "400px", textAlign: "left", padding: "16px 24px",
                marginBottom: "64px",
                boxSizing: "border-box",
                maxWidth: "calc(100vw - 32px)"
            }}>
                <div style={{ display: "flex" }}>
                    {activeStep > 0 &&
                        <KeyboardBackspaceIcon
                            color="primary"
                            fontSize="large"
                            onClick={back}
                            sx={{
                                mr: "10px",
                                cursor: "pointer"
                            }} />}
                    <H1 left>{currentStep.title}</H1>
                </div>
                <Divider sx={{ border: `1px solid ${Colors.red()}`, mb: 2 }} />
                {renderStep()}
            </Card>
        </>
    )
};

const initialValues = (): ICreateCompanyForm => ({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    companyUrl: "",
    phoneNumber: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    facilityName: "",
    facilityBrandName: "",
    facilityStreet: "",
    facilityCity: "",
    facilityState: "",
    facilityZip: "",
    facilityUrl: "",
    managementSoftware: 0,
    corporateCode: null,
    locationCode: null,
    corporateUsername: null,
    corporatePassword: null,
    baseUrl: null,
    externalFacilityId: null,
    password: "",
    confirmPassword: "",
    acceptedTerms: false,
    usesFms: null,
})

const StartOverButton = styled(QuaternaryButton)({
    height: "32px",
    fontSize: "1rem",
    marginTop: "8px"
})

const ContinueButton = styled(PrimaryButton)({
    height: "32px",
    fontSize: "1rem",
    marginTop: "8px"
})

function renderCurrentStep(
    next: (...args: any[]) => void,
    currentStep: Step<ICreateCompanyForm, StepExtension>,
    steps: any,
    form: IForm<ICreateCompanyForm>,
    activeStep: number
) {
    const handleNext = async () => {
        next(currentStep.validate);
    };
    return (
        <>
            <PBold left>{currentStep.subtext}</PBold>

            <form onSubmit={steps.length - 1 ? form.handleSubmit : next}>
                <RenderInputFields
                    currentStep={currentStep}
                    form={form} />
                {activeStep === steps.length - 1 ?
                    <ContinueButton onClick={form.handleSubmit} fullWidth>{strings.submit}</ContinueButton> :
                    <ContinueButton onClick={handleNext} fullWidth>{strings.continue}</ContinueButton>}
            </form>
        </>
    );
}

function renderCustomStep(
    currentStep: Step<ICreateCompanyForm, StepExtension>,
    form: IForm<ICreateCompanyForm>,
    next: (...args: any[]) => void,
    back: () => void,
    validationFailed: boolean
) {
    return (
        <>
            <PBold left>{currentStep.subtext}</PBold>
            {/**@ts-ignore */}
            <currentStep.render
                form={form}
                next={next}
                back={back}
                validationFailed={validationFailed} />
        </>
    );
}

function promptStartFromCache(
    cacheData: {
        startFromCache: (() => void) | null;
        clearCache: () => void;
        loaded: boolean;
    }) {
    const startOver = () => {
        cacheData.clearCache();
    };

    const startFromCache = () => {
        cacheData.startFromCache && cacheData.startFromCache();
    };

    return (
        <>
            <PBold left>
                {strings.continueAccountCreation}
            </PBold>

            <div style={{ display: "flex", gap: "16px" }}>
                <StartOverButton onClick={startOver} fullWidth>{strings.startOver}</StartOverButton>
                <ContinueButton onClick={startFromCache} fullWidth>{strings.continue}</ContinueButton>
            </div>
        </>
    );
}
