import { useEffect, useState, ChangeEvent, useRef } from "react"
import Input from "./Input";
import styled from "styled-components";
import { Form } from "./Form";
import { FormObject } from "../../hooks/useForm";
import { PrimaryButton } from "../../components/button";
import { useDebounce } from "../../hooks/useDebounce";
import React from "react";
import { SelectFacility } from "./SelectFacility";
import { SelectUserRole } from "./SelectUserRole";
import useAnalytics from "../../hooks/useAnalytics";

interface SearchFieldProps<T> {
    searchKeys?: (keyof T)[],
    data: T[],
    setFilterData: (data: T[]) => void,
    searchLabel?: string,
    useFacilityAutoComplete?: boolean,
    useUserRoleAutoComplete?: boolean,
    noClear?: boolean,
    clearOnNewValues?: boolean,
    fullWidth?: boolean,
    endAdornment?: any,
    geolocate?: boolean
}

/**
 * @summary creates a text input whose value is matched defined prop values to filter a collection.
 * @param data - data collection to be filtered.
 * @param searchKeys - data property names whose values will be compared to the search value.
 * @param searchLabel - label text for the search input.
 * @param setFilterData - updates new state with filtered results (do not update the data state itself).
 * @param useFacilityAutoComplete - adds an autocomplete field to filter by facility
 */
export default function SearchField<T>({
    data,
    searchKeys,
    searchLabel,
    setFilterData,
    useFacilityAutoComplete,
    useUserRoleAutoComplete,
    noClear,
    clearOnNewValues,
    fullWidth,
    endAdornment,
    geolocate
}: SearchFieldProps<T>): JSX.Element {
    const [searchValue, setSearchValue] = useState<string>("");
    const debouncedSearchValue = useDebounce<string>(searchValue, 300);
    const [facilityIDFilter, setFacilityIDFilter] = useState<string | number>(0);
    const [userRoleIDFilter, setUserRoleIDFilter] = useState<string | number>(0);
    const { trackClickEvent } = useAnalytics("SearchField", false)
    const trackedRef = useRef(false)

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchValue(value.toUpperCase());
    };

    const updateFilteredData = () => {
        if (facilityIDFilter || debouncedSearchValue || userRoleIDFilter) {
            const updatedData = data.filter((item: any) => {
                if (useFacilityAutoComplete) {
                    if (facilityIDFilter && (item?.facilityID != facilityIDFilter)) {
                        return false
                    }
                }
                if (userRoleIDFilter && (item?.roleID != userRoleIDFilter)) {
                    return false
                }
                return searchKeys?.some(key => (item[key] as string)?.toUpperCase().includes(debouncedSearchValue.toUpperCase()))

            })
            setFilterData(updatedData);
        }
        else {
            setFilterData(data);
        }
    }

    useEffect(() => {
        updateFilteredData();
        if (debouncedSearchValue && !trackedRef.current) {
            trackedRef.current = true
            trackClickEvent("Update Filter")
        }
    }, [debouncedSearchValue, facilityIDFilter, userRoleIDFilter]);

    useEffect(() => {
        if (clearOnNewValues) {
            setSearchValue("")
        } else {
            updateFilteredData()
        }
    }, [data])

    const handleFacilitySelect = (item: FormObject) => {
        setFacilityIDFilter(item?.facilityID as number || 0)
    }

    const resetFilter = () => setFacilityIDFilter("")

    return (
        <SearchFieldWrapper>
            {searchKeys &&
                <SearchForm as="div" fullWidth={fullWidth}>
                    <Input
                        name="search"
                        autoComplete="off"
                        label={searchLabel || "Search"}
                        clear={noClear ? null : () => setSearchValue("")}
                        value={searchValue} onChange={handleSearchChange} />
                </SearchForm>
            }

            {useFacilityAutoComplete && (
                <SelectFacility
                    setValue={handleFacilitySelect}
                    handleClear={resetFilter}
                    geolocate={geolocate}
                    facilityID={Number(facilityIDFilter || 0)}
                />
            )}

            {useUserRoleAutoComplete && (
                <SelectUserRole
                    setValue={setUserRoleIDFilter}
                    value={userRoleIDFilter}
                    handleClear={resetFilter}
                />
            )}
        </SearchFieldWrapper>
    )
}

const SearchFieldWrapper = styled.span({
    display: "flex",
    gap: "16px",
    "@media (max-width: 414px)": {
        flexDirection: "column",
        gap: "4px",
        padding: "0px 12px"
    },
})

type SearchInputProps = {
    fullWidth?: boolean,
    searchLabel?: string,
    searchValue?: string,
    handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void,
    setSearchValue: (val: string) => void,
    noClear?: boolean
}

export const SearchInput = ({
    fullWidth,
    searchLabel,
    searchValue,
    handleSearchChange,
    setSearchValue,
    noClear
}: SearchInputProps) => (
    <SearchForm fullWidth={fullWidth}>
        <Input
            name="search"
            autoComplete="off"
            label={searchLabel || "Search"} value={searchValue} onChange={handleSearchChange} />
        {!noClear && <SearchButton onClick={() => setSearchValue("")}>Clear</SearchButton>}
    </SearchForm>
)

const SearchForm = styled(Form)<{ fullWidth?: boolean }>(({ fullWidth }) => ({
    padding: 0,
    width: fullWidth ? "100%" : "250px",
    position: "static",
    flexDirection: "row",
}))

const SearchButton = styled(PrimaryButton)({
    marginBottom: "20px"
});
