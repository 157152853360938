import Input from "../../core/form-components/Input";
import styled from "styled-components";
import { accountValidator } from "../../validators/validators"
import { Card } from "../../core/surfaces/Card";
import { Form } from "../../core/form-components/Form";
import { H2, FormErrorText } from "../../core/brand/typography";
import { ILoginForm } from "../../models/models";
import { PrimaryButton, SubLink } from "../../components/button";
import { strings } from "../../content/strings";
import { useForm } from "../../hooks/useForm";
import links from "../../routes/links"
import { useLogin} from "../../hooks/useLogin";
import OktaLoginDialog from "./OktaLoginDialog";

/** find out how mixpanel allows tracking users before they log in. */
const LoginForm = ({ email }: { email?: string }) => {
    const { login, errorMessage, pending, redirectToOktaLogin, setRedirectToOktaLogin } = useLogin()
    const initialValues: ILoginForm = { email: email || "", password: "" }

    const form = useForm<ILoginForm>(
        initialValues, login, accountValidator
    );

    return (
        <LoginCard>
            {OktaLoginDialog({redirectToOktaLogin, setRedirectToOktaLogin, login:true})}
            <Form>
                <TitleContainer>
                    <H2>{strings.login}</H2>
                </TitleContainer>
                <Input {...form.inputProps.email} disabled={!!email} required label={strings.email} />
                <Input {...form.inputProps.password} required type="password" label={strings.password} />
                <PrimaryButton
                    pending={pending}
                    fullWidth
                    clickContext="Login"
                    onClick={form.handleSubmit}>
                    {strings.login}
                </PrimaryButton>
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <FormSubLink {...links.FORGOTPASSWORD}>{strings.forgotPassword}</FormSubLink>
                <FormSubLink {...links.CREATECOMPANY}>{strings.createAccountLink}</FormSubLink>
            </Form>
        </LoginCard>
    );
};

const LoginCard = styled(Card)({ width: "360px", position: "relative" })
const ErrorMessage = styled(FormErrorText)({
    width: "100%",
    textAlign: "right",
    display: "block",
    height: "16px",
    margin: "8px 0 0 0",
})

const TitleContainer = styled.div({
    display: "grid",
    rowGap: "8px",
    marginBottom: "24px",
    textAlign: "center"
})

const FormSubLink = styled(SubLink)({
    width: "100%",
    textAlign: "right",
    display: "block",
    height: "13px",
    margin: "16px 0 0 0",
})

export default LoginForm;
