import {
    faArrowRight,
    faBarcode,
    faBell,
    faCalculator,
    faCog,
    faCogs,
    faCubes,
    faFileAlt,
    faFolderTree,
    faGift,
    faInfo,
    faLock,
    faLockOpen,
    faPuzzlePiece,
    faTachometerAlt,
    faUniversity,
    faUserCog,
    faUsers,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons"
import { getToken } from "../api/agent"
import { strings } from "../content/strings"
import { ILoggedInUser } from "../models/ILoggedInUser"

export interface ILink { href?: string, external?: boolean, link?: string }

export interface INavigation {
    link: ILink,
    logout?: boolean,
    icon: IconDefinition,
    text: string,
    active?: boolean,
    children?: any,
    analyticsTag?: string,
    disabled?: any,
    hidden?: any,
    tooltip?: string,
    popup?: boolean
}

export const doesPathMatchLink = (link: ILink, location: { pathname: string }) => {
    if (link.href) {
        return `/${link.href}` == location.pathname
    }
    return link.link == location.pathname
}

const disableIfNoLocks = (loggedInUser: ILoggedInUser | null): [boolean, string] => {
    return [!loggedInUser?.hasLocks, strings.needOneLock]
}


const hideSaasItems = (loggedInUser: ILoggedInUser | null): boolean => {
    return loggedInUser?.companyID !== 2;
}

const hideTab = (loggedInUser: ILoggedInUser): boolean | undefined => {
    return loggedInUser?.email?.includes('@publicstorage.com')
}


// temporary flag to show the auto notification page for test company, 10 federal, and mini mall, freedom storage management users
const showAutoNotificationFor10Federal = (loggedInUser: ILoggedInUser | null): boolean => {
    return loggedInUser?.companyID !== 1 && loggedInUser?.companyID !== 2 && loggedInUser?.companyID !== 1341 && loggedInUser?.companyID !== 1305 && loggedInUser?.companyID !== 25;
}

export default class Navigation {

    static premium = (): INavigation => ({
        link: { link: "" },
        icon: faTachometerAlt,
        text: strings.davinciLockPremium,
        tooltip: strings.comingSoon,
        analyticsTag: "premium",
        popup: true,
        hidden: hideSaasItems
    })
    static dash = (link: ILink): INavigation => ({
        link,
        icon: faTachometerAlt,
        text: strings.dashboard,
        analyticsTag: "Dashboard Link"
    })
    static activateSerialCode = (): INavigation => ({
        link: { link: "/SerialCodes_Locks/Redeem" },
        icon: faLock,
        text: strings.activateSerialCode,
        analyticsTag: "Activate Serial Code Link",
    })
    static apiSettings = (): INavigation => ({
        link: { link: "/Company/Api" },
        icon: faPuzzlePiece,
        text: strings.apiSetting,
        analyticsTag: "API Settings Link"
    })
    // static companies = (): INavigation => ({
    //     link: { href: "Company" },
    //     icon: faCalculator,
    //     text: strings.companies,
    //     analyticsTag: "Companies Link"
    //     disabled: disableIfNoFacilities
    // })
    // static billingPlans = (): INavigation => ({
    //     link: { href: "Company/BillingPlanListing" },
    //     icon: faMoneyBillAlt,
    //     text: "Billing Plans"
    // })
    static companies = (): INavigation => ({
        link: { link: "/Company" },
        icon: faCalculator,
        text: strings.companies,
        analyticsTag: "Companies Link"
    })
    static subdomains = (): INavigation => ({
        link: { link: "/Company/Subdomains" },
        icon: faFolderTree,
        text: strings.subdomains,
        analyticsTag: "Subdomains Link"
    })
    static inventory = (): INavigation => ({
        link: { link: "/Company/Inventory" },
        icon: faLockOpen,
        text: strings.inventory,
        analyticsTag: "Inventory Link"
    })
    static profile = (): INavigation => ({
        link: { link: "/Users/Profile" },
        icon: faUserCog,
        text: strings.profile,
        analyticsTag: "Profile Link"
    })
    // static companyDetails = (): INavigation => ({
    //     link: { href: "Company/BillingDetails" },
    //     icon: faCalculator,
    //     text: "Company Details"
    // })
    static facilities = (): INavigation => ({
        link: { link: "/Company/FacilityListing" },
        icon: faUniversity,
        text: strings.facilities,
        analyticsTag: "Facilities Link"
    })
    static freeSerialCodes = (): INavigation => ({
        link: { link: "/SerialCodes_Locks/FreeCodes" },
        icon: faGift,
        text: strings.freeSerialCodes,
        analyticsTag: "Free Serial Codes Link"
    })
    static howToVideos = (): INavigation => ({
        link: { link: "/Account/HowTo" },
        icon: faInfo,
        text: strings.howToVids,
        analyticsTag: "How To Videos Link"
    })
    static logs = (): INavigation => ({
        link: { link: "/Users/GetLogs" },
        icon: faFileAlt,
        text: strings.logs,
        analyticsTag: "Logs Link"
    })
    static jobs = (): INavigation => ({
        link: { href: `Account/Jobs?token=${getToken()}&origin=${location.origin}` },
        icon: faCubes,
        text: strings.jobs,
        analyticsTag: "Jobs Link"
    })
    static processReturnLocks = (): INavigation => ({
        link: { link: "/SerialCodes_Locks/Reports" },
        icon: faCogs,
        text: strings.processReturned,
        analyticsTag: "Process Returns Link",
        disabled: disableIfNoLocks,
        hidden: hideTab
    })
    static serialCodes = (): INavigation => ({
        link: { link: "/SerialCodes_Locks/SerialCodeListingByCompany" },
        icon: faBarcode,
        text: strings.serialCodes,
        analyticsTag: "Serial Codes Link",
        disabled: disableIfNoLocks,
    })
    static users = (): INavigation => ({
        link: { link: "/Users/ClientUserListing" },
        icon: faUsers,
        text: strings.users,
        analyticsTag: "Users Link"
    })
    static davinciUsers = (): INavigation => ({
        link: { link: "/Users/DaVinciLock" },
        icon: faUsers,
        text: strings.davinciUsers,
        analyticsTag: "DaVinci Users Link"
    })
    static settings = (...children: INavigation[]): INavigation => ({
        link: {},
        icon: faCog,
        text: strings.settings,
        children,
        analyticsTag: "Settings Link"
    })
    static RolesAndPermissions = (): INavigation => ({
        link: { link: "/Company/RolesAndPermissions" },
        icon: faUsers,
        text: strings.rolesAndPermissions,
        analyticsTag: "Roles And Permissions Link",
        hidden: hideSaasItems
    })
    static Integrations = (): INavigation => ({
        link: { link: "/Company/Integrations" },
        icon: faUsers,
        text: strings.integrations,
        analyticsTag: "Integrations Link",
        hidden: hideSaasItems
    })
    static AutoNotifications = (): INavigation => ({
        link: { link: "/Company/AutoNotifications" },
        icon: faBell,
        text: "Auto Messages",
        analyticsTag: "AutoNotifications Link",
        hidden: showAutoNotificationFor10Federal
    })
    static logout = (): INavigation => ({
        link: { href: "/Account/LogOff" },
        icon: faArrowRight,
        text: strings.logout,
        analyticsTag: "Logout Link",
        logout: true,
    })
}
