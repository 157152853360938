import { useContext, useEffect, useState } from "react"
import agents from "../api/agent";
import { ToastContext } from "../contexts/ToastProvider";
import LockStatus from "../enums/LockStatus";
import { ToastType } from "../enums/ToastType"
import { debugLog } from "../utils/debugLog";

enum OverlockLockStatusReplacementEnum {
    "Don't Change status" = 1004
}
enum VacantLockStatusReplacementEnum {
    "Don't Change status" = 1005
}

export const OverlockMap = { ...LockStatus, ...OverlockLockStatusReplacementEnum } as LockStatus & OverlockLockStatusReplacementEnum
export const VacantMap = { ...LockStatus, ...VacantLockStatusReplacementEnum } as LockStatus & VacantLockStatusReplacementEnum
export const filterVacant = [LockStatus.Available, LockStatus["Returned to Dropbox"], LockStatus["Tenant Using Lock"], LockStatus["Assigned Vacant"]].map(ls => VacantMap[ls])
export const filterOverLock = [LockStatus.Available, LockStatus["Returned to Dropbox"], LockStatus["Tenant Using Lock"], LockStatus["Assigned Overlock"]].map(ls => OverlockMap[ls])

type OverlockLockStatusReplacement = LockStatus & OverlockLockStatusReplacementEnum
type VacantLockStatusReplacement = LockStatus & VacantLockStatusReplacementEnum

export const useAutoLockStatusChange = () => {
    const [assignedOverLock, setAssignedOverLock] = useState<number | null>(null);
    const [assignedVacant, setAssignedVacant] = useState<number | null>(null)
    const [locksPerUnit, setLocksPerUnit] = useState<number>(4)
    const { displayToast } = useContext(ToastContext);

    const getProfileData = async () => {
        const result = await agents.Settings.getSettingsProfile();
        if (result.success) {
            const data = result.data ?? {};
            setAssignedOverLock(data.overlockReleaseAction);
            setAssignedVacant(data.vacantReleaseAction);
            setLocksPerUnit(data.numberOfLocks);
        }
    }

    const updateAssignedOverLock = async (action: OverlockLockStatusReplacement) => {
        const value = OverlockMap[action]
        setAssignedOverLock(value)

        try {
            if (value === assignedOverLock) {
                return;
            }

            const result = await agents.Settings.updateAssignedOverlockAction(value);

            if (result.success) {
                displayToast({ type: ToastType.Success, text: result.data })
                return;
            } else {
                setAssignedOverLock(assignedOverLock)
                displayToast({ type: ToastType.Error, text: result.data })
            }
        } catch (e) {
            debugLog(e)
        }
    }

    const updateAssignedVacant = async (action: VacantLockStatusReplacement) => {
        const value = VacantMap[action]
        setAssignedVacant(value)

        try {
            if (value === assignedVacant) {
                return;
            }

            const result = await agents.Settings.updateAssignedVacantAction(value);
            if (result.success) {
                displayToast({ type: ToastType.Success, text: result.data })
                return;
            } else {
                setAssignedVacant(assignedVacant)
                displayToast({ type: ToastType.Error, text: result.data })
            }
        } catch (e) {
            debugLog(e)
        }
    }


    const updateLocksPerUnit = async () => {
        try {
            setLocksPerUnit(prev => prev === 1 ? 4 : 1)
            const result = await agents.Settings.updateLocksPerUnitSetting(locksPerUnit === 1);
            if (result.success) {
                displayToast({ type: ToastType.Success, text: result.data })
                return;
            } else {
                setLocksPerUnit(locksPerUnit)
                displayToast({ type: ToastType.Error, text: result.data })
            }
        } catch (e) {
            setLocksPerUnit(locksPerUnit)
            debugLog(e)
        }
    }

    useEffect(() => {
        getProfileData();
    }, [])

    return {
        assignedOverLock,
        assignedVacant,
        locksPerUnit,
        updateAssignedOverLock,
        updateAssignedVacant,
        updateLocksPerUnit
    }
}
