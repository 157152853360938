import {
    useEffect, useRef,
    useCallback
} from "react";
import LZString from "../utils/lz-string.js";
import { debugLog } from "../utils/debugLog.js";

const worker = new Worker(
    // @ts-ignore
    new URL('../workers/compressCache.worker.js', import.meta.url),
    { type: 'module' }
);

/** LZ compresses data and caches into LocalStorage
 * @param cacheKey - identifier to set and get cache
 */

export function useCacheWorker<T>(cacheKey: string) {
    const cacheKeyRef = useRef<string>("");
    cacheKeyRef.current = cacheKey;

    const cacheQueue = useRef<{ data: T, key: string } | null>(null)
    const workingRef = useRef(false)

    useEffect(() => {
        worker.onerror = (err) => debugLog({ err });
        worker.onmessage = (e) => {
            if (e.data.key) {
                localStorage.setItem(e.data.key, e.data.compressedData);
            }

            if (cacheQueue.current) {
                worker.postMessage(cacheQueue.current);
                cacheQueue.current = null
            } else {
                workingRef.current = false
            }
        };
    }, []);

    const getCachedData = useCallback((): T | null => {
        const uncompressedData: string | null = localStorage.getItem(cacheKey);

        if (uncompressedData) {
            return JSON.parse(LZString.decompress(uncompressedData) as string);
        }
        return null;
    }, [cacheKey]);

    const postCache = (data: T) => {
        const workItem = {
            data,
            key: cacheKey
        }

        if (workingRef.current) {
            cacheQueue.current = workItem
        } else {
            workingRef.current = true
            worker.postMessage(workItem);
        }
    }

    return { getCachedData, postCache };
}
