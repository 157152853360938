import React, { useEffect, useState } from "react";
import links from "../../../routes/links"
import agents from "../../../api/agent";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { strings } from "../../../content/strings"
import { PrimaryLink } from "../../../components/button"
import { H3, DisclaimerText } from "../../../core/brand/typography"
import { Image, ImgWrapper } from "../../../components/styled/boxes";
import { Section } from "../../../core/layout/Section"
import { CompanyImage } from 'src/models/companyImages'
import styled from "styled-components";

const CustomerSection = () => {
    const [images, setImages] = useState<CompanyImage[]>([])
    const [isMobile] = useMediaQuery(411);
    const margin = isMobile ? "0" : "8px 24px 0 24px";

    useEffect(() => {
        const fetchData = async () => {
            const response = await agents.Company.topCompanies();
            setImages(response.data);
        }
        fetchData()

    }, [])

    return (
        <Section paddingY={[24, 0]}>
            <H3>{strings.joinCustomers}</H3>
            <ImgWrapper >
                {images?.map(({ filename }) =>
                    <Image
                        src={agents.Account.getCompanyImage(filename)}
                        imgHeight="4rem"
                        imgMargin={margin}
                        filter="grayscale(100%)"
                        key={`image-logo-${filename}`}
                    />
                )}
            </ImgWrapper>
            <Container>
                <PrimaryLink {...links.LEADFORM}>{strings.getStarted}</PrimaryLink>
            </Container>
            <DisclaimerText>{strings.disclaimer}</DisclaimerText>
        </Section>
    )
}

export const Container = styled.div({
    padding: "16px 0"
})
export default CustomerSection
