import React, { useState, useContext, useEffect } from "react"
import agents from "../../../api/agent"
import AutoComplete from "../../../core/form-components/OldAutoComplete";
import styled from 'styled-components'
import Table from "../../../core/table/Table"
import { LockModel } from "../../../models/lockModel"
import { PrimaryButton } from "../../../components/button"
import { ILoggedInUser } from "../../../models/ILoggedInUser"
import { strings } from "../../../content/strings";
import { FacilityDto } from "../../../models/facility";
import { useUsers } from "../../../hooks/useUsers";
import LockStatus from "../../../enums/LockStatus";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { Colors } from "../../../core/brand/themes";
import useAnalytics from "../../../hooks/useAnalytics";
import { events as analyticsEvents } from "../../../mixPanelEvents";
import { useFacilities } from "../../../hooks/useFacilities";
import { SelectFacility } from "../../../core/form-components/SelectFacility";
import { LocationContext } from "../../../contexts/LocationContext";
import TableTitle from "../../../core/table/TableTitle";
import TableWrapper from "../../../core/table/TableWrapper";
import { Box } from "@mui/material";

const style = { padding: "1px 10px", margin: "2px 4px" }
const ReturnAction = ({ onClick, children, lockStatus, tr }:
    { onClick: (item: any, newStatus: any) => void, children: any, lockStatus: number, tr: any }) => {
    return (
        <PrimaryButton
            style={style}
            onClick={(e) => onClick(tr, lockStatus)}
            disabled={tr.secondaryLockStatus !== LockStatus["Returned to Dropbox"]}
            inactive={tr.updateSecondaryLockStatusTo !== lockStatus && tr.secondaryLockStatus !== lockStatus}
        >{children}</PrimaryButton>
    )
}

const currentReport = { createdOn: "Current", reportId: 0 }

const ProcessLockReturns = ({ locks }: { locks: LockModel[] }) => {
    const { trackClickEvent } = useAnalytics("Process Returns")
    const { facilities } = useFacilities();
    const { displayToast } = useContext(ToastContext)
    const { users } = useUsers();

    const [returnedLocks, setReturnedLocks] = useState<LockModel[]>([])
    const [selectedFacility, setSelectedFacility] = useState<number>(0)
    const [selectedUser, setSelectedUser] = useState<ILoggedInUser>();
    const [reports, setReports] = useState<any[]>([]);
    const [selectedReport, setSelectedReport] = useState(currentReport);

    useEffect(() => {
        if (selectedFacility) {
            const filteredLocks = locks.filter(l => l.facilityID === selectedFacility)
            const locksRemovedFromList = returnedLocks.filter(rl => locks.findIndex(l => l.lockID === rl.lockID) === -1)
            if (locksRemovedFromList.length) {
                locksRemovedFromList.forEach(l => {
                    displayToast({
                        type: ToastType.Info, text: `${strings.returnedStatus} ${l.serialCode}`
                    });
                })
            }
            setReturnedLocks(
                returnedLocks.length ?
                    filteredLocks.map((l: LockModel) => {
                        const updatedLock = returnedLocks.find(rl => rl.lockID === l.lockID) || null;
                        if (!updatedLock) {
                            displayToast({
                                type: ToastType.Info, text: `${l.serialCode} ${strings.updatedStatus}`
                            });
                        }
                        return {
                            ...l,
                            ...(updatedLock ? {
                                updateSecondaryLockStatusTo: updatedLock.updateSecondaryLockStatusTo
                            } : {})
                        }
                    }) : filteredLocks)
        }
    }, [locks])

    const getReports = async () => {
        const result = await agents.Locks.getReports(selectedFacility!)
        if (result.success && result.data) {
            setReports(result.data);
        } else {
            setReports([])
        }
    }

    useEffect(() => {
        if (selectedFacility) {
            setReturnedLocks(locks.filter(l => l.facilityID === selectedFacility))

            getReports()
            setSelectedReport(currentReport);
        }
    }, [selectedFacility])

    useEffect(() => {
        if (!selectedFacility && facilities[0] && !selectedFacility) {
            setSelectedFacility(prev => prev ? prev : facilities[0].facilityID || 0);
        }
    }, [facilities])

    const handleAutoCompleteClick = (facility: FacilityDto) => {
        trackClickEvent(analyticsEvents.selectFacility, {
            contextID: facility?.facilityID || 0
        })

        setSelectedFacility(facility?.facilityID || 0)
    }

    const handleReportChange = async (report: { reportId: number, createdOn: string }) => {
        if (report.reportId) {
            trackClickEvent(analyticsEvents.selectNoReport, {
                contextID: report.reportId
            })
            const result = await agents.Locks.getReportDetails(report.reportId)
            if (result.success && result.data) {
                setReturnedLocks(result.data)
                setSelectedReport(report)
            }
        } else {
            trackClickEvent(analyticsEvents.selectNoReport)
            if (selectedFacility) {
                setReturnedLocks(locks.filter(l => l.facilityID === selectedFacility))
                setSelectedReport(currentReport)
            }
        }
    }

    const handleUserSelect = (user: ILoggedInUser) => {
        trackClickEvent(analyticsEvents.selectReceivingUser)
        setSelectedUser(user)
    }

    const onClick = (item: LockModel, newStatus: number) => {
        setReturnedLocks(
            returnedLocks.map(l => l === item ? { ...l, updateSecondaryLockStatusTo: newStatus } : l)
        )
    }
    const ColumnContent = (tr: any) => (
        <div style={{ display: "flex", justifyContent: "space-evenly", ...(tr ? {} : { opacity: 0 }) }}>
            <ReturnAction onClick={onClick} tr={tr} lockStatus={LockStatus.Available}>{strings.available}</ReturnAction>
            <ReturnAction onClick={onClick} tr={tr} lockStatus={LockStatus.Lost}>{strings.lost}</ReturnAction>
            <ReturnAction onClick={onClick} tr={tr} lockStatus={LockStatus["Tenant Using Lock"]}>{strings.tenantUsing}</ReturnAction>
            <ReturnAction onClick={onClick} tr={tr} lockStatus={LockStatus.Damaged}>{strings.damaged}</ReturnAction>
        </div>
    )

    const headers: { text: string, key: string, date?: boolean, minWidth?: number, content?: any }[] =
        [
            { text: strings.serialCode, key: "serialCode", minWidth: 90 },
            { text: strings.unitNumber, key: "unitNumber", minWidth: 110 },
            ...(selectedReport?.reportId
                ? []
                : [{ text: strings.unlockCode, key: "unlockCode" }]),
            {
                text: strings.status, key: "secondaryLockStatus",
                content: (tr: LockModel): any => tr.secondaryLockStatus ? LockStatus[tr.secondaryLockStatus as number] : ""
            },
            { text: strings.updatedBy, key: "updatedBy" },
            { text: strings.updatedOn, key: "updatedOn", date: true },
            ...(selectedReport?.reportId ? [] : [{
                text: "",
                key: "updatingStatus",
                minWidth: 350,
                content: ColumnContent,
            }])
        ]

    const mobileKeys = [
        { text: strings.status, key: "secondaryStatusName" },
        { text: strings.serialCode, key: "serialCode" },
        {
            text: "",
            key: "",
            content: ColumnContent,
        },
    ]

    const handleSubmit = async () => {
        trackClickEvent(analyticsEvents.submit)
        const remainingLocks: LockModel[] = returnedLocks.filter(l => !l.updateSecondaryLockStatusTo)
        const updatingLocks: LockModel[] = returnedLocks.filter(l => !!l.updateSecondaryLockStatusTo)

        if (updatingLocks.length === 0) {
            displayToast({ type: ToastType.Error, text: strings.updateOneLock })
        } else if (!selectedUser?.userID) {
            displayToast({ type: ToastType.Error, text: strings.updateUser })
        } else {
            const result = await agents.Locks.processLockReturns(updatingLocks, selectedUser.userID);
            if (result.success) {
                setReturnedLocks(remainingLocks);
                getReports();
                displayToast({ type: ToastType.Success })
            } else {
                displayToast({ type: ToastType.Error })
            }
        }
    }

    const mobileActions = () => {
        return (
            <Box sx={{ display: "flex", alignSelf: "start", flexDirection: "column", width: "100%", marginBottom: "8px" }}>
                <AutoComplete
                    noClear
                    propName="name"
                    values={users}
                    label={strings.recipient}
                    setValue={handleUserSelect}
                />
                <PrimaryButton fullWidth onClick={handleSubmit}>{strings.sendEmail}</PrimaryButton>
            </Box>
        )
    }

    const facilityValues = facilities.map((f: any) => {
        const count = locks.filter((l: LockModel) => l.facilityID == f.facilityID).length;
        return {
            ...f,
            suggestion: `${f.facilityName} ${count ? `(${count})` : ""}`
        }
    })

    return (
        <Container>
            <ActionsContainer>
                <TableTitle title={strings.processLockReturns} />
                <SelectFacility
                    setValue={handleAutoCompleteClick}
                    geolocate
                    noClear
                    propName="suggestion"
                    values={facilityValues}
                    facilityID={selectedFacility || 0}
                />
                <AutoComplete
                    noClear
                    noSort
                    propName="createdOn"
                    values={[currentReport, ...reports]}
                    value={selectedReport.createdOn}
                    label={strings.reports}
                    setValue={handleReportChange}
                />
            </ActionsContainer >
            <TableWrapper>
                <Table
                    data={returnedLocks}
                    headers={headers}
                    noDataMessage={strings.noLocksFound}
                    mobileIdentifier="unitNumber"
                    mobileHeaders={mobileKeys}
                    mobileActions={mobileActions}

                >
                    <SubmitContainer>
                        <AutoComplete
                            noClear
                            propName="name"
                            values={users}
                            label={strings.recipient}
                            setValue={handleUserSelect}
                        />
                        <PrimaryButton onClick={handleSubmit}>{strings.sendEmail}</PrimaryButton>
                    </SubmitContainer>
                </Table>
            </TableWrapper>
        </Container >
    )
}

const ActionsContainer = styled.div({
    backgroundColor: Colors.white(),
    display: "flex",
    gap: "24px",
    justifyContent: "end",
    width: "100%",
    "@media (max-width: 414px)": {
        flexDirection: "column",
        gap: "4px",
    },
})

const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    padding: "8px",
    boxSizing: "border-box",
})

const SubmitContainer = styled.div({
    justifySelf: "flex-end",
    alignSelf: "end",
    display: "flex",
    justifyContent: "flex-end"
})

export default ProcessLockReturns;
