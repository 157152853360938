import React, { useContext, useState } from "react";
import styled from "styled-components";
import { PrimaryButton } from "../../../components/button";
import Table, { HeaderProps } from "../../../core/table/Table";
import { strings } from "../../../content/strings";
import { Colors } from "../../../core/brand/themes";
import { ISubdomain } from "../../../models/ISubdomain";
import { AddEditSubdomain } from "./AddEditSubdomain";
import useAnalytics from "../../../hooks/useAnalytics";
import TableWrapper from "../../../core/table/TableWrapper";
import { useLoggedInUser, useMediaQuery, useSubdomains } from "../../../hooks/hooks";
import TableTitle from "../../../core/table/TableTitle";
import Permission from "../../../enums/Permission";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";

const headers: HeaderProps[] =
    [
        { text: strings.displayName, key: "displayName" },
        { text: strings.subdomain, key: "subdomainUrl" },
        { text: strings.facilities, key: "facilities", content: (tr: ISubdomain) => tr.facilityIDs?.length, endOfRow: true },
    ];

const SubdomainsContainer = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const { subdomains, fetchSubdomains } = useSubdomains();
    const [selectedSubdomain, setSelectedSubdomain] = useState<ISubdomain | null>(null)
    const { trackClickEvent } = useAnalytics("Subdomains", false)
    const [mobile] = useMediaQuery(414)
    const mobileButtonStyles = mobile ? { width: "95%", marginTop: "-4px", marginBottom: "8px" } : {}
    const { loggedInUser } = useLoggedInUser();
    const { displayToast } = useContext(ToastContext);

    const writePermissions = Permission.write(Permission.Subdomains, loggedInUser)

    const handleDialog = () => {
        if (selectedSubdomain) {
            trackClickEvent("Close Edit Subdomain")
            setSelectedSubdomain(null)
        } else {
            if (!writePermissions) {
                displayToast({ type: ToastType.Info, text: strings.insufficientPermissions })
                return;
            }
            trackClickEvent(`${openDialog ? "Close" : "Open"} Add Subdomain`)
            setOpenDialog((dialog) => !dialog);
        }
    }

    return (
        <Container>
            <ActionsContainer>
                <TableTitle title={strings.subdomain} />
                {writePermissions && <PrimaryButton style={mobileButtonStyles} onClick={handleDialog}>{strings.addSubdomain}</PrimaryButton>}
            </ActionsContainer>

            {(openDialog || selectedSubdomain) &&
                <AddEditSubdomain
                    trackClickEvent={trackClickEvent}
                    openDialog={openDialog || !!selectedSubdomain}
                    handleDialog={handleDialog}
                    fetchSubdomains={fetchSubdomains}
                    initialValues={{
                        facilityIDs: [],
                        subdomainUrl: "",
                        displayName: "",
                        logo: "",
                        file: null,
                        ...(selectedSubdomain ? selectedSubdomain : {})
                    }}
                />
            }

            <TableWrapper>
                <Table
                    tooltip={writePermissions ? strings.tableTooltip : ""}
                    data={subdomains!}
                    headers={headers}
                    rowProps={{
                        onClick: (item: any) => {
                            if (!writePermissions) {
                                displayToast({ type: ToastType.Info, text: strings.insufficientPermissions })
                                return;
                            }
                            trackClickEvent("Open Edit Subdomain");
                            setSelectedSubdomain(item);
                        }
                    }}
                    selectedRow={selectedSubdomain}
                    mobileIdentifier="subdomainUrl"
                    mobileHeaders={headers.filter((h: any) => h.key !== "subdomainUrl")}
                />
            </TableWrapper>
        </Container>
    )
}

export const LocksDataHeader = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    backgroundColor: Colors.red(),
    color: Colors.white(),
})

export const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    padding: "8px",
    boxSizing: "border-box",
})

export const CloseBladeButton = styled("span")({
    cursor: "pointer",
})

const ActionsContainer = styled.div({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "@media (max-width: 414px)": {
        flexDirection: "column",
        gap: "4px",
    },
})

export default SubdomainsContainer
