﻿import React, { useState } from "react";
import ButtonTag, { ClippedButtonTag, SubLinkTag } from "../components/styled/button-tag";
import useAnalytics from "../hooks/useAnalytics";

const Loader = () => <div className="lds-dual-ring" />

export type toggleType = 0 | 1

type CoreButtonProps = {
    link?: string;
    external?: boolean;
    popup?: boolean;
    fullWidth?: boolean;
    width?: string;
    href?: string;
    secondary?: toggleType;
    tertiary?: toggleType;
    quaternary?: toggleType;
    subLink?: toggleType;
    clipped?: boolean;
    pending?: boolean;
    onClick?: (event: any) => { unmounting?: boolean } | React.MouseEvent<HTMLElement> | any;
    clickContext?: string,
};

const getButtonTag = (clipped?: boolean, subLink?: toggleType) => {
    if (clipped) return ClippedButtonTag;
    if (subLink) return SubLinkTag
    return ButtonTag
}

const AbstractButton: React.FC<CoreButtonProps> = ({
    children,
    link,
    external,
    onClick,
    clipped,
    popup,
    subLink,
    pending,
    clickContext,
    ...props
}) => {
    const { trackClickEvent } = useAnalytics("Button", false)
    const [submitting, setSubmitting] = useState(false);
    const Tag = getButtonTag(clipped, subLink)

    if (link) {
        return (
            <Tag to={link} {...props}>
                {children}
            </Tag>
        );
    } else if (onClick) {
        const onClickHandler = async (e: any) => {
            clickContext && trackClickEvent(clickContext)
            e.preventDefault()
            const task = onClick(e)
            setSubmitting(true)
            const { unmounting } = (await task) || {};

            if (!unmounting) {
                setSubmitting(false);
            }
        }
        return (
            <Tag loading={submitting || pending} as={"button"} onClick={!submitting ? onClickHandler : () => { /* noop */ }} {...props}>
                {(submitting || pending) && <Loader />}{children}
            </Tag>
        );
    }
    if (popup) {
        return (
            <Tag as={"a"} target={"popup"} {...props}
                onClick={() => { window.open(props.href, 'popup', 'width=600,height=600'); return false; }}
            >
                {children}
            </Tag>
        )
    }
    return (
        <Tag as={"a"} target={external ? "_blank" : ""} {...props}>
            {children}
        </Tag>
    );
};

// Use for onClick functionality
type ButtonProps = {
    fullWidth?: boolean;
    width?: string;
    onClick?: (event: any) => void;
    onMouseDown?: (event: any) => void;
    disabled?: boolean;
    type?: string,
    pending?: boolean,
    clickContext?: string,
    style?: any,
    as?: string,
    inactive?: boolean,
};

export const PrimaryButton: React.FC<ButtonProps> = ({
    children,
    ...props
}) => <AbstractButton {...props}> {children} </AbstractButton>;

export const SecondaryButton: React.FC<ButtonProps> = ({
    children,
    ...props
}) => (
    <AbstractButton {...props} secondary={1}>
        {children}
    </AbstractButton>
);

export const TertiaryButton: React.FC<ButtonProps> = ({
    children,
    ...props
}) => (
    <AbstractButton {...props} tertiary={1}>
        {children}
    </AbstractButton>
);
export const QuaternaryButton: React.FC<ButtonProps> = ({
    children,
    ...props
}) => (
    <AbstractButton {...props} quaternary={1}>
        {children}
    </AbstractButton>
);

export const ToggleButton: React.FC<ButtonProps & { selected: boolean }> = ({
    children,
    selected,
    ...props
}) => (
    <AbstractButton {...props} quaternary={selected ? 1 : 0}>
        {children}
    </AbstractButton>
)

// Use for navigation
type LinkProps = {
    style?: { [key: string]: any };
    link?: string;
    external?: boolean;
    fullWidth?: boolean;
    href?: string;
    clipped?: boolean;
    onClick?: any
};

export const PrimaryLink: React.FC<LinkProps> = ({ children, ...props }) => (
    <AbstractButton {...props}> {children} </AbstractButton>
);

export const SecondaryLink: React.FC<LinkProps> = ({ children, ...props }) => (
    <AbstractButton {...props} secondary={1}>
        {children}
    </AbstractButton>
);

export const SubLink: React.FC<LinkProps> = ({ children, ...props }) => (
    <AbstractButton {...props} subLink={1}>
        {children}
    </AbstractButton>
);
