import { useEffect, useState } from 'react';

export const useWindowSize = (): {
  windowWidth: number,
  windowHeight: number,
} => {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    windowWidth: windowSize.width,
    windowHeight: windowSize.height,
  };
}

export const useMediaQuery = (...maxWidths: number[]) => {
  const { windowWidth } = useWindowSize();
  return maxWidths.map(maxWidth => (windowWidth || 0) <= maxWidth)
}

export const useWindowWidth = (): number => {
  const { windowWidth } = useWindowSize();
  return windowWidth;
}
