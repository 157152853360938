import React, { useContext } from "react"
import agents from "../../../api/agent";
import Input from "../../../core/form-components/Input";
import { editEmailValidator } from "../../../validators/validators";
import { PrimaryButton } from "../../../components/button";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { useForm } from "../../../hooks/useForm"
import FormDivider from "./FormDivider";
import { FlexInputContainer, FormCard } from "./FormStyles";
import { IChangeEmail } from "../../../models/IChangeEmail";
import { strings } from "../../../content/strings";
import useAnalytics from "../../../hooks/useAnalytics";
import { events as analyticsEvents } from "../../../mixPanelEvents";
import { useFetchLoggedInUser } from "../../../hooks/useFetchLoggedInUser";

const EmailForm = ({ initialValues }: { initialValues: IChangeEmail }) => {
    const { displayToast } = useContext(ToastContext);
    const { trackClickEvent } = useAnalytics("Update Email Form")
    const { fetchLoggedInUser } = useFetchLoggedInUser();

    const submitAction = async (formValues: IChangeEmail, setFormValues: (arg0: IChangeEmail) => any) => {
        trackClickEvent(analyticsEvents.submit)
         const result = await agents.Auth.updateUserEmail(formValues?.newEmail!, formValues?.confirmEmail!);
        if (result.success) {
            fetchLoggedInUser();
            setFormValues({ email: formValues.newEmail, newEmail: "", confirmEmail: "" })
            displayToast({ type: ToastType.Success })
        } else {
            displayToast({ type: ToastType.Error });
        }
    }

    const form = useForm<IChangeEmail>(initialValues, submitAction, editEmailValidator)

    return (
        <FormCard>
            <Input {...form.inputProps.email} label={strings.email} disabled />
            <FlexInputContainer>
                <Input {...form.inputProps.newEmail} label={strings.newEmail} />
                <Input {...form.inputProps.confirmEmail} label={strings.confirmEmail} />
            </FlexInputContainer>
            <PrimaryButton fullWidth onClick={form.handleSubmit} >{strings.changeEmail}</PrimaryButton>
            <FormDivider />
        </FormCard>
    )
}

export default EmailForm
