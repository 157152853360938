import React from "react";
import gears from "../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import ProcessLockReturns from "./components/ProcessLockReturns";
import LockStatus from "../../enums/LockStatus";
import { strings } from "../../content/strings";
import { useLocks } from "../../hooks/useLocks";

const ProcessLockReturnsPage = () => {
    const { locks } = useLocks();

    const locksReturned =
        locks?.filter(l => l.secondaryLockStatus == LockStatus["Returned to Dropbox"])

    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.processLockReturns}
            pageDescription=""
            pageCanonical="www.davincilock.com/SerialCodes_Locks/Reports">
            <ProcessLockReturns locks={locksReturned} />
        </LoggedInPageTemplate >
    );
};

export default ProcessLockReturnsPage;
