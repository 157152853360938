import { useState, useEffect, useRef, useMemo, useContext } from "react"
import styled from 'styled-components'
import Table from "../../../core/table/Table"
import { LockModel } from "../../../models/lockModel"
import SearchField from "../../../core/form-components/SearchField"
import { strings } from "../../../content/strings"
import { useLoggedInUser, useMediaQuery } from "../../../hooks/hooks";
import LockStatus from "../../../enums/LockStatus"
import Role from "../../../enums/Role";
import { BladePopout } from "../../../core/layout/BladePopout"
import { ILockModelForm } from "../../../models/ILockModelForm";
import { PrintTableButton } from "../../../pages/serial-codes/components/PrintTableButton"
import { DownloadExcel } from "../../../pages/serial-codes/components/DownloadExcel"
import { Box, Switch } from "@mui/material"
import { EditInventoryForm } from "./EditInventoryForm"
import { useLocks } from "../../../hooks/useLocks";
import { PrimaryButton, SecondaryLink } from "../../../components/button"
import useAnalytics from "../../../hooks/useAnalytics"
import TableWrapper from "../../../core/table/TableWrapper"
import TableTitle from "../../../core/table/TableTitle"
import Permission from "../../../enums/Permission"
import { ToastContext } from "../../../contexts/ToastProvider"
import { ToastType } from "../../../enums/ToastType"
import { PMedium } from "../../../core/brand/typography"
import agents from "../../../api/agent"
import { SelectFacility } from "../../../core/form-components/SelectFacility"
import { FacilityDto } from "../../../models/facility"
import GenericLoader from "../../../core/surfaces/GenericLoader"

const InventoryContainer = () => {
    const { trackClickEvent } = useAnalytics("Inventory", false)
    const { inventoryLocks, fetchLocks, pending } = useLocks();
    const { loggedInUser } = useLoggedInUser();
    const [mobile] = useMediaQuery(414);

    const { displayToast } = useContext(ToastContext);

    const [filteredLocks, setFilteredLocks] = useState<LockModel[]>([]);
    const [selectedLock, setSelectedLock] = useState<LockModel | null>();
    const [selectedIDs, setSelectedIDs] = useState<number[]>([])
    const [selectedFacility, setSelectedFacility] = useState<FacilityDto | null>(null)

    const printTableRef = useRef();

    const writePermissions = Permission.write(Permission.Inventory, loggedInUser)

    const restrictedPersonnelCannotEdit = (lockModel: LockModel) => loggedInUser?.roleID === Role["Restricted Personnel"] && lockModel.secondaryLockStatus == LockStatus["Tenant Using Lock"]

    const selectRow = async (lockModel: LockModel) => {
        if (!writePermissions) {
            displayToast({ type: ToastType.Info, text: strings.insufficientPermissions })
            return;
        }
        trackClickEvent("Open Edit Lock")
        if (restrictedPersonnelCannotEdit(lockModel)) {
            return
        }
        setSelectedLock(lockModel)
    }

    const handleSubmit = async () => {
        if (!selectedFacility?.facilityID || !selectedIDs.length) {
            return;
        }
        const failed = []
        const succeeded = []
        for (let i = 0; i < selectedIDs.length; i++) {
            const res = await agents.Locks.transferLock({
                lockID: selectedIDs[i],
                facilityID: selectedFacility.facilityID
            })
            if (!res.success) {
                failed.push(selectedIDs[i])
            } else {
                succeeded.push(selectedIDs[i])
            }
        }
        if (failed.length) {
            displayToast({ type: ToastType.Warning, text: `${succeeded.length} out of ${selectedIDs.length} transferred` })
        } else {
            displayToast({ type: ToastType.Success })
        }
        setSelectedIDs([])
    }

    useEffect(() => {        
        if (!inventoryLocks.length) {
            const inventory = true;
            const facility = false;
            fetchLocks(inventory, facility)
        }
    },[])


    const headers: {
        text: string,
        key: string,
        date?: boolean,
        content?: any,
        normalSort?: boolean,
        filterOptions?: string[] | { label: string, value: any }[]
    }[] = useMemo(() =>
        [
            {
                text: "",
                key: "includedInAPI",
                maxWidth: "30px",
                content: (tr: LockModel): any =>
                    <Switch
                        key={tr.serialCode}
                        checked={selectedIDs.includes(tr.lockID)}
                        onClick={(e) => {
                            e.stopPropagation()
                            setSelectedIDs((prev: number[]) => {
                                if (prev.includes(tr.lockID)) {
                                    return prev.filter(id => id !== tr.lockID)
                                }
                                return [...prev, tr.lockID]
                            })
                        }} />
            },
            { text: strings.serialCode, key: "serialCode", normalSort: true },
            ...(loggedInUser?.roleID != Role["Restricted Personnel"] ? [{ text: strings.unlockCode, key: "unlockCode" }] : [
                {
                    text: strings.unlockCode,
                    key: "unlockCode",
                    content: (tr: LockModel): any => tr.unlockCode || "****"
                }
            ]),
            { text: strings.updatedBy, key: "updatedBy" },
            { text: strings.updatedOn, key: "updatedOn", date: true },
        ], [loggedInUser?.roleID, selectedIDs]
    )

    const mobileKeys = [
        { key: "unlockCode", text: strings.unlockCode },
        { key: "updatedBy", text: strings.updatedBy, endOfRow: true }];

    const displayTitleAndGoToLocks = () => {
        if (Role.isSiteRole(loggedInUser?.roleID)) return null

        if (mobile) {
            return (
                <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
                    <TableTitle title={strings.inventory} />
                    <SecondaryLink link="/SerialCodes_Locks/SerialCodeListingByCompany" style={{ justifySelf: "start" }}>{strings.goToActiveLocks}</SecondaryLink>

                </Box>
            )
        }
        else {
            return (
                <>
                    <TableTitle title={strings.inventory} />
                    <SecondaryLink link="/SerialCodes_Locks/SerialCodeListingByCompany" style={{ justifySelf: "start" }}>{strings.goToActiveLocks}</SecondaryLink>
                </>
            )
        }
    }

    return (
        <Container>
            <ActionsContainer>
                {displayTitleAndGoToLocks()}

                <SearchField
                    data={inventoryLocks || []}
                    searchKeys={["serialCode"]}
                    searchLabel={strings.serialCode}
                    setFilterData={setFilteredLocks}
                    fullWidth={mobile}
                />
                {!mobile &&
                    <>
                        <Box sx={{ mt: "4px" }}>
                            <PrintTableButton printTableRef={printTableRef} trackClickEvent={trackClickEvent} />
                        </Box>
                        <Box sx={{ mt: "4px" }}>
                            <DownloadExcel data={filteredLocks} inventory trackClickEvent={trackClickEvent} />
                        </Box>
                    </>
                }
            </ActionsContainer>

            <TableWrapper>
                <Table
                    noDataMessage={pending ? <GenericLoader/> : !inventoryLocks.length && "Could not find inventory locks" }
                    tooltip={strings.clickToEdit}
                    canEdit={restrictedPersonnelCannotEdit}
                    data={filteredLocks}
                    headers={headers}
                    ref={printTableRef}
                    rowsPerPage={{ initial: 10, options: [5, 10, 20, 50, 100] }}
                    rowProps={{ onClick: selectRow }}
                    selectedRow={selectedLock}
                    mobileIdentifier="serialCode"
                    mobileSortKey="serialCode"
                    mobileHeaders={mobileKeys}
                >
                    <SubmitContainer>
                        <SelectFacility
                            setValue={setSelectedFacility}
                            facilityID={selectedFacility?.facilityID}
                            geolocate
                            noClear
                        />
                        <PMedium>
                            <b>Selected:</b> {selectedIDs.length}
                        </PMedium>
                        <PrimaryButton disabled={!selectedFacility || !selectedIDs.length} onClick={handleSubmit}>{strings.submit}</PrimaryButton>
                    </SubmitContainer>
                </Table>
            </TableWrapper>
            <BladePopout
                show={!!selectedLock}
                closeBlade={() => { setSelectedLock(null); }}>
                {selectedLock &&
                    <EditInventoryForm
                        trackClickEvent={trackClickEvent}
                        closeBlade={() => { setSelectedLock(null); }}
                        initialValue={selectedLock as ILockModelForm}
                    />
                }
            </BladePopout>

        </Container >
    )
}

const ActionsContainer = styled.div({
    display: "grid",
    width: "100%",
    alignItems: "center",
    gridTemplateColumns: "minmax(135px, 1fr) repeat(4, auto)",
    "@media (max-width: 414px)": {
        gridTemplateColumns: "1fr",
        gap: "4px",
    },
})

const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    padding: "8px",
    boxSizing: "border-box",
})

export default InventoryContainer;

const SubmitContainer = styled.div({
    gap: "16px",
    justifySelf: "flex-end",
    alignSelf: "end",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "end"
})
