import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/useAppSelector";
import { FacilityDto } from "../models/facility";
import {
    getUserFacilities,
    setUserFacilities,
    fetchUserFacilitiesAsync,
    fetchCompanyFacilitiesAsync
} from "../redux/userFacilities/userFacilitiesSlice";
import { useCompanyID } from "./useCompanyID";

export const useFacilities = (a?: any) => {
    const { companyIDRef } = useCompanyID()

    const dispatch = useAppDispatch();
    const { facilities, status, pending } = useAppSelector(getUserFacilities);

    const fetchUserFacilities = useCallback(() => {
        if (companyIDRef.current) {
            dispatch(fetchCompanyFacilitiesAsync(companyIDRef.current))
        } else {
            dispatch(fetchUserFacilitiesAsync())
        }
    }, [companyIDRef.current])

    const setFacilities = useCallback((action: any) => {
        dispatch(setUserFacilities(action))
    }, [])

    const getFacilityByID = useCallback((id?: number) => {
        return facilities.find((f: FacilityDto) => f.facilityID == id);
    }, [facilities])

    return {
        facilities,
        fetchUserFacilities,
        setFacilities,
        pending,
        getFacilityByID,
        fetched: status === "fetched",
    }
}
