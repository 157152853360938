import { useCallback, useEffect, useState } from 'react';

export enum CacheState {
    Waiting,
    HasCache,
    NoCache,
    LoadingCache,
    LoadedCache,
}

const createExpiryUnix = (hours: number) => (new Date(Date.now() + 1000 * 60 * 60 * hours)).getTime();
const isExpired = (unix: number) => unix < Date.now();

type HasExp = {
    exp: number
}

export const useCache = <T>(cacheKey: string, expirationInHours: number = 8) => {
    const [cachedValues, setCachedValues] = useState<T | null>(null);
    const [cacheState, setCacheState] = useState<CacheState>(CacheState.Waiting);

    useEffect(() => {
        const cacheString = localStorage.getItem(cacheKey);
        if (cacheString) {
            const cacheData = JSON.parse(cacheString) as (T & HasExp);
            if (isExpired(cacheData.exp)) {
                clearCache()
                return;
            }
            setCachedValues(JSON.parse(cacheString) as T);
            setCacheState(CacheState.HasCache);
        } else {
            setCacheState(CacheState.NoCache);
        }
    }, []);

    const clearCache = useCallback(() => {
        localStorage.removeItem(cacheKey);
        setCachedValues(null);
        setCacheState(CacheState.NoCache);
    }, [cacheKey]);

    const setCache = useCallback((value: T) => {
        localStorage.setItem(cacheKey, JSON.stringify({
            exp: createExpiryUnix(expirationInHours),
            ...value
        }));
    }, [cacheKey]);

    const getCache = useCallback((): T => {
        setCacheState(CacheState.LoadingCache);
        return cachedValues as T;
    }, [cachedValues]);

    const finishedLoadingCache = () => setCacheState(CacheState.LoadedCache);

    return {
        clearCache,
        setCache,
        getCache,
        cacheState,
        finishedLoadingCache,
    };
};
