
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useProfileSettings } from './hooks';
import { MessagesEnum, MessagesEnumKeys } from "../enums/MessagePreference"

interface IUseCustomMessages {
    defaultMessages: { defaultEmail: any, defaultSms: any } | any,
    inUseProps: any,
    activeTab: number,
    setActiveTab: Dispatch<SetStateAction<number>>
}

export const useCustomMessage = (messageType: MessagesEnumKeys): IUseCustomMessages => {
    const { delinquentEmail, delinquentSms, moveInEmail, moveInSms, textReleaseApprovedSms, textReleaseDeniedSms } = useProfileSettings()
    const [smsInUse, setSmsInUse] = useState(true)
    const [emailInUse, setEmailInUse] = useState(false)
    const [activeTab, setActiveTab] = useState(0)

    const getDefaultMessages = () => {

        switch (MessagesEnum[messageType as MessagesEnumKeys]) {
            case 1:
                return { defaultEmail: moveInEmail, defaultSms: moveInSms }

            case 2:
                return { defaultEmail: delinquentEmail, defaultSms: delinquentSms }

            case 3:
                return { defaultSms: textReleaseApprovedSms }

            case 4:
                return { defaultSms: textReleaseDeniedSms }
        }
    }
    const getInuseProps = () => {
        if (MessagesEnum[messageType as MessagesEnumKeys] < 3) {
            return { smsInUse, setSmsInUse, emailInUse, setEmailInUse }
        }
        return { smsInUse, setSmsInUse }
    }

    const defaultMessages = getDefaultMessages()
    const inUseProps = getInuseProps()

    return {
        defaultMessages,
        inUseProps,
        activeTab,
        setActiveTab
    }
}
