import { Colors } from "../../core/brand/themes";
import links from "../../routes/links"
import login from "../../assets/images/login.png"
import { LoginKey } from "../../components/styled/login-key";
import { strings } from "../../content/strings"
import loginKey from "../../assets/images/key.svg"
import disc from "../../assets/images/disc.png"
import secure from "../../assets/images/how_secure.png"
import phone from "../../assets/images/how_automated.png"
import returnLock from "../../assets/images/how_return.png"
import React from "react";
import { SecondaryLink } from "../../components/button";

export default [
    {
        header: "1. Order DaVinci Locks",
        background: Colors.navy(),
        img: disc,
        linkInDescription: {
            text: "Implement the DaVinci Lock system at your facilities for vacant units, delinquent units, and more! Visit our ",
            link: {
                link: links.STORE.href,
                text: " Store Page"
            },
            remainingText: "and browse our selection of products."
        },
    },
    {
        header: "2. Create Your Account",
        description: "Create a DaVinci Lock account to get started using your locks. You can add additional users to your company dashboard.",
        img: login,
        info: {
            text: "Already have an account?",
            link: {
                text: "Click here to set up your account",
                link: links.CREATECOMPANY.link
            },
            button: <SecondaryLink style={{ margin: 0 }} {...links.LOGIN} > {strings.login} < LoginKey src={loginKey} /> </SecondaryLink>
        }
    },
    {
        header: "3. Assign Locks & Secure Units",
        description: "Once you add facilities and activate your DaVinci Locks, you can begin assigning your locks to delinquent or vacant units. Each lock is engraved with a serial code, made for secure retrieval by tenants.",
        img: secure,
    },
    {
        header: "4. Unlock Automation For Your Customers",
        linkInDescription: {
            sections: [
                "Integrate with your preferred facility management software to allow automatic notification of unlock codes to tenants upon paying their account balance.",
                "*Integration with a facility management software platform is not required to use DaVinci Locks, but recommended. For additional information on supported facility management software platforms visit our  "
            ],
            link: {
                link: links.COMPANYMANAGEMENT.href,
                text: "Help page."
            },
            remainingText: ""
        },
        img: phone,
    },
    {
        header: "5. Tenants Returns Locks",
        description: "Give time back to your facility managers to focus on other operations! Signage displayed within the facility will instruct tenants where to locate dropboxes to return their DaVinci Locks. ",
        img: returnLock,
    }
]
