import React, { useState } from "react";
import agents from "../../api/agent";
import gears from "../../assets/images/gears.png";
import Input from "../../core/form-components/Input";
import { acceptTransferValidator } from "../../validators/validators";
import { FacilityDto } from "../../models/facility";
import { H4, PBold } from "../../core/brand/typography";
import { IAcceptFacilityTransfer } from "../../models/IAcceptFacilityTransfer";
import { LoggedInForm } from "../../core/layout/LoggedInForm";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate";
import { PrimaryButton, QuaternaryButton } from "../../components/button";
import { strings } from "../../content/strings";
import { useForm } from "../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import useAnalytics from "../../hooks/useAnalytics";

const address = (facility: FacilityDto) => (<> {facility.street} <br /> {facility.city}, {facility.state} {facility.zip}</>)

function AcceptTransferPage({ token, facility }: { token: string, facility: FacilityDto }) {
    const initialValues: IAcceptFacilityTransfer = {
        facilityName: facility.facilityName || "",
        facilityURL: "",
        email: "",
        phoneNumber: "",
        phoneNumberCustomerService: "",
        transferJwt: token
    }
    const navigate = useNavigate();
    const { trackClickEvent } = useAnalytics("Accept Transfer Page")
    const [success, setSuccess] = useState<boolean | undefined>(false);
    const [errorMessage, setErrorMessage] = useState<boolean | string>(false);

    const submitAction = async (formValues: IAcceptFacilityTransfer): Promise<any> => {
        trackClickEvent("Accepting Transfer");
        const result = await agents.Facilities.acceptFacilityTransfer(formValues, token);
        if (result?.error) {
            setErrorMessage(result?.error || "Something went wrong!");
        } else {
            setSuccess(result?.success);
        }
    }

    const form = useForm<IAcceptFacilityTransfer>(
        initialValues, submitAction, acceptTransferValidator
    );

    let resultCard: any = null;

    if (errorMessage) {
        resultCard = <H4 tertiary>{errorMessage}</H4>
    } else if (success) {
        resultCard = (
            <>
                <PBold>{strings.facilityTransferApproved}</PBold>
                <br />
                <PrimaryButton fullWidth onClick={() => navigate("/Users/Dashboard")}>{strings.backToDash}</PrimaryButton>
            </>
        )
    }

    const renderContent = () => {
        if (resultCard) {
            return (
                <LoggedInForm.Form>
                    <LoggedInForm.FullSpan center>
                        {resultCard}
                    </LoggedInForm.FullSpan>
                </LoggedInForm.Form>
            )
        }
        return (
            <LoggedInForm.Form>
                <LoggedInForm.Title>
                    {facility.companyName && (<PBold>{facility.companyName} {strings.facilityTransferString}</PBold>)}
                </LoggedInForm.Title>

                <Input autoCompleteBackground="" {...form.inputProps.facilityName} type="text" label="Facility Name*" />
                <LoggedInForm.FullSpan>
                    <PBold left>{facility.street ? address(facility) : " "}</PBold>
                </LoggedInForm.FullSpan>
                <Input autoCompleteBackground="" {...form.inputProps.facilityURL} type="text" label={strings.facilityUrl} />
                <Input autoCompleteBackground="" {...form.inputProps.email} type="email" label={strings.email} />
                <Input autoCompleteBackground="" {...form.inputProps.phoneNumber} type="text" label={strings.phoneNumberRequired} />
                <Input autoCompleteBackground="" {...form.inputProps.phoneNumberCustomerService} type="text" label={strings.customerServicePhone} />
                <LoggedInForm.FullSpan />
                <QuaternaryButton type="reset" onClick={form.clear}>{strings.decline}</QuaternaryButton>
                <PrimaryButton type="submit" onClick={form.handleSubmit}>{strings.accept}</PrimaryButton>
            </LoggedInForm.Form>
        )
    }

    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.transferFacility}
            pageDescription={strings.davincilockPageDescription}
        >
            {renderContent()}
        </LoggedInPageTemplate>
    );
};

export default AcceptTransferPage;
