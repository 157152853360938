import { FormObject, IFieldProps, IForm, useForm } from './useForm';
import * as yup from "yup";
import { useCallback, useEffect, useState } from 'react';
import { CacheState, useCache } from './useCache';

export type StepField<T extends FormObject> = {
    key: (keyof T),
    component?: (props: IFieldProps<T>) => Element | JSX.Element,
    label?: string,
}

type NextFunction<T extends FormObject> = (callback?: ((form: IForm<T>) => Promise<boolean>) | null, numOfSteps?: number) => void

export type RenderProps<T extends FormObject> = {
    form: IForm<T>,
    next: NextFunction<T>,
    back: () => void
}

export type Step<T extends FormObject, T2 = {}> =
    (T2 & {
        fields: StepField<T>[],
        validate?: (form: IForm<T>) => Promise<boolean>,
        render?: (props: RenderProps<T>) => JSX.Element,
    })

type useFormFlowReturn<T extends FormObject, T2 = {}> = {
    currentStep: Step<T, T2>,
    next: NextFunction<T>,
    back: () => void,
    goToProgress: () => void,
    form: IForm<T>,
    activeStep: number,
    setActiveStep: (activeStep: number) => void,
    cacheData: CacheData,
    validationFailed: boolean,
}

type CacheData = {
    startFromCache: (() => void) | null,
    clearCache: () => void,
    loaded: boolean,
}

export default function useFormFlow<T extends FormObject, T2 = {}>(
    initialValues: T,
    submitAction: null | ((arg0: T, arg1: (arg0: T) => any) => any),
    validator: yup.ObjectSchema<any>,
    steps: Step<T, T2>[],
    options: {
        handleErrorCallback?: any,
        initialStep?: number,
        cacheKey?: string,
    } = {}
): useFormFlowReturn<T, T2> {
    const form = useForm<T>(initialValues, submitAction, validator, options.handleErrorCallback)
    const [activeStep, setActiveStep] = useState<number>(0)
    const currentStep = steps[activeStep]
    const [validationFailed, setValidationFailed] = useState<boolean>(false)

    const { getCache, setCache, clearCache, finishedLoadingCache, cacheState } = useCache<T>(options.cacheKey || "cache_key")

    useEffect(() => {
        options.initialStep && setActiveStep(options.initialStep)
    }, [options.initialStep])

    useEffect(() => {
        if (cacheState === CacheState.LoadingCache) {
            goToProgress()
        }
    }, [cacheState])

    useEffect(() => {
        setValidationFailed(false)
    }, [activeStep])

    const next = useCallback(async (
        callback?: ((form: IForm<T>) => Promise<boolean>) | null,
        numOfSteps?: number
    ) => {
        if (await form.validateFields(...currentStep.fields.map(f => f.key))) {
            const validationCallback = callback || currentStep.validate
            if (validationCallback) {
                const cbResult = await validationCallback(form)
                if (!cbResult) {
                    setValidationFailed(true)
                    return;
                }
            }
            setActiveStep(activeStep + (numOfSteps || 1))
            setCache(form.values)
        }
    }, [activeStep, form])

    const startFromCache = useCallback(() => {
        if (cacheState === CacheState.HasCache) {
            form.setFormValues(getCache())
        }
    }, [cacheState])

    const goToProgress = useCallback(async () => {
        let stepIndex = 0;
        while (
            await form.validateFields(...steps[stepIndex].fields.map(f => f.key))
            && stepIndex < steps.length - 1
        ) {
            stepIndex++
        }

        form.clearErrors()

        finishedLoadingCache()
        setActiveStep(stepIndex)
    }, [form])

    const back = useCallback(() => setActiveStep(activeStep - 1), [activeStep])

    return {
        currentStep,
        form,
        next,
        back,
        goToProgress,
        activeStep,
        setActiveStep,
        cacheData: {
            startFromCache:
                (cacheState === CacheState.HasCache || cacheState === CacheState.LoadingCache) ?
                    startFromCache :
                    null,
            clearCache,
            loaded: cacheState !== CacheState.Waiting
        },
        validationFailed
    }
}
