import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { PrimaryButton } from '../../../components/button';
import { IFacilityModelForm } from '../../../models/IFacilityForm';
import { useContext } from 'react';
import { strings } from '../../../content/strings';
import useAnalytics from '../../../hooks/useAnalytics';
import { ToastContext } from '../../../contexts/ToastProvider';
import agents from '../../../api/agent';
import { ToastType } from '../../../enums/ToastType';
import { facilityModelValidator } from '../../../validators/validators';
import { useNavigate } from 'react-router'
import { Divider } from '@mui/material';
import { Colors } from '../../../core/brand/themes';
import { useFacilities } from '../../../hooks/useFacilities';
import { useCompanyID } from '../../../hooks/useCompanyID';
import { FacilityAddressStep } from './FacilityAddressStep';
import { FacilityDetailsStep } from './FacilityDetailsStep';
import { FMSStep } from './FMSStep';
import { IStep, useFormFlow2 } from '../../../hooks/useFormFlow2';
import { ApiActions } from '../../../enums/ApiActions';

const AddUpdateFacility = {
    [ApiActions.ADD]: agents.Facilities.addFacility,
    [ApiActions.EDIT]: agents.Facilities.updateFacility
}

export default function AddEditFacilityForm({ edit, initialValues, closeBlade }: { edit?: boolean, initialValues: any, closeBlade: any }) {
    const { trackClickEvent } = useAnalytics(edit ? "Edit Facility" : "Add Facility")

    /** Grab company ID from query if site level user is adding or editing a facility */
    const { companyID } = useCompanyID();

    const { displayToast } = useContext(ToastContext)
    const { fetchUserFacilities } = useFacilities()

    const submitAction = async (values: IFacilityModelForm) => {
        const addUpdateKey = values.facilityID ? ApiActions.EDIT : ApiActions.ADD;
        try {
            if (!values.companyID && companyID) {
                /** FOR ADMIN SIDE: When addiing a new facility
                 * Adds the companyID for the company being viewed to the facility model. */
                values = { ...values, companyID };
            }

            const result = await AddUpdateFacility[addUpdateKey](values);

            if (result.errors && Object.values(result.errors).length > 0) {
                displayToast({ type: ToastType.Warning, text: Object.values(result.errors)[0] as string })
            } else if (result.warning) {
                displayToast({ type: ToastType.Warning, text: result.result })
                fetchUserFacilities()
                closeBlade()
            } else if (result.success) {
                displayToast({ type: ToastType.Success })
                fetchUserFacilities()
                closeBlade()
            } else {
                displayToast({
                    type: ToastType.Error, text: result.error,
                })
            }

        } catch (e: any) {
            displayToast({ type: ToastType.Error, text: e.message })
        }
    }

    const facilitySteps: IStep<IFacilityModelForm>[] = [
        {
            label: strings.contactDetails,
            ...FacilityDetailsStep,
        },
        {
            label: strings.address,
            ...FacilityAddressStep
        },
        {
            label: strings.facilityMngmtSoft,
            ...FMSStep
        },
    ]

    const { currentStep,
        form, next, back,
        activeStep, setActiveStep,
        furthestProgress } = useFormFlow2<IFacilityModelForm>({
            initialValues,
            submitAction,
            validator: facilityModelValidator,
            steps: facilitySteps,
            loadProgress: true
        })

    const submitButton = () => {
        if (activeStep !== (facilitySteps.length - 1) &&
            (form.values?.lat && !form.values?.dirtyAddress)
        ) {
            return <PrimaryButton type="submit" style={{ marginLeft: "32px" }} onClick={form.handleSubmit} >{strings.submit}</PrimaryButton>
        }

        return null
    }

    return (
        <>
            <Stepper
                activeStep={activeStep} orientation="vertical">
                {facilitySteps.map((step, index) => (
                    <Step key={step.label} completed={furthestProgress > index}>
                        <StepLabel
                            style={{ cursor: furthestProgress >= index ? "pointer" : "default" }}
                            sx={{ mb: 1 }}
                            onClick={() => furthestProgress >= index && setActiveStep(index)}
                        > {step.label}
                        </StepLabel>
                        <StepContent TransitionProps={{ unmountOnExit: false }}>
                            <form>
                                {activeStep === index && (<currentStep.Render
                                    form={form}
                                    next={next}
                                    submit={form.handleSubmit}
                                    back={back}
                                    analytics={trackClickEvent}
                                />)}
                            </form>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>

            {submitButton()}

            <Divider sx={{ border: `1px solid ${Colors.red()}`, mb: 1 }} />

            <TransferFacilityButton facilityID={initialValues.facilityID} />
        </>
    );
}

const TransferFacilityButton = ({ facilityID }: { facilityID: number }) => {
    const navigate = useNavigate();
    const { companyID } = useCompanyID()
    const navigateToTransferFacility = () => {
        const route = `/Company/InitiateFacilityTransfer/${facilityID}`
        navigate(route);
    }

    /** if adding facility or if viewing as a site level user, do not render button */
    if (!facilityID || companyID) {
        return null
    }

    return <PrimaryButton style={{ marginLeft: "32px" }} onClick={navigateToTransferFacility}>{strings.transferTheFacility}</PrimaryButton>

}
