export const Colors = {
    charcoal: () => "#36454F",
    black: () => "#000000",
    white: () => "#FFFFFF",
    navy: () => "#13143C",
    navyHover: () => "#212352",
    red: (opacity: number = 1) => `rgba(195, 37, 41, ${opacity ? opacity : 1})`,
    redHover: () => "#AB2125",
    redText: () => "#811216",
    yellow: () => "#FEE000",
    yellowHover: () => "#F5D800",
    blue: () => "#007bff",
    lightgrey: () => "#757575",
    lightergrey: () => "#ced4da",
    grey: () => "#78909C",
    greyHover: () => "#536d7b",
    tableGrey: () => "#E9EAEC",
    green: () => '#5cb85c',
    infoBlue: () => '#5bc0de',
    warningYellow: () => '#f0ad4e',
    pink: () => '#E6D1D2',
}

export const Styles = {
    shadow: () => ({ boxShadow: `-4px 4px 10px 2px ${Colors.lightergrey()}` })
}

import { createTheme } from "@mui/material";

const inputStyles = {
    styleOverrides: {
        root: {
            borderBottomColor: Colors.blue(),
            ":after": {
                borderBottomColor: Colors.blue(),
            }
        }
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            main: Colors.red(),
            dark: Colors.redHover(),
        },
        secondary: {
            main: Colors.yellow(),
            dark: Colors.yellowHover(),
        },
        error: {
            main: Colors.red()
        }
    },
    typography: {
        fontFamily: "'Exo', sans-serif"
    },
    components: {
        MuiInput: inputStyles,
        MuiInputBase: inputStyles,
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "black",
                    "&.Mui-focused": {
                        color: Colors.blue(),
                        fontWeight: "bold",
                        fontSize: "17px",
                    }
                }
            }
        }
    }
});
