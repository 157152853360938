import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useCompany } from "../../../hooks/useCompanies";
import { Box, Tab, Tabs } from "@mui/material";
import UsersListingContainer from "../../users-listing/components/UsersListingContainer";
import FacilityListingContainer from "../../facility-listing/components/FacilityListingContainer";
import { useCompanyID } from "../../../hooks/useCompanyID";
import SerialCodesTableContainer from "../../serial-codes/components/SerialCodesTableContainer";
import { PrimaryButton } from "../../../components/button";
import { H1 } from "../../../core/brand/typography";
import TabPanel from "./TabPanel"
import EditCompanyDetails from "./EditCompanyDetails";
import { BladePopout } from "../../../core/layout/BladePopout";
import agents from "../../../api/agent";
import { strings } from "../../../content/strings";
import { Colors } from "../../../core/brand/themes";
import { ICompanyDetails } from "../../../models/ICompanyDetails";
import { useMediaQuery } from "../../../hooks/hooks";
import InventoryContainer from "../../inventory/components/InventoryContainer";

export enum TabState {
    Facilities,
    Users,
    Locks,
    Inventory
}

const CompanyListingContainer = () => {
    const { companyID } = useCompanyID()
    const { company, error, fetchCompanyByID } = useCompany(companyID as number)
    const [tab, setTab] = useState<number>(TabState["Facilities"]);
    const [open, setOpen] = useState<boolean>(false);
    const [mobile] = useMediaQuery(414);

    const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    }, []);

    const handleDialog = useCallback(() => {
        setOpen(prevState => !prevState);
    }, [])

    const closeBlade = useCallback(() => {
        setOpen(false)
        fetchCompanyByID()
    }, [fetchCompanyByID])

    const ClearUnitNumbersCache = useCallback(async () => {
        if (companyID) {
            await agents.Facilities.refreshAllUnitNumbers(companyID)
        }
    }, [companyID])

    if (error) {
        return (
            <Container>
                <H1>{error}</H1>
            </Container>
        )
    }

    return (
        <Container>
            {!mobile &&
                <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                    <H1 style={{ marginRight: "8px", marginBottom: "1px" }}>{company?.companyName}</H1>
                    <PrimaryButton onClick={ClearUnitNumbersCache}>{strings.refreshUnits}</PrimaryButton>
                    <PrimaryButton onClick={handleDialog}>{strings.editCompany}</PrimaryButton>
                </Box>
            }

            <BladePopout
                headerText={strings.editCompany}
                show={!!company && open}
                closeBlade={handleDialog}>
                {(!!company && open) &&
                    <EditCompanyDetails close={closeBlade} company={company as ICompanyDetails} />
                }
            </BladePopout>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange} aria-label="company-tabs">
                    <Tab sx={{ fontSize: "20px", color: Colors.charcoal() }} label={strings.facilities} />
                    <Tab sx={{ fontSize: "20px", color: Colors.charcoal() }} label={strings.users} />
                    <Tab sx={{ fontSize: "20px", color: Colors.charcoal() }} label={strings.locks} />
                    <Tab sx={{ fontSize: "20px", color: Colors.charcoal() }} label={"Inventory"} />
                </Tabs>
            </Box>
            <TabPanel
                value={tab}
                index={TabState["Facilities"]}
            >
                <FacilityListingContainer />
            </TabPanel>
            <TabPanel
                value={tab}
                index={TabState["Users"]}
            >
                <UsersListingContainer />
            </TabPanel>
            <TabPanel
                value={tab}
                index={TabState["Locks"]}
            >
                <SerialCodesTableContainer />
            </TabPanel>
            <TabPanel
                value={tab}
                index={TabState["Inventory"]}
            >
                <InventoryContainer />
            </TabPanel>
        </Container >
    )
}

const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    padding: "8px",
    boxSizing: "border-box",
})

export default CompanyListingContainer
