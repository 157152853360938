import * as yup from "yup";
import agents from "../api/agent";
import { strings } from "../content/strings";
import { ICompanyDetails } from "../models/ICompanyDetails";
import { emailField, phoneNumberValidator, zipField } from "./validators";

export const editCompanyValues = (company: ICompanyDetails) => ({
    companyID: company.companyID,
    companyName: company.companyName || "",
    companyURL: company.companyURL || "",
    phoneNumber: company.phoneNumber || "",
    email: company.email || "",
    street: company.street || "",
    city: company.city || "",
    state: company.state || "",
    zip: company.zip || "",
})

export const editCompanyValidator = (initialValues: ICompanyDetails): yup.SchemaOf<ICompanyDetails> => yup.object().shape({
    companyID: yup.number().required(),
    companyName: yup.string().required(strings.required(strings.companyName)),
    companyURL: yup.string().required(strings.required(strings.companyUrl)).test(
        async (value: string | undefined, testContext: yup.TestContext) => {
            if (!value) {
                return testContext.createError({ message: strings.required(strings.companyUrl) });
            }
            if (initialValues.companyURL === value) {
                return true;
            }
            const res = await agents.Company.validateCompanyUrl(value);
            if (!res.success || !res.data) {
                return testContext.createError({ message: res.error });
            }
            return true;
        }),
    email: emailField.test(
        async (value: string | undefined, testContext: yup.TestContext) => {
            if (!value) {
                return testContext.createError({ message: strings.required(strings.email) });
            }
            if (initialValues.email === value) {
                return true;
            }
            const res = await agents.Users.validateUniqueEmail(value);
            if (!res.data) {
                return testContext.createError({ message: strings.validators.uniqueEmail });
            }
            if (!res.success) {
                return testContext.createError({ message: res.error || strings.somethingWentWrong });
            }
            return true;
        }),
    phoneNumber: phoneNumberValidator,
    street: yup.string()
        .min(4, strings.streetValidation)
        .required(strings.required(strings.street)),
    city: yup.string().required(strings.required(strings.city)),
    state: yup.string().required(strings.required(strings.state)),
    zip: zipField,
});
