import { Box, Divider, Grid, FormControlLabel, FormGroup, Switch } from '@mui/material';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Colors } from '../../../core/brand/themes';
import { PBold, PThin } from '../../../core/brand/typography';
import CreateIcon from '@mui/icons-material/Create';
import { useNavigate } from 'react-router';
import { strings } from '../../../content/strings';
import Table from "../../../core/table/Table"
import { Card } from '../../../core/surfaces/Card';
import { SelectOverlockAutoStatus, SelectVacantAutoStatus } from "./SelectStatus"
import { useLoggedInUser } from '../../../hooks/useLoggedInUser';
import { useAutoLockStatusChange } from '../../../hooks/useAutoLockStatusChange';
import agents from '../../../api/agent';
import { useProfileSettings } from '../../../hooks/hooks';
import { MessagePreference } from "../../../enums/MessagePreference"

const AutoMessagesContainer = () => {
    const navigate = useNavigate();
    const { assignedOverLock, assignedVacant, locksPerUnit, updateAssignedOverLock, updateAssignedVacant, updateLocksPerUnit } = useAutoLockStatusChange();
    const { isSubscribed } = useLoggedInUser()
    const { loggedInUser } = useLoggedInUser();
    const { delinquentNotificationPreference,
        moveInNotificationPreference,
        textReleaseNotificationPreference } = useProfileSettings()

    const navigateToCustomMessage = (id: number) => {
        const row = rows[id]
        if (!enableIconAndRoute(id)) return
        navigate(`/Company/CustomMessage/?message=${row.message}/?type=${row.type}`)
    }

    const headers = [
        { text: "Message", key: "message" },
        { text: "Sending With", key: "type" },
        { text: "", key: "icon" },
    ]

    const enableIconAndRoute = (id: number) => {
        return location.host.includes("davincilock-dev") || loggedInUser?.companyID == 2;
    }

    const rowIcon = (id: number) => {
        return (
            <Box onClick={() => navigateToCustomMessage(id)} sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px", cursor: "pointer" }}>
                <CreateIcon color={enableIconAndRoute(id) ? "primary" : "disabled"} />
                <span>{strings.edit.toUpperCase()}</span>
            </Box>
        )
    }

    useEffect(() => {
        const fetchProfileSettings = async () => {
            await agents.Settings.getSettingsProfile();
        }
        fetchProfileSettings();
    }, []);

    const rows = [
        { message: "Delinquent Bill", type: MessagePreference[delinquentNotificationPreference], icon: rowIcon(0) },
        { message: "New Move In", type: MessagePreference[moveInNotificationPreference], icon: rowIcon(1) },
        { message: "Text to Release Approved", type: MessagePreference[textReleaseNotificationPreference], icon: rowIcon(2) },
        { message: "Text to Release Denied", type: MessagePreference[textReleaseNotificationPreference], icon: rowIcon(3) },
    ]

    return (
        <Grid container gap="12px" alignItems={"start"} justifyContent="space-between">
            <Grid item sm={12} md={5.7} >
                <CardHeader>
                    <PBold secondary>
                        {strings.autoMessages}
                    </PBold>
                </CardHeader>
                <Card style={{ minHeight: "600px", padding: "32px 8px", boxSizing: "border-box", overflow: "hidden" }}>
                    <Table
                        headers={headers}
                        data={rows}
                        hidePagination
                        filter={false}
                        hideMobileCards
                    >
                    </Table>
                </Card>
            </Grid>
            <Grid item sm={12} md={5.7}>
                <CardHeader>
                    <PBold secondary>
                        {strings.autoLock}
                    </PBold>
                </CardHeader>
                <Card style={{ position: "relative", padding: "0px 8px", boxSizing: "border-box", minHeight: "600px" }}>
                    <Grid container item xs={12} direction="column" justifyContent={"center"} alignItems={"center"} gap="4px" >
                        <UpdateLockStatus header={strings.vacantUnits} helperText={strings.autoLockVacantContent} >
                            <SelectVacantAutoStatus status={assignedVacant} setStatus={updateAssignedVacant} />
                        </UpdateLockStatus>
                        <UpdateLockStatus header={strings.overlockUnits} helperText={strings.autoLockOverlockContent}>
                            <SelectOverlockAutoStatus status={assignedOverLock} setStatus={updateAssignedOverLock} />
                        </UpdateLockStatus>
                        <Grid item xs={12}>
                            <FormGroup sx={{ mt: "16px", mb: "16px" }}>
                                <FormControlLabel control={<Switch checked={locksPerUnit === 1} onChange={() => updateLocksPerUnit()} />}
                                    label={
                                        <PBold style={{ marginTop: "8px", marginLeft: "22px" }}>{strings.restrictOneLockperUnit}</PBold>
                                    } />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}

const UpdateLockStatus = ({ header, helperText, children }: { header: string, helperText: string, children: any }) => {
    return (
        <>
            <Grid item sx={{ my: "16px" }}>
                <Box sx={{ width: { sm: "65%", xs: "100%", }, margin: "auto" }}>
                    <h4>{header}</h4>
                    <PThin style={{ display: "inline" }}>{helperText}</PThin>
                </Box>
            </Grid>
            <Grid item >
                {children}
            </Grid>
            <Divider orientation='horizontal' sx={{ background: Colors.red(), width: "70%", height: "1px", overflow: "visible", mt: "32px" }} />
        </>
    )
}

const CardHeader = styled(Box)({
    width: "100%",
    height: "44px",
    background: Colors.red(),
    boxSizing: "border-box",
    paddingLeft: "12px",
    paddingTop: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "start"
});

export default AutoMessagesContainer;
