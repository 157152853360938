import styled from "styled-components"
import { highlight } from 'prismjs';
import React, { useLayoutEffect } from 'react';
import Editor from 'react-simple-code-editor';
import { PBold } from "../../../core/brand/typography";
import { messageLimit } from "../../../enums/MessagesTemplateValue"

interface ITemplateEditor {
    value: string,
    onChange?: (e: any) => void,
    preview?: boolean,
    error?: string,
    disabled?: boolean,
    count?: number,
    inputRef?: any,
    type?: string
}
export const TemplateEditor = ({ value, onChange, preview, count, disabled = false, inputRef, type, ...props }: ITemplateEditor) => {
    useLayoutEffect(() => {
        if (!preview) {
            const el = document.getElementById("template-editor")
            if (el) {
                inputRef.current = el
            }
        }
    }, [])

    return (
        <>
            <EditorWrapper preview={preview}>
                <Editor
                    {...props}
                    textareaId="template-editor"
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    onValueChange={value => {/** noop */ }}
                    highlight={value => highlight(value, {
                        keyword: {
                            pattern: preview ? /\s\u200B.*\u200B/gmi : /\{.+\}/gmi,
                            alias: 'keyword'
                        },
                    }, "custom")}
                    padding={12}
                />
            </EditorWrapper>
            {!preview && <PBold style={{ margin: "8px 0px" }} left>{`${count} out of ${messageLimit[type as keyof typeof messageLimit]}`}</PBold>}
            <PBold style={{ marginTop: "4px" }} left tertiary>{(props?.error) && props?.error || <>&nbsp;</>}</PBold>
        </>
    )
}

export default TemplateEditor

const EditorWrapper = styled.div<{ preview?: boolean }>(({ preview }) => ({
    fontSize: "16px",
    height: "auto",
    maxHeight: "auto",
    overflowY: "scroll",
    color: "#444444",
    fontWeight: "normal",
    minWidth: "316px",
    "@media(max-width:1035px)": {
        minWidth: "260px",
    },
    "@media(max-width:967px)": {
        minWidth: "220px",
    },
    "::-webkit-scrollbar": {
        width: "5px",
    },
    "::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "5px",
    },
    "::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#888"
    },
    [".token.keyword"]: {
        color: "black",
        "-webkit-text-stroke-width": "0.25px",
    },
    "div": {
        overflow: "visible",
    },
    ...(preview ? {
        // style preview here
        letterSpacing: "1.8px",
        lineHeight: "26px",
        width: "100%",
    } : {
        // style text editor here
        ["div"]: {
            overflow: "visible",
            height: "100%",
            "textarea": {
                border: "1px solid #999 !important",
                borderRadius: "5px",
                height: "100%",
                width: "100%",
                ["&:focus"]: {
                    border: "2px solid navy !important"
                },
            },
            "pre": {
                border: "2px solid transparent !important",
                height: "100%",
                width: "100%",
            },
        },
    })
}))
