import { Box, FormControl, MenuItem, Select } from "@mui/material";
import styled from 'styled-components';
import { Colors } from "../brand/themes";

interface CustomSelectProps {
    values: any,
    label?: string,
    handleChange: any,
    value: any,
    variant?: 'standard' | 'outlined' | 'filled'
}

const CustomSelect = ({ values, label, handleChange, value, variant }: CustomSelectProps) => {
    return (
        <Box sx={{ minWidth: 260 }}>
            <FormControl fullWidth >
                <StyledSelect
                    value={value}
                    label={label}
                    onChange={handleChange}
                    variant={variant || "standard"}
                >
                    {values.map((status: string) => {
                        return (
                            <MenuItem value={status}>{status}</MenuItem>
                        )
                    })}
                </StyledSelect>
            </FormControl>
        </Box>
    )
}

const StyledSelect = styled(Select)({
    ".MuiSelect-icon": {
        color: Colors.red(),
        fontSize: "34px",
    }
})

export default CustomSelect
