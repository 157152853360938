import { Box } from '@mui/material';
import React, { useState } from 'react';
import agents from '../../../api/agent';
import { Card } from '../../../core/surfaces/Card';
import ListCard from "../../../core/surfaces/ListCard"
import useAnalytics from '../../../hooks/useAnalytics';
import ImportFacilities from "../components/ImportFacilities"
import { events as analyticsEvents } from "../../../mixPanelEvents"
import { useLoggedInUser } from '../../../hooks/useLoggedInUser';
import { useSubdomains, useFacilities, useUsers } from '../../../hooks/hooks';
import { useCompany } from '../../../hooks/useCompanies';
import { ManagementSoftware } from '../../../enums/ManagementSoftware';
import { useEffect } from 'react';

const IntegrationsContainer = () => {
    const { subdomains } = useSubdomains();
    const { users } = useUsers();
    const { facilities } = useFacilities();
    const { loggedInUser } = useLoggedInUser()
    const { company } = useCompany(loggedInUser?.companyID!);
    const [defaultFMS, setDefaultFMS] = useState(ManagementSoftware['Not listed'])

    useEffect(() => {
        const getDefaultFMS = async () => {
            var result = await agents.Company.getCompanyDefaultFMS();
            setDefaultFMS(result.data || 0)
        }
        getDefaultFMS()
    }, [])

    var infoCount = {
        "Subdomains": subdomains?.length,
        "Facilities": facilities.length,
        "Users": users.length,
        "Management Software": defaultFMS ? ManagementSoftware[defaultFMS] : ""
    }

    const { trackClickEvent } = useAnalytics("Integrations", false)

    const refreshUnitHandler = async () => {
        trackClickEvent(analyticsEvents.refreshUnitNumbers)
        await agents.Facilities.refreshAllUnitNumbers(loggedInUser?.companyID!);

    }
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px", justifyContent: "space-around", mx: "8px" }}>
            <Card style={{ flexBasis: "15.5%", display: "flex", padding: "8px 4px", boxSizing: "border-box", marginTop: "42px", minHeight: "162px" }}>
                <ListCard data={infoCount} clickHandler={refreshUnitHandler} />
            </Card>
            <Card style={{ flexBasis: "65%", display: "flex", flexDirection: "column" }} >
                <ImportFacilities />
            </Card>
        </Box>
    );
}

export default IntegrationsContainer;
