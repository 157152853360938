import React, { useContext, useState } from "react"
import agents from "../../../api/agent";
import SearchField from "../../../core/form-components/SearchField";
import styled from 'styled-components'
import Switch from "@mui/material/Switch";
import Table from "../../../core/table/Table"
import { Card } from "../../../core/surfaces/Card"
import { FacilityDto } from "../../../models/facility";
import { PrimaryButton, QuaternaryButton } from "../../../components/button"
import { Dialog } from "@mui/material";
import { H1, H3Bold, PMedium } from "../../../core/brand/typography";
import { Form } from "../../../core/form-components/Form";
import { strings } from "../../../content/strings";
import useAnalytics from "../../../hooks/useAnalytics";
import { useFacilities } from "../../../hooks/useFacilities";
import TableWrapper from "../../../core/table/TableWrapper";
import { useMediaQuery } from "../../../hooks/hooks";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";

// I need the toggle in thie mobile thing yo!
const ApiFacilityListing = ({ writePermissions }: { writePermissions: boolean }) => {
    const { trackClickEvent } = useAnalytics("API Settings- Facility Listing", false)
    const { facilities } = useFacilities();
    const [mobile] = useMediaQuery(414);
    const { displayToast } = useContext(ToastContext);

    const [filteredFacilities, setFilteredFacilities] = useState<FacilityDto[]>([]);
    const [selectedFacility, setSelectedFacility] = useState<FacilityDto | null>(null)

    const facilitiesHeaders: { text: string, key?: string, date?: boolean, content?: (tr: FacilityDto) => any }[] =
        [
            { text: strings.facilityName, key: "facilityName" },
            { text: strings.facilityID, key: "apiFacilityID" },
            {
                text: strings.includeInApi,
                key: "includedInAPI",
                content: (tr: FacilityDto): any =>
                    <Switch
                        checked={!!(tr?.includedInAPI)}
                        onChange={() => setSelectedFacility(tr)} />
            },
        ];

    const rowsPerPage = { initial: 10, options: [5, 10, 20] }

    const facilitiesKeys = [
        {
            text: strings.includeInApi,
            endOfRow: true,
            content: (tr: FacilityDto): any =>
                <Switch
                    sx={{ mt: "-8px" }}
                    checked={!!(tr?.includedInAPI)}
                    onChange={() => setSelectedFacility(tr)} />
        },
    ];

    const toggleExcludeFacility = async () => {
        if (!writePermissions) {
            displayToast({ type: ToastType.Info, text: strings.insufficientPermissions })
            return;
        }
        trackClickEvent("Toggle Exclude Facility")
        if (selectedFacility && selectedFacility.facilityID) {
            await agents.Company.toggleExcludeFacility(selectedFacility.facilityID)
        }
        setSelectedFacility(null);
    }

    return (
        <>
            <ActionsContainer>
                <SearchField
                    data={facilities}
                    searchKeys={["facilityName", "apiFacilityID"]}
                    searchLabel={strings.facilityNameOrId}
                    setFilterData={setFilteredFacilities}
                    fullWidth={mobile}
                />
            </ActionsContainer>

            <TableWrapper>
                <Table
                    data={filteredFacilities}
                    headers={facilitiesHeaders}
                    rowsPerPage={rowsPerPage}
                    mobileIdentifier="facilityName"
                    mobileHeaders={facilitiesKeys}
                />
            </TableWrapper>
            <Dialog onClose={() => setSelectedFacility(null)} open={!!selectedFacility}>
                <Form>
                    <H3Bold>{strings.selectedFacility(!!selectedFacility?.includedInAPI)}</H3Bold>
                    <PMedium>{strings.areYouSure}</PMedium>
                    <div style={{ display: "flex", paddingBottom: "12px" }}>
                        <PrimaryButton onClick={toggleExcludeFacility}>{strings.yes}</PrimaryButton>
                        <QuaternaryButton onClick={() => setSelectedFacility(null)}>{strings.no}</QuaternaryButton>
                    </div>
                </Form>
            </Dialog>
        </>
    );
}

const ActionsContainer = styled.div({
    display: "flex",
    justifyContent: "end",
    width: "100%",
    "@media (max-width: 414px)": {
        flexDirection: "column",
        gap: "4px",
    },
})

export default ApiFacilityListing;
