import React, { useCallback, useState } from "react";
import { Checkbox, Box, Dialog } from '@mui/material';
import { termsOfUse } from "../../../content/termsOfUse";
import CMSDocumentRenderer from "./CMSDocumentRenderer";
import { TermsView, TermsLink } from "./TermsOfUseContainer";
import { strings } from "../../../content/strings";
import { privacyPolicy } from "../../../content/privacyPolicy";

export const TermsOfUseAndPrivacyPolicy = ({ accepted, setAccepted }: any) => {
    const [viewing, setViewing] = useState(TermsView.Default);

    const handleBack = useCallback(() => setViewing(TermsView.Default), []);

    return (
        <>
            <Dialog open={viewing === TermsView.TermsOfUse}
                onClose={handleBack}
                PaperProps={{
                    style: {
                        minWidth: "80vw",
                        padding: "8px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: 'start',
                        gap: "16px",
                        width: "80%",
                        height: "650px",
                        maxHeight: "100vh",
                    }
                }}>
                <CMSDocumentRenderer cmsDocument={termsOfUse} handleBack={handleBack} />
            </Dialog>
            <Dialog open={viewing === TermsView.PrivacyPolicy}
                onClose={handleBack}
                PaperProps={{
                    style: {
                        minWidth: "80vw",
                        padding: "8px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: 'start',
                        gap: "16px",
                        width: "80%",
                        height: "650px",
                        maxHeight: "100vh",
                    }
                }}>
                <CMSDocumentRenderer cmsDocument={privacyPolicy} handleBack={handleBack} />
            </Dialog>
            <Box sx={{
                display: "flex"
            }}>
                <Checkbox checked={accepted} onChange={() => setAccepted(!accepted)} />
                <p style={{
                    textAlign: "center",
                    fontSize: "400",
                    fontWeight: 700
                }}>
                    {strings.agreeToTerms}
                    {" "}
                    <TermsLink
                        style={{ textDecoration: "underline" }}
                        onClick={() => setViewing(TermsView.TermsOfUse)}
                    >
                        {strings.termsOfUse}
                    </TermsLink>
                    {" "}
                    {strings.and}
                    {" "}
                    <TermsLink style={{ textDecoration: "underline" }} onClick={() => setViewing(TermsView.PrivacyPolicy)}>
                        {strings.privacyPolicy}
                    </TermsLink>
                </p>
            </Box>
        </>
    );
};
