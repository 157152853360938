import { Drawer as MuiDrawer, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { strings } from "../../content/strings";
import { Colors } from "../brand/themes";
import { ErrorBoundary } from "../../utils/errorHandling";
import ExceptionPage, { ExceptionForm } from "../error-fallbacks/ErrorPage";

interface BladePopoutProps {
    closeBlade: () => void,
    show?: boolean,
    headerText?: string,
    children: any
}

export function BladePopout({ closeBlade, show, headerText, children }: BladePopoutProps) {
    const [canClose, setCanClose] = useState(false)
    useEffect(() => {
        if (show) {
            setCanClose(false)
            setTimeout(() => {
                setCanClose(true)
            }, 500)
        }
    }, [show])
    return (
        <Drawer
            anchor="right"
            open={show}
            onClose={() => canClose && closeBlade()}
            transitionDuration={700}
            PaperProps={{
                sx: {
                    height: "calc(100% - 28px)"
                }
            }}
        >
            <ErrorBoundary
                fallback={<ExceptionForm />}>
                <LocksDataHeader>
                    <span>{headerText || strings.edit}</span>
                    <CloseBladeButton onClick={closeBlade}>X</CloseBladeButton>
                </LocksDataHeader>
                <FormWrapper>
                    <FormContainer>
                        {show && children}
                    </FormContainer>
                </FormWrapper>
            </ErrorBoundary>
        </Drawer>
    );
}

const Drawer = styled(MuiDrawer)({
    width: "450px",
    ".MuiDrawer-paperAnchorRight": {
        top: "28px",
        '@media(max-width: 865px)': {
            top: "55px",
            maxHeight: "calc(100vh - 55px)",
            height: "calc(100vh - 55px)",
        },
        '@media(max-width: 500px)': {
            width: "100vw",
            top: "0",
            maxHeight: "100vh",
            height: "100vh",
        },
    }
});

const FormWrapper = styled("div")({
    width: "450px",
    boxSizing: "border-box",
    padding: "24px 16px 36px 16px",
    top: "28px",
    overflowY: "scroll",
    zIndex: 5,
    "::-webkit-scrollbar": {
        height: "0px",
        width: "0px",
    },
    '@media(max-width: 865px)': {
        top: "55px",
        maxHeight: "calc(100vh - 55px)",
        height: "calc(100vh - 55px)",
    },
    '@media(max-width: 500px)': {
        width: "100vw",
        top: "55px",
        maxHeight: "calc(100vh - 55px)",
        height: "calc(100vh - 55px)",
    },
})

const CloseBladeButton = styled("span")({
    cursor: "pointer",
})

const LocksDataHeader = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    backgroundColor: Colors.red(),
    color: Colors.white(),
})

const FormContainer = styled("div")({
    display: "grid",
    width: "100%",
    gridRowGap: "8px",
    input: {
        fontSize: "1.25rem",
        paddingBottom: "4px",
        borderBottom: "1px solid"
    },
    h4: {
        textAlign: "left",
    },
})
