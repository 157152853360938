import React from "react";
import SectionImgDescription from "./components/SectionImgDescription"
import { PrimaryLink } from "../../components/button"
import links from "../../routes/links"
import { strings } from "../../content/strings"
import gears from "../../assets/images/gears.png";
import { PageTemplate } from "../../core/layout/PageTemplate"
import _data from "../../pages/how-it-works/content"
import { H2, H3Bold, H3Thin, PBold } from "../../core/brand/typography"
import { Colors } from "../../core/brand/themes";
import { Grid } from "@mui/material";

const info = [
    <Grid item xs={12}>
        <H3Bold style={{ color: Colors.white(), marginTop: "8px" }}>{strings.help}</H3Bold>
    </Grid>,
    <Grid item xs={12}>
        <PBold style={{ color: Colors.white() }}>{strings.supportPage}</PBold>
    </Grid>,
    <Grid item xs={12} sm={3} md={2}>
        <PrimaryLink {...links.SUPPORT}>{strings.support}</PrimaryLink>
    </Grid>,
    <Grid item xs={12} >
        <H3Bold style={{ margin: "0", color: Colors.white() }}>{strings.ready}</H3Bold>
    </Grid>,
    <Grid item xs={12} sm={3} md={2} sx={{ pb: "12px" }}>
        <PrimaryLink {...links.LEADFORM}>{strings.learnMore}</PrimaryLink>
    </Grid>
]

const HowItoWorksPage = () => {
    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={strings.howItWorksPageTitle}
            pageDescription={strings.howItWorksPageDescription}
            pageCanonical={strings.howItWorksPageCanonical}
            center
        >
            <Grid container spacing={2} direction="column">
                <Grid item container spacing={2} direction="column" xs={12}>
                    <H2 >{strings.contactless}</H2>
                    <H3Thin style={{ marginTop: "48px" }}>{strings.HIWTwo}</H3Thin>
                </Grid>
                {_data.map((item: any, idx: number) => (
                    <SectionImgDescription header={item.header}
                        description={item.description}
                        linkedDescription={item?.linkInDescription}
                        img={item.img}
                        bg={idx}
                        info={item.info}
                    />
                ))}
                <Grid item container xs={12} justifyContent="center" sx={{ background: Colors.navy() }}>
                    {info.map((item, idx) => item
                    )}
                </Grid>
            </Grid>
        </PageTemplate>
    )
}

export default HowItoWorksPage
