import React from "react";
import gears from "../../assets/images/gears.png";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import { strings } from "../../content/strings";
import DashboardNavigationTiles from "./components/dashboardNavigationTiles";

function DashboardPage() {
    const { loggedInUser, loading } = useLoggedInUser();

    if (loading || !loggedInUser?.roleID) return null;

    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.dashboard}
            pageDescription={strings.howItWorksPageDescription}
            pageCanonical={"https://www.davincilock.com/Users/Dashboard"}
        >
            <DashboardNavigationTiles loggedInUser={loggedInUser} />
        </LoggedInPageTemplate >
    )
}

export default DashboardPage
