import React, { useState, createContext } from 'react';
import { Box, Dialog } from "@mui/material";
import { H3Bold } from "../core/brand/typography"
import { strings } from '../content/strings';

interface ISubscription {
    open?: boolean,
    handleClose?: () => void,
    handleOpen?: () => void,
    renderDialog?: () => JSX.Element | null
}

export const SubscriptionContext = createContext<ISubscription>({
    open: false,
    handleClose: () => { },
    handleOpen: () => { },
    renderDialog: () => null
})

const SubscriptionProvider = ({ children }: { children: any }) => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const renderDialog = () => {
        if (open) {
            return (
                <Dialog open={open} onClose={handleClose} className="momos" >
                    <Box sx={{ width: "250px", height: "125px", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                        <H3Bold>{strings.comingSoon}</H3Bold>
                    </Box>
                </Dialog>
            )
        }
        return null
    }

    return (
        <SubscriptionContext.Provider
            value={{
                open: open,
                handleOpen: handleClickOpen,
                handleClose: handleClose,
                renderDialog: renderDialog
            }}>
            {children}
        </SubscriptionContext.Provider>

    )
}
export default SubscriptionProvider;
