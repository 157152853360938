import Role from "../../enums/Role";
import { ILoggedInUser } from "../../models/ILoggedInUser";
import { fetchLocksAsync, fetchUpdatedLocksAsync, loadLocksAsync } from "../locks/lockSlice";
import { fetchCompaniesAsync } from "../companies/companiesSlice";
import { fetchUsersAsync } from '../companyUsers/companyUsersSlice';
import { fetchUserFacilitiesAsync } from "../userFacilities/userFacilitiesSlice";
import { fetchCustomRolesAsync } from "../custom-roles/customRolesSlice";
import Permission from "../../enums/Permission";
import { IPermission } from "../../models/IPermission";
import { fetchUpdatedLoggedInUserAsync } from "./authSlice";
import { cancelTokenSources } from "../../api/agent";

/** 
 * fetches all of the redux store state needed for the user's role.
 */
export const loadUserStore = async (
    nextState: ILoggedInUser | null | undefined,
    prevState: ILoggedInUser | null | undefined,
    dispatch: any,
    fetchUpdateAfter?: boolean
) => {
    const tasks = [];
    let aborted = false;
    const controller = {
        abort: () => aborted = true
    }
    cancelTokenSources.push(controller)

    if (!nextState?.userID || !nextState?.roleID) {
        return; // noop
    }

    /** Only some user roles fetch the users table */
    if (usersAccess().includes(nextState.roleID)
        || hasPermission(Permission.Users, nextState.permissions)) {
        tasks.push(dispatch(fetchUsersAsync()));
    }

    /** Only some user roles fetch the facilities table */
    if (facilitiesAccess().includes(nextState.roleID)
        || hasPermission(Permission.Facilities, nextState.permissions)
    ) {
        tasks.push(dispatch(fetchUserFacilitiesAsync()));
    }

    /** Only site level users fetch list of companies */
    if (Role.isSiteRole(nextState.roleID)) {
        tasks.push(dispatch(fetchCompaniesAsync()));
    }

    if (rolesAccess().includes(nextState.roleID)) {
        tasks.push(dispatch(fetchCustomRolesAsync()))
    }

    /** Site level users don't belon to a company, therefore they don't have locks */
    if (!Role.isSiteRole(nextState.roleID)) {
        if (nextState.roleID < 100
            || hasPermission(Permission.Locks, nextState.permissions)
        ) {
            /** Locks locks from cache */
            !aborted && await dispatch(loadLocksAsync(nextState?.userID, dispatch));
            /** Fetch locks updated since cache */
            !aborted && await dispatch(fetchUpdatedLocksAsync({inventoryLocks: false }));
            /** Fetch entire table of locks just in case something went wrong with the update
             */
            !aborted && await dispatch(fetchLocksAsync({ inventoryLocks: false }));
        }
    }

    if (fetchUpdateAfter) {
        // Wait for all previous fetches are finished before checking for user update
        await Promise.all(tasks)
        !aborted && dispatch(fetchUpdatedLoggedInUserAsync(nextState))
    }

    /** Clean up abort controller */
    const index = cancelTokenSources.findIndex(c => c === controller);
    if (index !== -1) {
        cancelTokenSources.splice(index, 1);
    }
};

const hasPermission = (permissionID: Permission, permissions?: { [key: number]: IPermission } | null) =>
    permissions && permissions[permissionID]?.accessLevel > 0

/** DaVinci users is fetched in CompanyDetailsMiddleware
 * There is probably a better design where the loading of this
 * state is not in 2 different places.
 */
const usersAccess = () => [
    Role["Owner"],
    Role.Admin,
    Role.Personnel,
];

const facilitiesAccess = () => [
    Role["Owner"],
    Role.Admin,
    Role.Personnel,
    Role["Restricted Personnel"],
    Role["Vendor"],
    Role["Call Center Agent"],
];

const rolesAccess = () => [
    Role["Owner"],
    Role.Admin,
];
