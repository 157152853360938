import { useCallback, useContext } from "react";
import { ICompanyDetails } from "../models/ICompanyDetails";
import agents from "../api/agent";
import { ToastContext } from "../contexts/ToastProvider";
import { ToastType } from "../enums/ToastType";

export const useUpdateCompany = () => {
    const { displayToast } = useContext(ToastContext);

    const updateCompany = useCallback(async (company: ICompanyDetails) => {
        const response = await agents.Company.updateCompany(company);

        if (response.success) {
            displayToast({ type: ToastType.Success });
            return true;
        }
        displayToast({ type: ToastType.Error, text: response.error });
        return false;
    }, []);

    return updateCompany;
};
