import React from "react";
import { IFacilityModelForm } from "../../../models/IFacilityForm";
import AddEditFacilityForm from "./AddEditFacilityForm";

const FacilityFormContainer = ({
    closeBlade,
    initialValues,
    edit,
}:
    {
        initialValues: IFacilityModelForm,
        closeBlade: () => void,
        edit?: boolean,
    }) => {
    return <AddEditFacilityForm
        edit={edit}
        initialValues={initialValues}
        closeBlade={closeBlade} />
}

export default FacilityFormContainer;
