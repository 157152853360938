import React, { useCallback, useEffect, useMemo, useState } from "react"
import styled from 'styled-components'
import Table from "../../../core/table/Table"
import { PrimaryButton } from "../../../components/button"
import { blankFacilityForm, FacilityDto } from "../../../models/facility";
import { BladePopout } from "../../../core/layout/BladePopout";
import FacilityFormContainer from "../../../pages/facility-listing/components/FacilityFormContainer";
import { IFacilityModelForm } from "../../../models/IFacilityForm";
import SearchField from "../../../core/form-components/SearchField";
import { strings } from "../../../content/strings";
import useAnalytics from "../../../hooks/useAnalytics";
import { events as analyticsEvents } from "../../../mixPanelEvents";
import { useFacilities } from "../../../hooks/useFacilities";
import TableWrapper from "../../../core/table/TableWrapper"
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import TableTitle from "../../../core/table/TableTitle";
import { Colors } from "../../../core/brand/themes";
import GpsOffIcon from '@mui/icons-material/GpsOff';
import { Box, Tooltip } from "@mui/material";
import Permission from "../../../enums/Permission";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import agents from '../../../api/agent';
import { useCompanyID } from "../../../hooks/useCompanyID";

const DisplayGPSWarning = (tr: any) => {
    return (
        <Box display="flex" justifyContent={"start"} alignItems="center" gap="8px" >
            <span>
                {tr.facilityName}
            </span>
            <Tooltip title={strings.noGpsInfo} arrow>
                <GpsOffIcon sx={{ color: Colors.red(), textAlign: "center", fontSize: "16px", mt: "-3px" }} fontSize="small" />
            </Tooltip>
        </Box>
    )
}

export const headers: { text: string, key: string, date?: boolean, content?: any, tooltip?: any, default?: boolean }[] =
    [
        { text: strings.facilityName, key: "facilityName", content: (tr: any) => tr.lat ? tr.facilityName : DisplayGPSWarning(tr), default: true },
        { text: strings.address, key: "street" },
        { text: strings.city, key: "city" },
        { text: strings.state, key: "state" },
    ];

const mobileKeys = [
    { text: strings.address, key: "street" },
    { text: strings.city, key: "city" },
    { text: strings.state, key: "state", endOfRow: true },
]

const FacilityListingContainer = () => {
    const { trackClickEvent } = useAnalytics("Facilities")
    const { facilities, pending } = useFacilities();
    const [mobile] = useMediaQuery(414);
    const { loggedInUser } = useLoggedInUser();
    const { companyID } = useCompanyID()

    const [filteredFacilities, setFilteredFacilities] = useState<FacilityDto[]>([]);
    const [addFacility, setAddFacility] = React.useState(false);
    const [selectedFacility, setSelectedFacility] = useState<FacilityDto | null>();
    const [defaultFMS, setDefaultFMS] = useState(0)

    const writePermissions = Permission.write(Permission.Facilities, loggedInUser)

    const selectRow = useCallback(async (facility: FacilityDto) => {
        await trackClickEvent(analyticsEvents.showEditFacilityForm, { id: facility.facilityID })
        setSelectedFacility(facility);
    }, [trackClickEvent, setSelectedFacility])

    const handleOpenAddBlade = async () => {
        await trackClickEvent(analyticsEvents.showAddFacilityForm)
        setAddFacility(true);
    }

    useEffect(() => {
        const getDefaultFMS = async () => {
            var result = await agents.Company.getCompanyDefaultFMS(companyID);
            setDefaultFMS(result.data || 0)
        }
        getDefaultFMS()
    }, [])

    const handleCloseBlade = () => {
        trackClickEvent(analyticsEvents.closeForm)
        if (addFacility) setAddFacility(false)
        if (selectedFacility) setSelectedFacility(null)
    }

    useEffect(() => {
        if (selectedFacility) {
            const updateFacility = facilities.find(f => f.facilityID == selectedFacility.facilityID)
            if (JSON.stringify(updateFacility) !== JSON.stringify(selectedFacility)) {
                setSelectedFacility(updateFacility)
            }
        }
    }, [facilities]);

    const rowsPerPage = { initial: 10, options: [5, 10, 20] }
    const rowsProps = useMemo(() =>
        writePermissions ? { onClick: selectRow } : null,
        [writePermissions, selectRow]
    )

    const mobileButtonStyles = mobile ? { width: "95%", marginTop: "-4px", marginBottom: "8px" } : {}

    const blankForm = blankFacilityForm();
    blankForm.managementSoftware = defaultFMS;

    return (
        <>
            <Container>
                <ActionsContainer>
                    <TableTitle title={strings.facilities} />
                    <SearchField
                        data={facilities}
                        searchKeys={["facilityName", "phoneNumber"]}
                        searchLabel={strings.facilityNameOrNumber}
                        setFilterData={setFilteredFacilities}
                        fullWidth={mobile}
                    />
                    {writePermissions &&
                        <PrimaryButton style={mobileButtonStyles} onClick={handleOpenAddBlade}>{strings.addFacility}</PrimaryButton>
                    }
                </ActionsContainer>

                <TableWrapper>
                    <Table
                        //TODO: let's talk about this message
                        noDataMessage={(!filteredFacilities.length && pending) ? "Loading..." : "Could not find any facilities."}
                        tooltip={strings.tableTooltip}
                        data={filteredFacilities}
                        headers={headers}
                        rowsPerPage={rowsPerPage}
                        rowProps={rowsProps}
                        selectedRow={selectedFacility}
                        mobileIdentifier="facilityName"
                        mobileHeaders={mobileKeys}
                    />
                </TableWrapper>

                <BladePopout
                    headerText={addFacility ? strings.addFacility : strings.editFacility(selectedFacility?.facilityName || "")}
                    show={!!selectedFacility || addFacility}
                    closeBlade={handleCloseBlade}>
                    {(selectedFacility || addFacility) &&
                        <FacilityFormContainer
                            closeBlade={handleCloseBlade}
                            edit={!addFacility}
                            initialValues={(!!selectedFacility ? selectedFacility! : blankForm) as IFacilityModelForm}
                        />
                    }
                </BladePopout>
            </Container >
        </>
    )
}

export const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    padding: "8px",
    boxSizing: "border-box",
})

const ActionsContainer = styled.div({
    display: "grid",
    width: "100%",
    gridTemplateColumns: "minmax(135px, 1fr) repeat(4, auto)",
    alignItems: "center",
    "@media (max-width: 414px)": {
        gridTemplateColumns: "1fr",
        gap: "4px",
    },
})

export default FacilityListingContainer;
