import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from "../../hooks/useMediaQuery"
import { Box } from "@mui/material"
import { Card } from "../../core/surfaces/Card"
import { Colors, Styles } from '../brand/themes';

const TableWrapper = ({ children, style }: { children: any, style?: any }) => {
    const [mobile] = useMediaQuery(414);

    return (
        <Wrapper sx={style} mobile={mobile}>
            {children}
        </Wrapper>
    );
}

export default TableWrapper;

const Wrapper = styled(Box)<{ mobile: boolean }>(({ mobile }) => ({
    display: "grid",
    position: "relative",
    padding: "16px",
    height: "70vh",
    boxSizing: "border-box",
    overflowX: "scroll",
    "::-webkit-scrollbar": {
        height: "0px",
        width: "0px",
    },
    "@media (max-width: 414px)": {
        gap: "8px",
        padding: "8px",
        height: "75vh",
        display: "flex",
        flexDirection: 'column'
    },
    ...(!mobile ? {
        borderRadius: "3px",
        background: Colors.white(),
        ...Styles.shadow(),
    } : {})
}));
