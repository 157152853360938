import React, { useMemo } from "react";
import AutoComplete from "./AutoComplete";

type ObjectOption = { [key: string]: any }
type ObjectArr = ObjectOption[]

export type AutoCompleteDynamicProps = {
    options: ObjectArr | string[],
    setValue: any,
    validate?: (val?: string) => boolean,
    error?: string | boolean | undefined,
    label: string,
    value: string | number | boolean | undefined | number[],
    propName?: string,
    targetProp?: string,
    displayProp?: string,
    analyticsTag?: string,
    style?: { [key: string]: string },
    endAdornment?: JSX.Element,
    solo?: boolean
}

export default function AutoCompleteDynamic({ targetProp, validate, options, setValue, propName, value, ...props }: AutoCompleteDynamicProps) {
    const handleSetValue = (val: string) => {
        const option = (options as ObjectArr).find(option => option[propName || "label"] === val) || {}
        setValue(option)
    }

    const isObjArr = useMemo(() => {
        return options.some(o => typeof o === "object")
    }, [options])

    if (isObjArr) {
        return <AutoComplete
            options={(options as ObjectArr).map(o => o[propName || "label"])}
            setValue={handleSetValue}
            {...props}
            value={((
                options as ObjectArr).find(option => option[targetProp || "value"] === value) || {}
            )[propName || "label"] || ""}
        />
    }

    return <AutoComplete
        options={options as string[]}
        setValue={setValue}
        {...props}
        value={value}
    />
}
