import React, { useCallback, useContext } from "react"
import { useForm } from "../../../hooks/useForm";
import Input from "../../../core/form-components/Input";
import AutoComplete from "../../../core/form-components/AutoComplete";
import { FormCard } from "../../../pages/user-profile-update/components/FormStyles";
import { PrimaryButton, QuaternaryButton } from "../../../components/button";
import { Box, FormControlLabel, FormGroup } from "@mui/material";
import { IBaseResponse } from "../../../models/IBaseResponse";
import agents from "../../../api/agent";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { freeLocksValidator } from "../../../validators/validators";
import { Manufacturer } from "../../../enums/Manufacturer";
import { Checkbox } from '@mui/material';
import { strings } from "../../../content/strings";
import { IFreeLockForm } from "../../../models/models";

const initialValues = {
    noOfNewLocks: 0,
    color: "",
    manufacturer: 100,
    publicFacing: false
}

const lockColors = ["Red", "Silver", "Gray", "Blue", "Yellow", "Green", "Black"]
const lockManufacturers = ["Better By Day", "Fake Lock For Testing"]
const AddLockForm = ({ closeBlade, fetchFreeLocks }: { closeBlade: any, fetchFreeLocks: any }) => {
    const { displayToast } = useContext(ToastContext);

    const submitAction = useCallback(async (formValues: IFreeLockForm) => {
        try {
            const result: IBaseResponse = await agents.Locks.addFreeLocks(formValues);
            if (result.success) {
                displayToast({ type: ToastType.Success, title: "Success", text: strings.locksGenerated });
                fetchFreeLocks()
                closeBlade();
            } else {
                displayToast({ type: ToastType.Error, title: "Error", text: strings.somethingWentWrong });

            }
        } catch (err: any) {
            displayToast({ type: ToastType.Error, text: err.message })

        }
    }, [])

    const handleCheckBox = () => {
        const { value, setValue } = form.inputProps.publicFacing;
        setValue(!value);
    }

    const form = useForm(initialValues, submitAction, freeLocksValidator)
    const colorProps = form.inputProps.color;
    const manufacProps = form.inputProps.manufacturer;

    return (
        <FormCard>
            <Input {...form.inputProps.noOfNewLocks} type="number" label={strings.numOfLocks} />
            <AutoComplete
                options={lockColors}
                value={colorProps.value}
                setValue={(val: string) => {
                    colorProps.setValue(val)
                }}
                label={strings.chooseColor}

            />

            <AutoComplete
                options={lockManufacturers}
                value={Manufacturer[manufacProps.value as number]}
                setValue={(val: any) => {
                    manufacProps.setValue(val ? Manufacturer[val] : 0)
                }}
                label={strings.lockManufacturer}
            />
            <FormGroup>
                <FormControlLabel control={
                    <Checkbox
                        checked={!!form.inputProps.publicFacing.value}
                        onChange={handleCheckBox} />} label={strings.publicFacing} />

            </FormGroup>

            <Box sx={{
                display: "flex",
                margin: "32px -6px",
                justifyContent: "space-evenly",
                gap: "16px",
            }}>
                <QuaternaryButton fullWidth onClick={closeBlade}>{strings.cancel}</QuaternaryButton>
                <PrimaryButton fullWidth onClick={form.handleSubmit}>{strings.submit}</PrimaryButton>
            </Box>
        </FormCard>
    );
}

export default AddLockForm;
