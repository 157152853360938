import { MutableRefObject, useRef } from "react";
import { ILoggedInUser } from "../models/ILoggedInUser";
import { getCurrentUser } from "../redux/auth/authSlice";
import { RootState } from "../redux/store";
import { debugLog } from "../utils/debugLog";
import { useAppSelector } from "./useAppSelector";

interface IUseLoggedInUser {
    userRef: MutableRefObject<ILoggedInUser | null>,
    loggedInUser: ILoggedInUser | null,
    loading: boolean,
    isSubscribed: () => boolean
}

export const useLoggedInUser = (): IUseLoggedInUser => {
    const userRef = useRef<ILoggedInUser | null>(null)

    const { loggedInUser, status } = useAppSelector(getCurrentUser)
    userRef.current = loggedInUser

    const isSubscribed = () => {
        if (!loggedInUser?.paidThrough) return false;
        return new Date(loggedInUser.paidThrough).getTime() > Date.now()
    }

    return {
        userRef,
        loggedInUser,
        isSubscribed,
        loading: loggedInUser ? false : status === "awaiting"
    }
}

/** Returns null values if error is thrown from redux store */
export const useTryUseLoggedInUser = (): IUseLoggedInUser => {
    try {
        return useLoggedInUser()
    } catch (err) {
        debugLog(err)
        return {
            userRef: { current: null },
            loggedInUser: null,
            loading: false,
            isSubscribed: () => false,
        }
    }
}

const getLoggedInUserRoleID = (state: RootState) => state.auth.loggedInUser?.roleID

export const useUserRoleID = (): number | undefined => {
    return useAppSelector(getLoggedInUserRoleID)
}
