import { Card, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import agents from "../../../api/agent";
import { PrimaryButton } from "../../../components/button";
import { Colors } from "../../../core/brand/themes";
import { H4Meduim } from "../../../core/brand/typography";
import Input from "../../../core/form-components/Input";
import useAnalytics from "../../../hooks/useAnalytics";
import { FormObject, useForm } from "../../../hooks/useForm";
import { useLockLogs } from "../../../hooks/useLockLogs";
import { useLocks } from "../../../hooks/useLocks";
import { LockModel } from "../../../models/lockModel";
import { LogsTable } from "../../../pages/serial-codes/components/LogsTable";
import { strings } from "../../../content/strings";
import { searchLockBySerialCodeValidator } from "../../../validators/validators";
import LockDetails from "./LockDetails";
import LockStatus from "../../../enums/LockStatus";
export interface ISearchLock extends FormObject {
    serialCode: string;
}

const ClientCallCenterContainer = () => {
    const { trackClickEvent } = useAnalytics("Client Call Center", false)
    const { locks } = useLocks();
    const [lock, setLock] = useState<LockModel | null>(null);

    const [logs, fetchLogs] = useLockLogs(lock?.lockID as number, null)
    const [alert, setAlert] = useState(false)

    const submitAction = async (formValues: ISearchLock) => {
        trackClickEvent("Query Serial Code")
        const { serialCode } = formValues

        const getLockBySerialCode = (serialCode: string) => {
            const lock = locks.filter((l: LockModel) => l.serialCode === serialCode);
            if (lock?.length > 0) {
                return lock[0]
            }
        }

        const lock = getLockBySerialCode(serialCode.toUpperCase());
        if (lock) {
            setLock(lock)
            setAlert(false)
        } else {
            setAlert(true)
        }
    }

    const updateLock = async () => {
        const result = await agents.Locks.getLockById(lock?.lockID || -1);
        if (result.success) {
            setLock(result.data || null);
        }
    }

    const form = useForm<ISearchLock>
        ({ serialCode: "" }, submitAction, searchLockBySerialCodeValidator)

    const serialCodeProps = form.inputProps.serialCode

    useEffect(() => {
        const recordLockReveal = async () => {
            await agents.Locks.logRevealLockCombination(lock!);
            fetchLogs()
        }
        if (lock?.secondaryLockStatus == LockStatus["Tenant Using Lock"]) {
            recordLockReveal();
        }
    }, [lock])

    if (alert) {
        return (
            <Container>
                <Wrapper>
                    <ActionsContainer>
                        <Input {...serialCodeProps} label={strings.serialCode} />
                        <span style={{ marginTop: "4px" }}>
                            <PrimaryButton onClick={form.handleSubmit}>{strings.search}</PrimaryButton>
                        </span>
                    </ActionsContainer>
                </Wrapper>
                <AlertCard >
                    <H4Meduim tertiary>{strings.canNotFindLock}</H4Meduim>
                </AlertCard>
            </Container >
        )
    }

    return (
        <Container>
            <Wrapper>
                <ActionsContainer>
                    <Input {...serialCodeProps} label={strings.serialCode} />
                    <span style={{ marginTop: "4px" }}>
                        <PrimaryButton onClick={form.handleSubmit}>{strings.search}</PrimaryButton>
                    </span>
                </ActionsContainer>
            </Wrapper>
            {lock &&
                <LockDataDisplay>
                    <LockDetails trackClickEvent={trackClickEvent} lock={lock} updateLock={updateLock} />
                    <LogsContainer>
                        <Header>
                            <H4Meduim secondary>{strings.logs}</H4Meduim>
                        </Header>
                        <LogsTable logs={logs} />
                    </LogsContainer>
                </LockDataDisplay>
            }
        </Container >
    )
}

export default ClientCallCenterContainer

export const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    boxSizing: "border-box",
    gap: "24px",
    padding: "16px",
    marginTop: "24px",
});

const Wrapper = styled.div({
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
})

const ActionsContainer = styled.div({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "35%",
    marginLeft: "20px"
})

const LockDataDisplay = styled.div({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    padding: "12px ",
    "@media(max-width : 820px)": {
        display: "flex",
        flexDirection: "column",
        gap: "24px"
    }
})

const Header = styled.div({
    width: "100%",
    background: Colors.red(),
    padding: "8px 4px",
    margin: "0 0 8px -8px",
    borderRadius: "2px"
});

const LogsContainer = styled(Paper)({
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    margin: "8px 4px 0px 12px",
    "@media(max-width : 545px)": {
        width: "65%",
        margin: "0px",
    },
    "@media(max-width : 820px)": {
        margin: "0px",
    }
});

const AlertCard = styled(Card)({
    display: "grid",
    rowGap: "20px",
    padding: "45px 20px 30px 20px",
    width: "25%",
    position: "relative",
    paddingBottom: "24px",
    margin: "auto"
});
