import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import { useClosestFacility } from '../hooks/useClosestFacility';
import { useFacilities } from '../hooks/useFacilities';
import { useLatLong } from "../hooks/useLatLong";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { useUserRoleID } from '../hooks/useLoggedInUser';
import Role from '../enums/Role';
import { FacilityDto } from '../models/facility';

interface ILocationContext {
    locationActive: boolean,
    setDefaultFacility: (arg: FacilityDto | null) => void
    locationToggleIcon?: any,
    nearestFacility?: FacilityDto | null
    defaultFacility?: FacilityDto | null
}

export const LocationContext = createContext<ILocationContext>({
    locationActive: true,
    setDefaultFacility: (arg) => { },
    nearestFacility: null,
    defaultFacility: null,
})

const LS = {
    defaultFacility: "defaultFacility",
    nearestFacility: "nearestFacility",
    usingNearestFacility: "usingNearestFacility"
}

const LocationProvider = ({ children }: { children: any }) => {
    const { currentPosition } = useLatLong();
    const siteLevel = Role.isSiteRole(useUserRoleID())

    const [usingNearestFacility, setUsingNearestFacility] = useState<boolean>(false)
    const [defaultFacility, setDefaultFacility] = useState<FacilityDto | null>(null);

    const { facilities } = useFacilities();
    const closestFacility = useClosestFacility(currentPosition, facilities);

    useEffect(() => {
        if (!siteLevel &&
            !currentPosition.error &&
            localStorage.getItem(LS.usingNearestFacility) === "true") {
            setUsingNearestFacility(true)
        }
    }, [siteLevel, currentPosition])

    const activateLocation = useCallback((e: any) => {
        setUsingNearestFacility(prevState => {
            const nextState = !prevState
            setDefaultFacility(nextState ? closestFacility : null)
            localStorage.setItem(LS.usingNearestFacility, JSON.stringify(nextState))

            return nextState
        })
        e.preventDefault();
        e.stopPropagation();

    }, [setUsingNearestFacility])

    const locationToggleIcon = useMemo(() => {
        if (siteLevel || currentPosition.error || !currentPosition.latitude) return null
        if (usingNearestFacility) return <MyLocationIcon onClick={activateLocation} />
        return <LocationSearchingIcon onClick={activateLocation} />
    }, [siteLevel, currentPosition, usingNearestFacility, activateLocation])

    const handleSelectDefault = (facility: FacilityDto | null) => {
        setDefaultFacility(facility)
        setUsingNearestFacility(false)
    }

    return (
        <LocationContext.Provider
            value={{
                locationActive: usingNearestFacility,
                setDefaultFacility: handleSelectDefault,
                locationToggleIcon: locationToggleIcon,
                nearestFacility: usingNearestFacility ? closestFacility : null,
                defaultFacility
            }}
        >
            {children}
        </LocationContext.Provider>
    )
}

export default LocationProvider;
