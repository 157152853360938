export enum SubscriptionTier {
    OldWebApp = -2,
    Invalid = -1,
    LocksOnly = 0,
    Basic = 1,
    Plus = 2,
    Premium = 3
}

export type SubscriptionTierDisplay = "Old Web App" | "Invalid" | "Locks Only" | "Basic" | "Plus" | "Premium"

export const getSubscriptionTierDisplay = (tier:SubscriptionTier) => {
    switch (tier) {
        case SubscriptionTier.OldWebApp:
            return "Old Web App";
        case SubscriptionTier.Invalid:
            return "Invalid";
        case SubscriptionTier.LocksOnly:
            return "Locks Only";
        case SubscriptionTier.Basic:
            return "Basic";
        case SubscriptionTier.Plus:
            return "Plus";
        case SubscriptionTier.Premium:
            return "Premium";
    }
}

export const getSubscriptionTierEnum = (tier:SubscriptionTierDisplay) => {
    switch (tier) {
        case "Old Web App":
            return SubscriptionTier.OldWebApp;
        case "Invalid":
            return SubscriptionTier.Invalid;
        case "Locks Only":
            return SubscriptionTier.LocksOnly;
        case "Basic":
            return SubscriptionTier.Basic;
        case "Plus":
            return SubscriptionTier.Plus;
        case "Premium":
         return SubscriptionTier.Premium
    }
}

export const subscriptions = ["Old Web App", "Locks Only", "Basic", "Plus", "Premium"];
 