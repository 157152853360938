import mixpanel from 'mixpanel-browser';
import { ILoggedInUser } from '../models/ILoggedInUser';
import { createThrottle } from '../utils/createThrottle';
import environment from '../utils/environment';

// per mixpanel, we don't need to hide this token...
const mixPanelToken = location.host.includes("davincilock.com") ? "a65d1127106459caa6b2e06ff779a61d" : "43544f27ecd37bed45947eb6c72fb2cd";

const initAnalytics = () => {
    mixpanel.init(mixPanelToken, { debug: environment.local })
}

initAnalytics()

let lastEventViewed = sessionStorage.getItem("lastEventViewed") || "";

const trackViewEvent = (analyticsContext: string, pageName: string, user: ILoggedInUser | null) => {
    const viewEvent = `View - ${analyticsContext}`
    if (lastEventViewed == viewEvent) return;
    lastEventViewed = viewEvent;
    sessionStorage.setItem("lastEventViewed", viewEvent)

    trackEvent(
        viewEvent,
        user,
        { pageName: pageName }
    )
}

const trackClickEvent = async (analyticsContext: string, clickContext: string, user: ILoggedInUser | null, properties: { [key: string]: any } = {}): Promise<any> => {
    return new Promise((resolve) => {
        trackEvent(
            `Click - ${analyticsContext} - ${clickContext}`,
            user,
            {
                buttonClicked: clickContext,
                ...properties
            },
            (res: any) => {
                resolve(res)
            }
        )
    })
}

const isDNTEnabled = () => {
    // @ts-ignore
    const dnt = navigator.doNotTrack || window.doNotTrack;
    return (dnt === 'yes' || dnt === '1');
};

export const trackEvent = (eventName: string, user: ILoggedInUser | null, properties: { [key: string]: any } = {}, callback?: any) => {
    if (isDNTEnabled()) return;

    if (user?.userID) {
        mixpanel.identify(user.userID.toString());
    }
    mixpanel.track(eventName, {
        ...user,
        ...properties,
    }, callback)
}

const trackAppLoad = createThrottle((user: ILoggedInUser | null) => {
    return new Promise((resolve) => {
        trackEvent("App Loaded", user, {}, (res: any) => {
            resolve(res)
        })
    })
})

export const analyticsService = { initAnalytics, trackViewEvent, trackClickEvent, trackAppLoad };
