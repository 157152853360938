import { useMemo } from "react";
import { useCompanyID } from "../hooks/useCompanyID";
import { useLoggedInUser } from "../hooks/useLoggedInUser";

export const useIsSiteLevelPage = () => {
    const { loggedInUser } = useLoggedInUser();
    const { companyID } = useCompanyID();

    return useMemo(() => !loggedInUser?.companyID && !companyID, [companyID, loggedInUser?.companyID]);
};
