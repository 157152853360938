import React, { useState } from "react"
import styled from "styled-components";
import { FilterButton } from "../../components/styled/filter-button";
import { Colors, Styles } from "../../core/brand/themes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IPaginationDropDown {
    selectedItem: any,
    leftMargin: boolean,
    dropDownText: string,
    dropDownData: number[],
    setter: any
}

const PaginationDropDown = ({ selectedItem,
    leftMargin,
    dropDownText,
    dropDownData,
    setter }: IPaginationDropDown) => {
    const [showDropDown, setShowDropDown] = useState(false);

    return (
        <DropDownFilterWrapper leftMargin>
            <FilterButton onClick={() => setShowDropDown(!showDropDown)}>
                <StyledFAIcon size="xs" icon={showDropDown ? faAngleUp as IconProp : faAngleDown as IconProp}
                    color={Colors.white()}
                    onClick={() => setShowDropDown(!showDropDown)}></StyledFAIcon>
            </FilterButton>
            <Pages wide={leftMargin || false} onClick={() => setShowDropDown(!showDropDown)} >
                {leftMargin ? `${selectedItem} ${dropDownText}` : `${dropDownText} ${selectedItem}`}
                <DropDown showDropDown={showDropDown} left={-30}>
                    {dropDownData.map((item: number, idx) => (
                        <DropDownList onClick={() => { setter(item), setShowDropDown(!showDropDown) }}
                            last={idx === dropDownData.length - 1}
                            key={idx}> {leftMargin ? item : `${dropDownText} ${item}`}</DropDownList>
                    ))}
                </DropDown>
            </Pages>
        </DropDownFilterWrapper>
    )
}

const Pages = styled.div<{ wide?: boolean }>((props) => ({
    position: 'relative',
    width: props.wide ? "115px" : "45px",
    borderBottom: `solid 1px ${Colors.navy()}`,
    boxSizing: "border-box",
    color: "black",
    fontSize: "0.75rem",
    lineHeight: "10px",
    marginRight: "6px",
    cursor: "pointer"
}))

const DropDown = styled.div<{ showDropDown: boolean, left: number }>((props) => ({
    visibility: props.showDropDown ? "visible" : "hidden",
    position: "absolute",
    bottom: 28,
    left: props.left,
    background: Colors.white(),
    width: "85px",
    borderRadius: "3px",
    display: "grid",
    gridTemplateColumns: "100%",
    color: Colors.black(),
    padding: "0 8px",
    ...Styles.shadow(),
    zIndex: 1,
    transform: "translate3d(0, 0, 100px)",
}))

const DropDownList = styled.div<{ last: boolean }>((props) => ({
    margin: "4px 0px",
    textAlign: "center",
    borderBottom: props.last ? "none" : `solid 1px ${Colors.red()}`,
    lineHeight: "15px",
    cursor: "pointer",
    height: "20px",
}))

const DropDownFilterWrapper = styled.div<{ leftMargin?: boolean }>((props) => ({
    display: "flex",
    padding: "4px 2px",
    marginRight: "24px",
    ...(props.leftMargin ? { marginLeft: "24px" } : {})
}))

const StyledFAIcon = styled(FontAwesomeIcon)({
    marginBottom: "8px",
})

export default PaginationDropDown
