import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { authSlice } from './auth/authSlice';
import { companiesSlice } from './companies/companiesSlice';
import { companyUsersSlice } from './companyUsers/companyUsersSlice';
import { locksSlice } from './locks/lockSlice';
import { CustomRolesSlice } from './custom-roles/customRolesSlice';
import { userFacilitiesSlice } from './userFacilities/userFacilitiesSlice';

export const store = configureStore({
    reducer: combineReducers({
        auth: authSlice.reducer,
        companyUsers: companyUsersSlice.reducer,
        userFacilities: userFacilitiesSlice.reducer,
        locks: locksSlice.reducer,
        companies: companiesSlice.reducer,
        customRoles: CustomRolesSlice.reducer
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
