import { getAuthUserFromUid, updateLoggedInUser } from "../redux/auth/authSlice";
import { useAppDispatch } from "../hooks/useAppSelector";

export const useUpdateLoggedInUser = () => {
    const dispatch = useAppDispatch()

    return { updateLoggedInUser: async (userData: any) => dispatch(updateLoggedInUser(userData)) }
}

export const useLoginUserFromToken = () => {
    const dispatch = useAppDispatch()

    return { getAuthUserFromUid: async (uid: string) => dispatch(getAuthUserFromUid(uid)) }
}
