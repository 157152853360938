import { fetchCompanyLocksAsync } from "../locks/lockSlice";
import { fetchCompanyUsersAsync } from '../companyUsers/companyUsersSlice';
import { fetchCompanyFacilitiesAsync } from "../userFacilities/userFacilitiesSlice";
import { ActionTypes } from "./ActionTypes";
import { fetchCompanyRolesAsync } from "../custom-roles/customRolesSlice";

/** Fetches queried company redux store for site level users */
export const loadCompanyDetails = (dispatch: any, companyID: number | null) => {
    if (companyID) {
        dispatch(fetchCompanyFacilitiesAsync(companyID));
        dispatch(fetchCompanyUsersAsync(companyID));
        dispatch(fetchCompanyLocksAsync({companyID, inventoryLocks:false}));
        dispatch(fetchCompanyRolesAsync(companyID));
    }
};

export const resetSiteRole = () => {
    return {
        type: ActionTypes.RESET_SITE_ROLE
    };
};
