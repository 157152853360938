import { useCallback, useContext, useEffect } from 'react'
import { AnalyticsContext } from '../contexts/AnalyticsProvider';
import { analyticsService } from "../services/analytics-service"
import { createThrottle } from '../utils/createThrottle';
import { useTryUseLoggedInUser } from './useLoggedInUser';

interface IAnalyticsHook {
    trackViewEvent: () => void,
    trackClickEvent: (arg0: string, properties?: { [key: string]: any }) => void,
}

const throttledTrackViewEvent = createThrottle<void>(async (componentContext, pageName, loggedInUser) =>
    analyticsService.trackViewEvent(componentContext, pageName, loggedInUser),
    500
)

const useAnalytics = (componentContext: string, shouldTrackViewEvent = true): IAnalyticsHook => {
    const { loggedInUser } = useTryUseLoggedInUser()
    const { pageName } = useContext(AnalyticsContext)

    const trackViewEvent = useCallback(
        () => throttledTrackViewEvent(componentContext, pageName, loggedInUser),
        [loggedInUser]
    )

    const trackClickEvent = useCallback(
        createThrottle<any>(
            (clickContext: string, properties: { [key: string]: any } = {}): Promise<any> =>
                analyticsService.trackClickEvent(
                    componentContext,
                    clickContext,
                    loggedInUser,
                    { pageName, ...properties }
                ),
            500
        ),
        [loggedInUser]
    )

    useEffect(() => {
        if (shouldTrackViewEvent) {
            trackViewEvent()
        }
    }, []);

    //TODO: have each event is added to a queue and awaited before next event instead of awaiting in click methods.
    return {
        trackViewEvent,
        trackClickEvent,
    };
}

export default useAnalytics;
