import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Colors } from '../brand/themes';
import { IconButton } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CancelOutlined from '@mui/icons-material/Clear';
import agents from '../../api/agent';

interface IAcceptedFile extends File {
    preview: string
}

export default function DropUpload({ onChange, hash }: { onChange: (file: FormData | null) => void, hash?: string }) {
    const [file, setFile] = useState<IAcceptedFile | null | { preview: string }>(null);

    useEffect(() => {
        const setLogo = async () => {
            if (hash) {
                const preview = await agents.Account.getCompanyImage(hash)
                setFile({
                    preview
                })
            }
        }

        setLogo()
    }, []);

    const [hovering, setHovering] = useState(false)
    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setHovering(false)
            const newFile = Object.assign(acceptedFiles[0], {
                preview: URL.createObjectURL(acceptedFiles[0])
            });
            setFile(newFile);

            const formData = new FormData();
            formData.append("file", newFile);
            onChange(formData);
        }
    });

    useEffect(() => () => URL.revokeObjectURL(file?.preview || ""), []) // to avoid memory leaks

    const handleClearFile = (e: any) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        URL.revokeObjectURL(file?.preview || "")
        setFile(null);
        onChange(new FormData());

        return false;
    }

    const renderPreview = () => {
        if (!file) {
            return null;
        }
        return (
            <>
                <div
                    onMouseLeave={() => setHovering(false)}
                    style={{
                        position: "absolute",
                        top: "calc(50%  - 30px)",
                        display: "flex",
                        gap: "8px",
                    }}
                >
                    <IconButton
                        aria-label="delete" size="large"
                        id="camera-icon"
                        onMouseEnter={() => setHovering(true)}
                        onClick={open}
                    >
                        <CameraAltOutlinedIcon fontSize="large" style={{ fill: Colors.white() }} />
                    </IconButton>

                    <IconButton
                        onClick={handleClearFile}
                        aria-label="delete" size="large"
                        id="camera-icon"
                        onMouseEnter={() => setHovering(true)}
                    >
                        <CancelOutlined fontSize="large" style={{ fill: Colors.white() }} />
                    </IconButton>
                </div>
                <Thumbnail
                    onClick={open}
                    src={file.preview}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </>
        )
    }

    const renderEmptyDropContainer = () => (
        <DropContainer>
            <IconButton
                style={{
                    position: "absolute",
                    top: "calc(50%  - 30px)",
                    left: "calc(50% - 30px)",
                }}
                aria-label="delete" size="large"
                id="camera-icon"
                onMouseEnter={() => setHovering(true)}
                onClick={open}
            >
                <CameraAltOutlinedIcon fontSize="large" style={{ fill: Colors.white() }} />
            </IconButton>
        </DropContainer>
    )

    return (
        <DropSection hideIcon={!!file && !hovering} {...getRootProps({ className: 'dropzone' })}>
            {
                file
                    ? renderPreview()
                    : renderEmptyDropContainer()
            }
        </DropSection>
    );
}

const DropContainer = styled.div({
    zIndex: 5,
    height: "100px", width: "100%", boxSizing: "border-box", marginLeft: "4px",
    position: "relative",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
})

const DropSection = styled.section<{ hideIcon?: boolean }>(({ hideIcon }) => ({
    position: "relative",
    backgroundColor: Colors.lightergrey(),
    display: "flex",
    justifyContent: "center",
    height: "100px",
    ["#camera-icon"]: {
        opacity: hideIcon ? 0 : 1,
        backgroundColor: Colors.lightgrey(),
    },
    border: "1px dashed black",
}))

const DropInput = styled.input({
    width: "100%", height: "100px", opacity: 0
})

const Thumbnail = styled.img({
    height: "93px",
    maxWidth: "calc(100% - 4px)",
})
