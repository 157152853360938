import { Box, CircularProgress } from "@mui/material"

const GenericLoader = () => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: '100%', minHeight: "300px", alignItems: "center" }}>
            <CircularProgress size={50} thickness={5} />
        </Box>
    )
}

export default GenericLoader
