import React from "react";
import ReactToPrint from "react-to-print";
import ReactDOM from "react-dom";
import { PrintTable } from "../../../core/table/Table";
import { PrimaryButton } from "../../../components/button";
import { strings } from "../../../content/strings";
import { events as analyticsEvents } from "../../../mixPanelEvents";
import { Provider } from 'react-redux'
import { store } from "../../../redux/store"

export const PrintTableButton = ({
    printTableRef,
    trackClickEvent,
    style
}: {
    printTableRef: React.MutableRefObject<any>;
    trackClickEvent: (tag: string) => void;
    style?: any
}) => (
    <ReactToPrint
        trigger={() => <PrimaryButton>{strings.print}</PrimaryButton>}
        content={() => {
            const divEl = document.createElement("div");

            if (printTableRef?.current?.data) {
                const { data, headers } = printTableRef?.current;
                if (data.length > 750) {
                    alert(strings.printAlert(data?.length));
                }

                trackClickEvent(analyticsEvents.printLocks)
                ReactDOM.render(
                    <Provider
                        /** Render redux store to prevent errors thrown
                         * from trying to access redux state during rendering of PrintTable
                        */
                        store={store}>
                        <PrintTable
                            data={data}
                            headers={headers} />
                    </Provider>, divEl);
            }

            return divEl;
        }} />
);
