import { ICMSDocument } from "./common/cmsSchema";

export const privacyPolicy: ICMSDocument = {
    title: "DaVinci Lock Privacy Notice",
    effectiveDate: "Effective Date: February 1, 2023",
    content: [
        {
            content: [
                {
                    text: "This privacy notice explains how DaVinci Lock LLC and its affiliates (hereinafter, we, us, our) collects, uses, shares, or otherwise processes the personal data we collect from you when you access our websites (including ",
                    links: ["www.davincilock.com", "www.davincilockrelease.com", "www.overlockrelease.com", "www.davincilockstore.com"]
                },
                {
                    text: " mobile applications, or software (collectively, the “Services”)."
                }
            ]
        },
        {
            content: [
                {
                    text: "Table of Contents:",
                    newline: "16px"
                },
                {
                    type: "ul",
                    items: [
                        {
                            content: [
                                {
                                    text: "Who we are"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Data we collect"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "How we use your personal data"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "How we may share your personal data and with whom"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Retention and deletion of your personal data"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Our use of cookies and other similar technologies"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Your choices"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Privacy notices of other websites"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Changes to this notice"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "How to contact us"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Additional Information for Individuals in the EEA"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "Who We Are",
            content: [
                {
                    text: "The Services are provided by us, a U.S.-based company, registered in North Carolina and operating in North Carolina. If you are visiting our website from another country, you should be aware that the United States of America’s data protection and data privacy laws and protections may not be as comprehensive as those in your home country, in particular, if your are visiting our website from within the European Economic Area (EEA), you should be aware that the United States of America has not been determined to ensure adequate protection for personal information by the European Commission or the other equivalent authorities in the region. We will nonetheless take appropriate steps to protect any personal information that we collect about you."
                }
            ]
        },
        {
            title: "Data We Collect",
            content: [
                {
                    text: "Information that you provide us",
                    underline: true
                },
                {
                    text: ": This is information that you provide to us when you use our Services. For example, we may collect the following data:"
                },
                {
                    type: "ul",
                    items: [
                        {
                            content: [
                                {
                                    text: "Personal contact information, such as your first and last name, email address, telephone number, shipping/billing address, credit card or debit card information;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Business contact information, such as business name, business shipping/billing address, business email, business telephone number, and payment information;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Commercial information about products that you purchase or express an interest in purchasing; and"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Any other information you may provide us when contacting us via the website, mobile app, or our customer support department by email or telephone."
                                }
                            ]
                        }
                    ]
                },
                {
                    text: "Information that our website automatically collects",
                    underline: true
                },
                {
                    text: ": Our website will automatically collect some information about you and your device, including:"
                },
                {
                    type: "ul",
                    items: [
                        {
                            content: [
                                {
                                    text: "Identifiers, such as your Internet protocol (IP) address and other information that can be used to recognize you and your device, including cookies and information about your devices such as your browser type and version;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Internet and other electronic network activity information, such as the pages on our site that you visit, the products that you view, and other information about your interactions with our site;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Inferences are drawn from your activity on our website and information that you give us to create a profile that reflects your product interests and purchasing preferences; and"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Geolocation data, such as the geographic location associated with your IP address."
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "How We Use Your Personal Data",
            content: [
                {
                    text: "We may use your data for the following purposes:"
                },
                {
                    type: "ul",
                    items: [
                        {
                            content: [
                                {
                                    text: "To make the Services available to you and facilitate your use of the Services;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To respond to your inquiries, and provide information that you request;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To process and fulfill your orders;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To manage your account with us;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To process and recover payments for our products;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To address and resolve customer service and support issues;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To personalize your website experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and advertisements, on our website and third-party sites;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To develop and promote our business and our relationship with you or the business you represent, including by communicating with you and any business that you represent via email or text message (with your consent, where required by law) about products that may be of interest to you;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To operate, administer and improve our website and other aspects of our operations, including debugging, identifying, or repairing errors or effectuating similar functional enhancements;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To detect security incidents and protect against malicious, deceptive, fraudulent, or illegal activity on our website, and to prosecute those responsible for that activity;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To engage in or enable internal uses consistent with our relationship with you, or compatible with the context in which you provided the information, such as for testing, research, analysis, and product development, including to develop and improve our website, products, and services;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information about our customers and/or website users is among the assets transferred;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To enforce and enable our legal rights and the legal rights of our partners and users;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To protect our business from fraud, money-laundering, breach of confidence, theft of proprietary materials and other financial or business crimes;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "To comply with our legal and regulatory obligations, including to respond to law enforcement requests and to bring and defend legal claims; and"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "For any other purpose described to you when collecting your personal information before or at the time of collection."
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            title: "How We May Share Your Personal Data and with Whom",
            content: [
                {
                    text: "To accomplish the purposes set out above, we may share personal data about you with the following third parties:"
                },
                {
                    type: "ul",
                    items: [
                        {
                            content: [
                                {
                                    text: "Our affiliated companies;"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Our service providers and contractors who perform services on our behalf, and that may include, but are not limited to:"
                                },
                                {
                                    type: "ul",
                                    items: [
                                        {
                                            content: [
                                                {
                                                    text: "website hosting and e-commerce platform providers,"
                                                }
                                            ]
                                        },
                                        {
                                            content: [
                                                {
                                                    text: "data centers,"
                                                }
                                            ]
                                        },
                                        {
                                            content: [
                                                {
                                                    text: "website analytical service providers,"
                                                }
                                            ]
                                        },
                                        {
                                            content: [
                                                {
                                                    text: "credit and debit card payment processors,"
                                                }
                                            ]
                                        },
                                        {
                                            content: [
                                                {
                                                    text: "order fulfillment and distribution service providers,"
                                                }
                                            ]
                                        },
                                        {
                                            content: [
                                                {
                                                    text: "product shipping service providers; and"
                                                }
                                            ]
                                        },
                                        {
                                            content: [
                                                {
                                                    text: "marketing companies;"
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "An actual or potential buyer or successor in the event of an actual or potential merger, change in organizational structure, or other sale or transfer of some or all our assets, including as part of bankruptcy, liquidation, or similar proceedings; or"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Governmental bodies, such as law enforcement, a court of law, or regulators; and"
                                }
                            ]
                        },
                        {
                            content: [
                                {
                                    text: "Such other parties as you may subsequently consent to or authorize."
                                }
                            ]
                        },
                    ]
                },
                {
                    text: "These disclosures may involve transferring your personal data within the United States or overseas, including to other countries that may not have similarly strict data privacy laws as those in your home country. In those cases, where we transfer personal data with our affiliates or with our service providers, we will ensure that the transfers comply with applicable data privacy laws, including the use of data-sharing agreements as required by law to ensure that your personal data is protected. Please contact us if you would like to know whether any such agreements are in place or if so, to see a copy, as applicable."
                }
            ]
        },
        {
            title: "Retention and Deletion of Your Personal Data",
            content: [
                {
                    text: "We will retain your personal data as long as needed to fulfill the Services but will delete your information when we no longer need it. Please contact us in writing, as specified below, for more information regarding our record retention policies and practices.",
                    newline: "16px"
                },
                {
                    text: "Note that, if you are a contact at one of our customers, we may retain some limited information about you even when we know that you have left the organization that you represent, so that we can maintain a continuous relationship with you if and when we are in contact with you again, representing a different organization. Generally, we will retain some information after our business relationship with you has ended, so as to maintain an appropriate record of our transactions against the possibility of future disagreements or disputes, or as required by law."
                }
            ]
        },
        {
            title: "Our Use of Cookies and Other Similar Technologies",
            content: [
                {
                    text: "Our website may download cookies to your device, and we may also use web beacons to collect information about website usage. A cookie is a data file that is placed by a website operator on the hard drive of a visitor’s computer to their site. A “web beacon” is a transparent graphic image placed on a website that enables the monitoring of things such as user activity and site traffic.",
                    newline: "16px"
                },
                {
                    text: "First-party cookies (those placed by us) and third-party cookies (those placed by other parties with whom we work) may be placed on your computer when you visit our website to allow our website to function properly and to improve the user experience, including to help you access secure areas or use a shopping cart, to remember you and your preferences as you browse through different pages, and to allow us to perform website analytics.",
                    newline: "16px"
                },
                {
                    text: "One of the ways that we do this is through the use of Google Analytics. For more information about how Google Analytics uses and processes data, please visit ",
                    link: {
                        text: "https://www.google.com/policies/privacy/partners/",
                        link: "https://www.google.com/policies/privacy/partners/"
                    }
                },
                {
                    text: ".",
                    newline: "16px"
                },
                {
                    text: "We and third parties with whom we work may also place cookies and use web beacons on our website to collect information about your browsing activity, and use that information to deliver content relevant to your interests, including our advertisements, on our website and elsewhere on the internet. We may also use those technologies to understand the usefulness to you of the advertisements and content that has been delivered to you, such as whether you have clicked on an advertisement.",
                    newline: "16px"
                },
                {
                    text: "For more information on how to control the use of cookies, please see the “Your Choices” section of this Privacy Notice."
                }
            ]
        },
        {
            title: "Your Choices",
            content: [
                {
                    text: "You may review and request changes to the certain personal information we collect about you. To do so please contact us in writing, as specified below.",
                    newline: "16px"
                },
                {
                    text: 'At this time, we do not respond to browser "do not track" signals, but you may be able to control or delete cookies using the preferences settings in your browser.'
                }
            ]
        },
        {
            title: "Privacy Notices of Other Websites",
            content: [
                {
                    text: "Our website may contain links to other websites and third-party advertising materials. The providers of these websites and materials may collect information about you when you visit/click on these websites. We do not control the personal information processing practices of these providers – you should consult their posted privacy policies. Our privacy notice applies only to our website."
                }
            ]
        },
        {
            title: "Changes to this Notice",
            content: [
                {
                    text: "If there are updates or changes to this privacy notice we will post those changes here and update the Effective Date above so that you will always know what information we collect, how we use it, and what choices you have. Your continued use of our website following the posting of changes to this privacy notice means you acknowledge that you have had an opportunity to review those changes."
                }
            ]
        },
        {
            title: "How to Contact Us",
            content: [
                {
                    text: "If you have any questions about our privacy notice, the data we hold on you, or if applicable, you would like to exercise one of your data protection rights, please do not hesitate to contact us.",
                    newline: "16px"
                },
                {
                    text: "Email us at: ",
                    email: "privacy@davincilock.com",
                    newline: "16px"
                },
                {
                    text: "Or write to us at:",
                    newline: "16px"
                },
                {
                    text: "3301 Atlantic Avenue",
                    newline: "0"
                },
                {
                    text: "Raleigh, NC 27604"
                }
            ]
        },
        {
            title: "Additional Information for Individuals in the EEA",
            content: [
                {
                    text: "If you reside within the European Economic Area (including Switzerland and the United Kingdom), then you may be entitled under the European Union’s General Data Protection Regulation or similar applicable law to certain additional information and rights with respect to our collection, use, and sharing of your personal data. These additional rights, may include the following:",
                    newline: "16px"
                },
                {
                    text: "The right to access",
                    bold: true
                },
                {
                    text: " – the right to request that we provide to you copies of your personal data. We may charge you a small fee for this service.",
                    newline: "16px"
                },
                {
                    text: "The right to rectification",
                    bold: true
                },
                {
                    text: " – the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.",
                    newline: "16px"
                },
                {
                    text: "The right to erasure",
                    bold: true
                },
                {
                    text: " – the right to request that we erase your personal data, under certain conditions.",
                    newline: "16px"
                },
                {
                    text: "The right to restrict processing",
                    bold: true
                },
                {
                    text: " – the right to request that we restrict the processing of your personal data, under certain conditions.",
                    newline: "16px"
                },
                {
                    text: "The right to object to processing",
                    bold: true
                },
                {
                    text: " – the right to object to our processing of your personal data, under certain conditions.",
                    newline: "16px"
                },
                {
                    text: "The right to data portability",
                    bold: true
                },
                {
                    text: " – the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.",
                    newline: "16px"
                },
                {
                    text: "If you are a citizen of a member state of the EEA and would like to exercise any of these rights, please contact us at: ",
                    email: "privacy@davincilock.com"
                },
                {
                    text: " or write to us at: 3301 Atlantic Avenue, Raleigh, NC 27604. Please note that we have up to a month to respond to your request."
                }
            ]
        }
    ]
} 
