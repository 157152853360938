import React, { useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from 'styled-components'
import { useLoggedInUser } from "../../../hooks/useLoggedInUser"
import { ICompanyUser } from "../../../models/ICompanyUser"
import Navigation from "../../../routes/Navigation"
import { AddEditUserBlade } from "./AddEditUserBlade"
import { UsersListingTable } from "./UsersListingTable"

const initialValues = (): ICompanyUser => ({
    firstName: "",
    lastName: "",
    email: "",
    roleID: 0,
    facilityIDs: [],
})

const UsersListingContainer = () => {
    const { loggedInUser } = useLoggedInUser()
    const navigate = useNavigate();

    const [selectedUser, setSelectedUser] = useState<ICompanyUser | null>(null);

    const toggleAddUser = useCallback(
        () => selectedUser ? setSelectedUser(null) : setSelectedUser(initialValues()),
        [selectedUser]
    )

    const handleSetSelectedUser = useCallback((user: ICompanyUser) => {
        if (user.userID === loggedInUser?.userID) {
            // navigate user to profile if trying to edit themself
            navigate(Navigation.profile().link.link!)
        } else {
            setSelectedUser(user)
        }
    }, [loggedInUser?.userID])

    return (
        <Container>
            <UsersListingTable
                canUpdate={true}
                toggleAddUser={toggleAddUser}
                selectedUser={selectedUser}
                setSelectedUser={handleSetSelectedUser}
            />

            <AddEditUserBlade
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
            />
        </Container >
    )
}

export const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    padding: "8px",
    boxSizing: "border-box",
})

export const ActionsContainer = styled.div({
    display: "grid",
    width: "100%",
    gridTemplateColumns: " 1fr repeat(2, auto)",
    alignItems: "center",
    "@media (max-width: 414px)": {
        gridTemplateColumns: "1fr",
        gap: "4px",
    },
})

export default UsersListingContainer;
