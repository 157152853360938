import { Colors } from "../core/brand/themes"

enum LockStatus {
    "Available" = 1001,
    "Lost" = 1002,
    "Returned to Dropbox" = 1003,
    "Assigned Overlock" = 1004,
    "Assigned Vacant" = 1005,
    "Tenant Using Lock" = 1006,
    "Damaged" = 1007,
    "Assigned Auction" = 1008,
    "Unscannable" = 1009,
    "On Dropbox" = 1010,
    "Assigned Other" = 1011,
    "Assigned Vendor" = 1012,
}

export const lockStatuses = Object.values(LockStatus).filter(ls => typeof ls === "string")
export const lockStatusOptions: { label: string, value: any }[] = lockStatuses.map(
    (l: any) => ({
        label: l as string,
        value: LockStatus[l]
    })
)

namespace LockStatus {
    export function getColor(lockStatus: LockStatus) {
        switch (lockStatus) {
            case LockStatus.Available:
                return Colors.navy();
            case LockStatus.Lost:
                return "#d3d3d3"
            case LockStatus.Damaged:
                return "#8e8e8e"
            case LockStatus["Returned to Dropbox"]:
                return "#821720"
            case LockStatus["Assigned Vacant"]:
                return Colors.yellowHover();
            case LockStatus["Assigned Overlock"]:
                return Colors.red();
            default:
                return null
        }
    }

    export function isAssigned(lockStatus: LockStatus) {
        switch (lockStatus) {
            case LockStatus["Assigned Overlock"]:
            case LockStatus["Assigned Vacant"]:
            case LockStatus["Assigned Auction"]:
            case LockStatus["Tenant Using Lock"]:
            case LockStatus["On Dropbox"]:
            case LockStatus["Assigned Other"]:
            case LockStatus["Assigned Vendor"]:
                return true;
            default:
                return false;
        }
    }

    export function isNotAssigned(lockStatus: LockStatus) {
        switch (lockStatus) {
            case LockStatus.Available:
            case LockStatus.Damaged:
            case LockStatus.Lost:
            case LockStatus.Unscannable:
                return true;
            default:
                return false;
        }
    }
}

export default LockStatus
