import gears from "../../assets/images/gears.png";
import { PrimaryButton, SecondaryButton } from "../../components/button";
import { PageTemplate } from "../../core/layout/PageTemplate";
import styled from "styled-components";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { useNavigate } from "react-router";
import Role from "../../enums/Role";

const HowToPage = () => {
    const { loggedInUser, loading } = useLoggedInUser();
    const navigate = useNavigate();

    if (loading) {
        return null;
    }

    const homeClick = () => {
        if (loggedInUser) {
            const dashLink = Role.getDashLink(loggedInUser.roleID)
            navigate(dashLink.link || "/");
        }
    }

    return (
        <PageTemplate
            backgroundImage={gears}
            pageTitle={"How To"}
            pageDescription=""
            pageCanonical="www.davincilock.com/Account/PrivacyPolicy">
            {
                loggedInUser?.userID &&
                <SecondaryButton onClick={homeClick} style={{ top: "50px", position: "fixed", left: 0 }} width="80px">Home</SecondaryButton>
            }
            <IFrameContainer style={{ marginTop: "-120px" }}>
                <iframe
                    src="https://davincilocksupport.com/"
                    width={"100vw"}
                    height={"100%"}
                    frameBorder="0"></iframe>
            </IFrameContainer>
        </PageTemplate>
    );
};

const IFrameContainer = styled.div({
    width: "100vw",
    height: "100vh",
    ["iframe"]: {
        width: "100vw",
        height: "100%",
        border: "none",
        padding: 0,
        margin: 0,
        display: "block",
    }
})

export default HowToPage
