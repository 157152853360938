import React from "react";
import gears from "../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import { strings } from "../../content/strings";
import AutoNotificationContainer from "./components/AutoNotificationsContainer"

const AutoNotifications = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.autoMessages}
            pageDescription={strings.autoMessages}
            pageCanonical={"https://www.davincilock.com/Company/AutoNotifications"}
        >
            <AutoNotificationContainer />
        </LoggedInPageTemplate >
    )
}

export default AutoNotifications
