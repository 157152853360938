import React from "react";
import gears from "../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import FacilityListingContainer from "./components/FacilityListingContainer"
import { strings } from "../../content/strings";

function FacilityListingPage() {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.facilityListing}
            pageDescription=""
            pageCanonical="www.davincilock.com/Company/FacilityListing">
            <FacilityListingContainer />
        </LoggedInPageTemplate >
    );
};

export default FacilityListingPage;
