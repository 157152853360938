import { LockModel } from "../models/lockModel";
import { debugLog } from "../utils/debugLog";

const worker = new Worker(
    // @ts-ignore
    new URL('../workers/locks-excel.worker.js', import.meta.url),
    { type: 'module' }
);

export function createLocksExcelWorker<T>(processResult: (data: T) => void) {
    worker.onerror = (err) => debugLog({ err });

    worker.onmessage = (e) => {
        if (e.data.result) {
            processResult(e.data.result)
        }
    };

    const performWork = (data: LockModel[], facilities: any) => {
        worker.postMessage({
            data,
            facilities,
            LockStatus: {
                "1001": "Available",
                "1002": "Lost",
                "1003": "Returned to Dropbox",
                "1004": "Assigned Overlock",
                "1005": "Assigned Vacant",
                "1006": "Tenant Using Lock",
                "1007": "Damaged",
                "1008": "Assigned Auction",
                "1009": "Unscannable",
                "1010": "On Dropbox",
                "1011": "Assigned Other",
                "1012": "Assigned Vendor",
            }
        });
    }

    return { performWork };
}
