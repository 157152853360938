import { Box, Paper, } from '@mui/material';
import React from 'react';
import TransferOwnership from "./TransferOwnership"
import DefaultRoles from "./DefaultRoles"
import RolesCount from "./RolesCount"
import { Card } from '../../../core/surfaces/Card';
import { useLoggedInUser } from '../../../hooks/useLoggedInUser';
import Role from '../../../enums/Role';

const RolesAndPermissionsContainer = () => {
    const { loggedInUser } = useLoggedInUser()

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px", justifyContent: "space-around", mx: "8px" }}>
            <Card style={{ flexBasis: "15.5%", display: "flex", padding: "8px 4px", boxSizing: "border-box", marginTop: "42px", minHeight: "162px" }}>
                <RolesCount />
            </Card>

            {/**
             * // TODO: Do Tabs instead of accordian */}
            <Box style={{ flexBasis: "65%", }} >
                <DefaultRoles />
            </Box>

            {loggedInUser?.roleID === Role.Owner &&
                <Card style={{ flexBasis: "12.5%" }} >
                    <TransferOwnership />
                </Card>
            }
        </Box>
    );
}

export default RolesAndPermissionsContainer;
