import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { H4Meduim, PBold } from '../../../core/brand/typography';
import { Box, Paper } from "@mui/material";
import { LockModel } from "../../../models/lockModel";
import { PrimaryButton } from '../../../components/button';
import { Colors } from '../../../core/brand/themes';
import { ToastContext } from "../../../contexts/ToastProvider";
import agent from "../../../api/agent"
import { ToastType } from '../../../enums/ToastType';
import { strings } from '../../../content/strings';
import LockStatus from "../../../enums/LockStatus"
import { HubContext, signalRService } from '../../../contexts/HubContext';
import { events } from "../../../events";
import { useLocks } from '../../../hooks/useLocks';
import { useFacilityLookup } from '../../../hooks/useFacilityLookup';

const LockDetails = ({ lock, updateLock, trackClickEvent }: {
    lock: LockModel, updateLock: () => void, trackClickEvent: (arg0: string, arg1?: any) => void
}) => {
    const { displayToast } = useContext(ToastContext);
    const { fetchLocks } = useLocks();
    const { isReady } = useContext(HubContext);
    const facilityLookup = useFacilityLookup()

    const [lockUpdateSuccess, setLockUpdateSuccess] = useState(true);
    const [updatedLockStatus, setUpdatedLockStatus] = useState(null);

    const LockUnassigned = LockStatus.isNotAssigned(lock?.secondaryLockStatus as LockStatus);

    let [disableReturnToDropBox, setDisableReturnToDropBox] =
        useState(LockUnassigned
            || LockStatus[lock?.secondaryLockStatus!] === LockStatus[1003])

    let [disableTenantUsingLock, setDisableTenantUsingLock] =
        useState(LockUnassigned ||
            LockStatus[lock?.secondaryLockStatus!] === LockStatus[1006]);

    const updateLockStatus = async (secondaryStatus: number) => {
        trackClickEvent("Update Lock Status")
        const updatedLock = {
            ...lock,
            secondaryStatusName: LockStatus[secondaryStatus],
            secondaryLockStatus: secondaryStatus
        }

        const result = await agent.Locks.updateLock(updatedLock);
        if (result.success) {
            const updatedSecondaryStatus = result.data?.secondaryLockStatus as any;
            displayToast({ type: ToastType.Success });
            setLockUpdateSuccess(true);
            setUpdatedLockStatus(updatedSecondaryStatus);

            setDisableReturnToDropBox(LockUnassigned ||
                LockStatus[updatedSecondaryStatus] === LockStatus[1003]);

            setDisableTenantUsingLock(LockUnassigned ||
                LockStatus[updatedSecondaryStatus] === LockStatus[1006]);
        } else {
            displayToast({ type: ToastType.Error, text: result.error || strings.somethingWentWrong })
        }
    };

    useEffect(() => {
        if (isReady) {
            signalRService.getConnection()?.on(events.notifyLockUpdate, () => {
                fetchLocks();
                updateLock()
            })
        }
    }, [isReady]);

    useEffect(() => {
        if (lockUpdateSuccess) {
            setLockUpdateSuccess(!lockUpdateSuccess)
        }
        setDisableReturnToDropBox(LockUnassigned ||
            LockStatus[lock?.secondaryLockStatus!] === LockStatus[1003]);

        setDisableTenantUsingLock(LockUnassigned ||
            LockStatus[lock?.secondaryLockStatus!] === LockStatus[1006]);
    }, [lock])

    return (
        <Container>
            <HeaderWrapper>
                <Header>
                    <H4Meduim secondary>{strings.serialCodeDetails}</H4Meduim>
                </Header>
            </HeaderWrapper>

            <Row>
                <ListItem tertiary>{strings.serialCode}</ListItem>
                <ListItem >{lock.serialCode}</ListItem>
            </Row>

            <Row>
                <ListItem tertiary>{strings.facilityName}</ListItem>
                <ListItem >{facilityLookup[lock?.facilityID!] || " "}</ListItem>
            </Row>

            <Row>
                <ListItem tertiary>{strings.status}</ListItem>
                <ListItem >{lockUpdateSuccess ?
                    LockStatus[updatedLockStatus!] :
                    LockStatus[lock?.secondaryLockStatus!]}</ListItem>
            </Row>

            <Row>
                <ListItem tertiary>{strings.unlockCode}</ListItem>
                <ListItem >{lock.unlockCode}</ListItem>
            </Row>

            <Row>
                <ListItem tertiary>{strings.assignToUnit}</ListItem>
                <ListItem>{lock.unitNumber}</ListItem>
            </Row>

            <Row>
                <Box sx={{ gridColumn: "2" }}>
                    <PrimaryButton disabled={disableTenantUsingLock} onClick={() => updateLockStatus(LockStatus["Tenant Using Lock"])}>{strings.markLock("used")}</PrimaryButton>
                </Box>
                <Box sx={{ gridColumn: "3" }}>
                    <PrimaryButton disabled={disableReturnToDropBox} onClick={() => updateLockStatus(LockStatus["Returned to Dropbox"])}>{strings.markLock("returned")}</PrimaryButton>
                </Box>
            </Row>
        </Container>

    )
}
export default LockDetails

const Header = styled.div({
    width: "100%",
    background: Colors.red(),
    padding: "8px 4px",
    margin: "0 0 8px -8px",
    borderRadius: "2px"
});

const ListItem = styled(PBold)({
    margin: "8px",
    textAlign: "left",
})

const Row = styled.div({
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
})

const Container = styled(Paper)({
    marginTop: "8px",
    "@media(max-width : 545px)": {
        width: "65%"
    }
})

const HeaderWrapper = styled.div({
    gridColumn: "1/-1",
    gridRow: "1/-1",
    padding: "12px"
})
