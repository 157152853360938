import React from "react";
import gears from "../../assets/images/gears.png";
import { strings } from "../../content/strings";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate";
import CompanyDetailsContainer from "./components/CompanyDetailsContainer";

const CompanyDetailsPage = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.companyDetails}
            pageDescription=""
            pageCanonical="www.davincilock.com/Company/CompanyDetails">
            <CompanyDetailsContainer />
        </LoggedInPageTemplate >
    );
};

export default CompanyDetailsPage;
