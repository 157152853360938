import React from "react";
import gears from "../../assets/images/gears.png";
import { LoggedInPageTemplate } from "../../core/layout/LoggedInPageTemplate"
import { strings } from "../../content/strings";
import RolesAndPermissionsContainer from "./components/RolesAndPermissionsContainer"

const RolesAndPermissionsPage = () => {
    return (
        <LoggedInPageTemplate
            backgroundImage={gears}
            pageTitle={strings.dashboard}
            pageDescription={strings.rolesAndPermissionsDescription}
            pageCanonical={"https://www.davincilock.com/Company/RolesAndPermissions"}
        >
            <RolesAndPermissionsContainer />
        </LoggedInPageTemplate >
    )
}

export default RolesAndPermissionsPage
